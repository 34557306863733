import React from 'react'
import Box from "@mui/material/Box";
import { Typography, Modal, Tab, Tabs, IconButton } from "@mui/material";
import styles from "./BetterAnswerModal.module.css";
import BetterAnswers from '../BetterAnswers/BetterAnswers';
import AddAnswersManually from '../AddAnswersManually/AddAnswersManually';
import SearchUploadedFiles from '../SearchUploadedFiles/SearchUploadedFiles';
import { Close } from '@mui/icons-material';

const BetterAnswerModal = ({ Id, BotId, open, setOpen, question }) => {
  const [value, setValue] = React.useState(0)
  // const [questionNumber, setQuestionNumber] = React.useState()
  const handleClose = (e) => {
    console.log(e)
    setOpen(false)
  }
  console.log("question : ",question);
  console.log("index in better answer modal is: ", Id)
  // React.useEffect(() => {
  // }, [])
  const handleChange = (e, newValue) => {
    console.log(newValue)
    setValue(newValue)
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(29, 77, 106, 0.43)",
        }
      }}
    >
      <Box className={styles.outermostCard}>
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Typography id="modal-modal-title" fontFamily={"Gordita"} fontSize={24} fontWeight={500} component="h2">
            Add Better Answer
          </Typography>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <hr className={styles.divider} />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Search Dialogues" value={0} {...a11yProps(0)} />
              <Tab label="Search Uploaded Files" value={1} {...a11yProps(1)} />
              <Tab label="Add Answer manually" value={2} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BetterAnswers setOpen={setOpen} Id={Id} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SearchUploadedFiles Id={Id}  />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AddAnswersManually index={Id} BotId={BotId} setOpen={setOpen} question={question} />
          </TabPanel>

        </Box>
      </Box>

    </Modal>
  )
}

export default BetterAnswerModal