import { Container } from '@mui/material'
import React from 'react'
import DataBoxes from '../../components/Organization/superAdmin/DataBoxes/DataBoxes'
import MembersTable from '../../components/Organization/superAdmin/MembersTable/MembersTable'

const SuperAdmin = () => {
  return (
    <Container>
        <DataBoxes/>
        <MembersTable/>
    </Container>
  )
}

export default SuperAdmin