import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import tickImage from "../../../../assets/img/organization/charm_circle-tick.svg"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: "30px 10px",
  fontFamily: "Gordita",
  borderRadius: "18px"
};

export default function Modale({ open, handleOpen, handleClose, setOpen, modelData }) {
  /* eslint eqeqeq: 0 */
  const data = modelData?.amount
  const email = localStorage.getItem('email')
  const amount = data / 100
  // console.log(data?.card_details?.card?.card_type ,"dAA");
  // console.log(modelData,"model data");
  const navigate = useNavigate()

  const handleOK = () => {
    navigate("/organization/billing")
    handleClose()
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleOK}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          {modelData.status == "succeeded" ?
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
              <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "600", color: "black", textAlign: "center" }}>
                Payment Successful
              </Typography>
              <Box width={"100%"} sx={{ display: "flex", justifyContent: "center", my: "20px" }}>
                <img alt=' ' src={tickImage} width={"100px"} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", mt: "10px" }}>
                <Box sx={{ borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", width: "90%", flexDirection: "column" }} >
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                      Payment Type
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                      {modelData?.payment_method_types[0]}
                    </Typography>
                  </Box>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                    Card Brand
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                    {data?.card_details?.card?.card_brand}
                  </Typography>
                </Box> */}
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                      Mobile
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                      0
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400", color: "black" }}>
                      Email
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400", color: "black" }}>
                      {/* {userData.data?.Cost} */}
                      {email}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "black" }}>
                      Amount Paid
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "black" }}>
                      ${amount.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "5px", width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400", }}>
                      Transaction Id
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400", color: "black" }}>
                      {modelData?.id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Button onClick={handleOK} variant='contained' sx={{mx:"auto",minWidth:"100px",my:"20px"}}>OK</Button>
            </Box>
            : modelData[0]?.code == "NOT_FOUND" ?
              <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                <Typography id="modal-modal-title" sx={{ fontSize: "28px", fontWeight: "600", textAlign: "center", py: "20px" }}>
                  Somthing went wrong
                </Typography>
                <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center", color: "red" }}>
                  {modelData[0]?.detail}
                </Typography>
                <Button onClick={handleOK} variant='contained' sx={{mx:"auto",minWidth:"100px",my:"20px"}}>OK</Button>
              </Box>
              :
              <>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                  <Typography id="modal-modal-title" sx={{ fontSize: "28px", fontWeight: "600", textAlign: "center", py: "20px", color: "red" }}>
                    Somthing went wrong
                  </Typography>
                  <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center", }}>
                    Make sure credit card details should be correct{modelData?.message}
                  </Typography>
                  <Button onClick={handleOK} variant='contained' sx={{mx:"auto",minWidth:"100px",my:"20px"}}>OK</Button>
                </Box>
              </>
          }
        </Box>
      </Modal>
    </div>
  );
}