import React from "react";
import CustomizeBot from "../../components/BotCustomization/customizeBot";
import { TopBar } from "../../components/CommonComponent/Topbar";

export const BotCustomization = () => {
  return (
    <div>
      
        <TopBar backArrow={true} text="Pitch Deck- Template Customization "/>
        <CustomizeBot />
      
    </div>
  );
};
