import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import AddEmojiCard from "../../components/Administration/AddEmojiCard/AddEmojiCard";
import BottomProgressStep2 from "../../components/Operations/BottomProgressStep2";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import AvataresContainer from "../../components/Classroom/AvataresContainer/AvataresContainer";

const AddEmoji = ({ page }) => {
  const {state} = useLocation();
  const navigate =useNavigate()
  const [id, setId] =  React.useState(1)
  const [rerender, setRerender] = React.useState(false);
  const [avatares, setAvatares] = React.useState([]);
  const [avatare, setAvatare] = React.useState("Zofi")
  const [desc, setDesc] = React.useState("E-commerce")
  
  let userId = localStorage.getItem('UserId')
  const [UserId , setUserId] = React.useState(userId)
  console.log(state)
    // console.log(avatareName)
    // setAvatare(avatareName)
    // console.log(description)
    // setDesc(description)
    React.useEffect(() => {
    // let mounted = true;
    if (state){
      const { botId, avatareName, description } = state; 
      console.log("BotID is: ", botId)
      setId(botId)
      setAvatare(avatareName)
      setDesc(description)
    }
    else{
      let botId = 1
      let avatareName = "Zofi"
      let description = "Avatare"
      setId(botId)
      setAvatare(avatareName)
      setDesc(description)
    }
    
    }, [])
   const onClickNext = ()=>{
    navigate("/library/dataFiles")
   }

   
  const getData = async () => {
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${UserId}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
      .then(
        data => data.json())
  }

  React.useEffect(() => {
    getData()
      .then(items => {
        if (items.message != "No Avatars Yet") {
          setAvatares(items.message)
        }else if(items.message = "No Avatars Yet"){
          setAvatares(items.message)
        }
      })
  }, [rerender,UserId])
console.log("avatars : ",avatares);

  return (
    <React.Fragment>
      <Box sx={{position:"relative",mb:"60px"}}>
      <CssBaseline />
      {page == "editAvatar" ? (
        <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          margin:"0vw",
          padding:"0vw"
        }}
        >
        <Breadcrumbs_component Left="Administration" Right="Add Emoji"/>
        <AddEmojiCard botId={id} name={avatare} description={desc}/>
      </Container>
      ):(
        <Container
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
        >
        <Breadcrumbs_component Left="Administration" Right="Add My Emoji" setUserId={setUserId} UserId={UserId}/>
        <Typography variant="h5" align="left" fontWeight={"bold"}  >My Avatar</Typography>
        <Box sx={{
                      bgcolor: "#E1F4FF",
                      height: "100%",
                      minHeight:"460px",
                      width: "100%",
                      display:"flex",
                      justifyContent:"space-around",
                      flexWrap: "wrap",
                      alignItems:"start",
                      my: "20px",
                      borderStyle: "dashed",
                      borderWidth: "1px",
                      overflowY:"auto",
                      mx:"auto",
                      py:"20px"
        }}>

          <Box sx={{ width: "90%", display: "flex",flexWrap: "wrap",mx:"auto" }}>
            { avatares == "No Avatars Yet" ? (
                <Typography sx={{fontSize:"22px",fontWeight:"bold",my:"20px",}}>No Avatars Yet</Typography>
            ):( avatares.map((data)=>(
              <AddEmojiCard BotId={data?.BotId} BotEmoji={data?.BotEmoji} BotName={data?.BotName} emojiS3Link={data?.emojiS3Link} page="addEmoji"/>
            )))}
          </Box>
        </Box>
       </Container>
      )}
      <BottomProgressStep2 title="Add your emoji" message="Create emoji for your avatare." onClickNext={onClickNext}/>
      </Box>
    </React.Fragment>
  );
};

export default AddEmoji;