import React from 'react';
import Container from "@mui/material/Container";
import { Typography, Button,TextField } from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import { useLocation, useNavigate } from 'react-router-dom';
import CloudSVG from "../../assets/img/common/CloudUploadWhite.svg";
import swal from 'sweetalert';

const AddNewQuestion = () => {
  /* eslint eqeqeq: 0 */
  // Defining states
  const {state} = useLocation();
  const { sourceId, dataId, name } = state;

  const navigate  = useNavigate()
  
  const AddNewQuestionManually = () => {
    var question = document.getElementById('question').value;
    var answer = document.getElementById('answer').value;
    if (sourceId) {
      var body = {
        DataId: dataId,
        SourceId: sourceId,
        Question: question,
        Answer: answer
    }
    }
    else{
       body = {
        DataId: dataId,
        Question: question,
        Answer: answer
    }
    }
    
    // console.log("Data is: ", body)
    fetch(`${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(body),
        cache: "default",
      })
      .then(function (response) {
        console.log(response)
        document.getElementById('question').value = ""
        document.getElementById('answer').value = ""
        if(response.status==200 || response.status==201){
          swal({
            title: "Successful",
            text: "Question has been added!",
            icon: "success",
            button: "Dismiss!",
          });
          navigate("/classroom/QuestionsAndAnswers",{
            state: {
              currentSourceId: sourceId,
              currentDataId: dataId,
              name: name
            },
          })
        }
        else{
          swal({
            title: "Error",
            text: "Question or answer field should not be empty!",
            icon: "error",
            button: "Dismiss!",
          });
        }
        return response.json();
      })
      .then(function (response) {
        console.log(response)
      })
  }
    return (
        <React.Fragment>
          <Container
            fixed
            sx={{
              display: "flex",
              flexDirection: "column",
              // margin:"3vw",
              // width:"2000px"
            }}
          >
            <BreadcrumbsComponent Left="Library" Right="DataFiles"/>
            <Typography variant="h3" align={"left"} sx={{fontSize:24, m:3, fontWeight:"bold", fontFamily:"Gordita"}}>Add New Question</Typography>
            <Container
                fixed
                sx={{
                    p:10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems:"flex-start",
                  mt:"10px",
                  width:"2000px!important",
                  height:"100%",
                  gap:"1vw",
                  backgroundColor:"#E1F4FF",
                  
                }}
              >
                <Typography variant="h3" sx={{fontSize:24, fontWeight:"bold", fontFamily:"Gordita"}}>Question</Typography>
                <TextField
                id={"question"}
                required={true}
                sx={{
                    backgroundColor:"white",
                    width:"100%"
                }}
                />
                <Typography variant="h3" sx={{fontSize:24, fontWeight:"bold", fontFamily:"Gordita"}}>Answer</Typography>
                <TextField
                id={"answer"}
                multiline
                rows={8}
                required={true}
                maxRows={Infinity}
                sx={{
                    backgroundColor:"white",
                    width:"100%"
                }}
                />
                </Container>
          </Container>
          <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent:"flex-end",
            margin:"3vw",
            width:"2000px"
          }}>
            <Button
                    onClick={AddNewQuestionManually}
                    sx={{backgroundColor:"#295598!important", color:"white!important", width:"15vw", alignSelf:"right!important"}}
                    ><img alt=' ' src={CloudSVG} height={20}/><Typography ml={3}>Upload</Typography></Button>
            </Container>
        </React.Fragment>
      );
}

export default AddNewQuestion