import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Typography, Button } from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import QuestionsAndAnswers from "../../components/Classroom/QuestionsAndAnswers/QuestionsAndAnswers";
import { useLocation, useNavigate } from "react-router-dom";
import CloudUpload from "../../assets/img/common/CloudUploadWhite.svg";
import { TopBar } from "../../components/CommonComponent/Topbar";

const QuestionsAndAnswersScreen = () => {
  // Getting necessary values from sibling screens
  /* eslint eqeqeq: 0 */
  const { state } = useLocation();
  const { name, currentDataId, currentSourceId, questions, answers } = state;

  const [questionId, setQuestionId] = useState(1);

  const [dataObjects, setDataObjects] = React.useState([]);
  const dataId = currentDataId;
  const [rerender, setRerender] = React.useState(null);
  const sourceId = currentSourceId;
  // const [botId, setBotId] = React.useState(null)
  const [open, setOpen] = React.useState(false);

  var navigate = useNavigate();
  // Passing data to API to get data for display
  const getData = async () => {
    if (currentSourceId) {
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/?SourceId=${currentSourceId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      ).then((data) => data.json());
    } else {
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/?DataId=${currentDataId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      ).then((data) => data.json());
    }
  };
  React.useEffect(() => {
    let mounted = true;
    getData().then((items) => {
      console.log("Items: ", items);
      if (
        typeof items.message.constructor == "string" ||
        items.message == "No Chat found."
      ) {
        // console.log("No QAs")
      } else {
        setDataObjects(items.message);
      }
    });
    return () => (mounted = false);
  }, [open, rerender]);

  // Create function for redirection
  const AddNewQA = () => {
    navigate("/classroom/addNewQuestion", {
      state: {
        sourceId: sourceId,
        dataId: dataId,
        name: name,
      },
    });
  };
  return (
    <>
      <div className="w-full">
        <TopBar backArrow={true} text={"Questions & Answers"} />
        <div className="px-8 py-4">
          

        <QuestionsAndAnswers
          open={open}
          setOpen={setOpen}
          setQuestionId={setQuestionId}
          questionId={questionId}
          questions={questions}
          answers={answers}
          dataObjects={dataObjects}
          Id={sourceId ? sourceId : dataId}
          IdName={sourceId ? "SourceId" : "DataId"}
          rerender={rerender}
          setRerender={setRerender}
        />
        </div>
      </div>
    </>
  );
};

export default QuestionsAndAnswersScreen;
