import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItem: "flex-end",
          // margin:"3vw"
        }}
      >
        <Box
          sx={{
            bgcolor: "#DAD5D5E5",
            height: "80vh",
            width: "80vw",
            mt: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography fontSize={46} fontWeight={500}>
            Error 404: Page Not Found
          </Typography>
          <Link to="/" style={{textDecoration:'none'}}>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowRightAltIcon />}
              sx={{
                mt: 4,
                backgroundColor: "#9B9B9B",
                color: "#000000",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              Back to Home
            </Button>
          </Link>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ErrorPage;
