import React from 'react'

const Guide = (props) => {
  return (
    <div
    onClick={props.handleClick}
    style={{ border: "1px solid rgba(0, 0, 0, 0.20)" }}
    className="px-4 py-4 flex flex-col rounded-lg bg-white w-[430px] max-lg:w-[40%]  gap-2 cursor-pointer"
  >
    <div className="flex self-center rounded-2xl border border-[#9747FF] text-xs text-black justify-center px-4 opacity-80">
      Beginner
    </div>
    <div className="flex justify-start items-start text-wrap text-left gap-2">
      <img src={props.icon} className="w-10 mt-2" />
      <div className="flex flex-col gap-1">
        <h1 className="font-bold text-base">{props.text}</h1>
        <p className="text-xs text-opacity-70">{props.subText}</p>
        <div className="flex gap-2 mt-4 mb-3">
          <img src='/video-icon.svg'   />
          <p className="text-sm text-black text-opacity-70 underline ring-offset-2">
            6 Videos
          </p>
        </div>


      </div>
      
    </div>
  </div>
  )
}

export default Guide