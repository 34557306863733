// import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import { Typography } from "@mui/material";
// import Breadcrumbs_component from "../../components/common/Breadcrumbs";
// import CloneAvatar from "../../components/Administration/CloneAvatar/CloneAvatar";

// const CloneAvatare = () => {
//   /* eslint eqeqeq: 0 */
//   const [avatares, setAvatares] = React.useState([]);
//   const [rerender, setRerender] = React.useState(false);

//   const [UserId, setUserId] = React.useState(userId)
//   // const getData = async () => {
//   //     return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${userId}`,
//   //     {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
//   //       .then(data => data.json())
//   // }
//   // React.useEffect(() => {
//   //   getData()
//   //     .then(items => {
//   //       if(items.message != "No Avatars Yet"){
//   //         setAvatares(items.message)
//   //       }
//   //     })
//   //   }, [rerender])

//   const getData = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${UserId}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const dataa = await response.json();
//         setAvatares(dataa?.message);
//       } else {
//         const dataa = await response.json();
//         if (dataa.message == "No Avatars Yet") {
//           setAvatares(dataa.message)
//         }
//         console.log('Error:', response.status);
//       }
//     } catch (error) {
//       console.log('Error:', error.message);
//     }
//   };

//   React.useEffect(() => {
//     getData();
//   }, [rerender, UserId]);

//   console.log("avatares :", avatares);

//   return (
//     <React.Fragment>
//       <CssBaseline />
//       <Container
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           // margin:"3vw"
//         }}
//       >
//         <Breadcrumbs_component Left="Administration" Right="Clone Avatare" setUserId={setUserId} UserId={UserId} />
//         <Typography variant="h5" align="left" fontWeight={"bold"} >My Avatars</Typography>
//         <Box
//           sx={{
//             bgcolor: "#E1F4FF",
//             height: "100%",
//             minHeight: "520px",
//             width: "100%",
//             display: "flex",
//             justifyContent: "space-around",
//             flexWrap: "wrap",
//             alignItems: "start",
//             my: "20px",
//             borderStyle: "dashed",
//             borderWidth: "1px",
//             overflowY: "auto",
//             mx: "auto",
//             py: "20px"
//           }}
//         >
//           <Box sx={{ width: "90%", display: "flex", flexWrap: "wrap", }}>
//             {avatares == "No Avatars Yet" ? (
//               <Typography sx={{ fontSize: "22px", fontWeight: "bold", my: "20px", }}>No Avatars Yet</Typography>
//             ) : (
//               avatares?.map((data) => (
//                 <CloneAvatar BotId={data?.BotId} BotEmoji={data?.BotEmoji} BotName={data?.BotName} emojiS3Link={data?.emojiS3Link} getData={getData} setRerender={setRerender} />
//               )))}
//           </Box>
//         </Box>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default CloneAvatare;

import React, { useEffect, useState } from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { AvatarFromScratchCard } from "../../components/MyAvatarNew/AvatarFromScratchCard";
import { cloneAvatar, getData } from "../../components/common/api";
import Popup from "../../components/common/PopUp";
import { message } from "antd";

const CloneAvatare = () => {
  const [avatares, setAvatares] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [avatareName, setAvatareName] = useState("");
  const [botId, setBotId] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setisOpen(false);
  };

  //api call

  useEffect(() => {
    getData(setAvatares);
  }, [isOpen]);

  const handleCLick = (id) => {
    console.log(id)
    setIsModalOpen(true);
    setBotId(id);
  };

  const handleClone = () => {
    console.log("hello")
    if (avatareName.trim() !== "") {
      if (avatareName.replace(/\s/g, "").length >= 30){
        message.error("Name should not be more than 30 characters");
      }
      else {
        const result = cloneAvatar(botId, avatareName, setisOpen,setIsModalOpen);
        console.log(result)
      }
    } else {
      message.error("Give a name before cloning avatare");
    }
  };
  return (
    <div>
      <TopBar text="Clone Avatare" />

      <div className="px-8 pt-4">
        <h1 className="text-lg font-medium mt-3">
          Select the bot to make a clone of it
        </h1>
        <div className="mt-6 mb-20">
          <div className="flex gap-6 m-w-[50%] flex-wrap">
            {avatares?.map((data, key) => (
              <AvatarFromScratchCard
                key={key}
                handleCLick={handleCLick}
                data={data}
              />
            ))}
          </div>
          <Popup
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            avatareName={avatareName}
            setAvatareName={setAvatareName}
            fromClone={true}
            text={"Give a name to cloned Avatare"}
            buttonTextOne={"Clone Avatare"}
            handleClone={handleClone}
          />
          <Popup
            isOpen={isOpen}
            closeModal={closeModal}
            avatareName={avatareName}
            setAvatarName={setAvatareName}
            fromClone={true}
            text={"Avatare Cloned Successfully!"}
            desc={"Your avatare has been cloned successfully under name"}
          />
        </div>
      </div>
    </div>
  );
};

export default CloneAvatare;
