import React from 'react'
import { Container } from '@mui/material'
import LoginComponent from '../../components/Auth/MemberLogin/Login'
import OrgLoginComponent from '../../components/Auth/OrgLogin/OrgLogin'

const Login = ({setIsOrgnizer}) => {
  React.useEffect(() => {
     localStorage.setItem('token', null)
  }, [])

  return (
    <Container sx={{align:"center", mt:"3vh"}}>
      <OrgLoginComponent setIsOrgnizer={setIsOrgnizer}/>
    </Container>
  )
}

export default Login