import { Container } from '@mui/material'
import React from 'react'
import Header from './Header'
import Payments from '../../../components/Organization/Billing/payments/Payment'
const Payment = ({credits, setCredits, value,setValue}) => {
  return (
    <>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        py:"20px"
      }}
    >
      <Header />
      <Payments credits={credits} setCredits={setCredits} value={value} setValue={setValue}  />
    </Container>
    </>
  )
}

export default Payment