import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import AddMyEmojiCard from "../../components/Administration/AddMyEmojiCard/AddMyEmojiCard";
import BottomProgressStep2 from "../../components/Operations/BottomProgressStep2";
import { useLocation, useNavigate } from "react-router-dom";
import AvataresContainer from "../../components/Classroom/AvataresContainer/AvataresContainer";
import { Box } from "@mui/material";

const AddMyEmoji = () => {

  const navigate = useNavigate()
  const { state } = useLocation();
  const { botId, avatareName, description, variant, emoji } = state;
  React.useEffect(() => {
    console.log("Bot Id is: ", botId)
  })
  const onClickNext = () => {
    navigate("/library/dataFiles")
  }



  return (
    <React.Fragment>
      <CssBaseline />
        <Container
          fixed
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0vw",
            padding: "0vw"
          }}
        >
          <Breadcrumbs_component Left="Administration" Right="Add Emoji" />
          <AddMyEmojiCard botId={botId} name={avatareName} description={description} variant={variant} emoji={emoji} />
        </Container>
      <BottomProgressStep2 title="Add an emoji" message="Create an emoji for the avatare" onClickNext={onClickNext} />
    </React.Fragment>
  );
};

export default AddMyEmoji;