import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import {Link} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styles from "./SideNav.module.css";
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LibraryIcon from "../../assets/img/common/library.svg";
import ClassroomIcon from "../../assets/img/common/Classroom.svg";
import OperationsIcon from "../../assets/img/common/Operations.svg";
import whiteLogo from "../../assets/img/logo/White Logo.svg";

const drawerWidth = "20vw";

const OrgSideNav = () => {
  const superAdminEmail = localStorage.getItem("email")

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline enableColorScheme/>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#295598 !important",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h5" className={styles.logoSidebar}>
            <img alt=" " src={whiteLogo} height={60}/>
          </Typography>
        </Toolbar>
        <List
          className={styles.sidebarOptions}
        >
          <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/">
            <ListItemButton>
            <HomeIcon sx={{color:"white", fontSize:20, mr:2}}/>
              <Typography fontSize={28} fontWeight={600} color={"#E1F4FF"}>
                Workspace
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/organization/profile">
            <ListItemButton>
              <ManageAccountsIcon sx={{color:"white", fontSize:20, mr:2}}/>
              <Typography fontSize={24} fontWeight={600} color={"white"}>
                Personal
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/organization/team">
            <ListItemButton>
            <img alt=" " src={LibraryIcon} width="20"/>
              <Typography fontSize={24} fontWeight={600} ml={2}color={"white"}>
                Team
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/organization/billing">
            <ListItemButton>
            <img alt=" " src={ClassroomIcon} width="20"/>
              <Typography fontSize={24} fontWeight={600} ml={2} color={"white"}>
                Billing
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/organization/usage">
            <ListItemButton>
            <img alt=" " src={OperationsIcon} width="20"/>
              <Typography fontSize={24} fontWeight={600} ml={2} color={"white"}>
                Usage
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
          {superAdminEmail=="daren.jackson@avatare.com" && (
            <ListItem disablePadding className={styles.sidebarItem}>
            <Link className="Link" to="/organization/SuperAdmin">
            <ListItemButton>
            <img alt=" " src={OperationsIcon} width="20"/>
              <Typography fontSize={24} fontWeight={600} ml={2} color={"white"}>
                Super Admin
              </Typography>
            </ListItemButton>
            </Link>
          </ListItem>
        )}
        </List>
      </Drawer>
    </Box>
  );
};

export default OrgSideNav;
