import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import '../../App.css';
import { Link } from "react-router-dom";
import { useEffect } from "react";



const Appbar = ({setAllCredits,totalCredits,changeCredits}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [isOrganizer, setIsOrganizer] = React.useState(false)
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');
  // const [totalCredits,setTotalCredits] = React.useState({})
  const email = localStorage.getItem('email')

  
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/getcredits/?Id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const dataa = await response.json();
        // setTotalCredits(dataa);
        setAllCredits(dataa)
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    fetchData();
  }, [changeCredits]);


  React.useEffect(() => {
    setUserData(email)
    var value = localStorage.getItem("isOrganizer")
    var isTrueSet = (value === 'true');
    setIsOrganizer(isTrueSet);
  }, [])

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };


  
  const handleLogout = (event) => {
    localStorage.removeItem('token');
    localStorage.removeItem('UserId');
    localStorage.removeItem('email');
    window.location.href = "/login"
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isOrganizer? <Link className="Link" to="organization/team"><MenuItem onClick={handleMenuClose}>Profile</MenuItem></Link> : <></>}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ bgcolor: "transparent !important", boxShadow: "none", paddingTop:'1vh' }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent:"center" }}
          >
              <Typography sx={{color:"black",fontSize:"20px"}}>{totalCredits?.message?.toLocaleString()} (credits)</Typography>
              <Typography fontWeight={600} sx={{color:'#295598',mx:"8px"}}>|</Typography>
            <Link
            to={"/help"}
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              sx={{borderRadius:"32px"}}
            >
              <Badge badgeContent="?" className="badge" sx={{
                  "& .MuiBadge-badge": {
                    transform: "scale(0.8,0.8)",
                    backgroundColor: "#295598!important"
                  },
                  padding:"10px",
                  color:"white"
                }}>
                <Typography fontWeight={500} fontSize={20} sx={{color:'black', fontFamily:'Gordita'}} >Help Desk</Typography>
              </Badge>
            </Link>
            <Typography fontWeight={600} sx={{color:'#295598'}}>|</Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              sx={{borderRadius:"1px"}}
            >
              <AccountCircle sx={{color:'#295598', fontSize:32, mr:2}}/>
              <Typography  sx={{color:'black', fontSize:20, fontWeight:500, fontFamily:"Gordita"}}>{userData}</Typography>
              <KeyboardArrowDownIcon sx={{color:'#295598', fontSize:29}}/>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

export default Appbar;
