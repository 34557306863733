import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
    return (
        <Box>
            <Typography
                align={"left"}
                fontSize={"38px"}
                fontWeight={500}
                fontFamily={"Gordita"}
            >
                Billing
            </Typography>
            <Typography align={"left"} sx={{ fontFamily: "Gordita", }}>
                Update your payment information or buy more credits according to your needs.
            </Typography>
        </Box>
    )
}

export default Header