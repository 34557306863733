import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import logos_visa from "../../../../assets/img/organization/logos_visa.svg"
import logos_paypal from "../../../../assets/img/organization/logos_paypal.svg"
import logos_mastercard from "../../../../assets/img/organization/logos_mastercard.svg"
import BillingInfo from './BillingInfo'
import CreditCardInfo from './CreditCardInfo'
// import Square from "square"

// strip payment

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm'



const Payment = ({ value, credits }) => {



    const [userData, setUserData] = useState({});
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('UserId');
    const [clientSecret,setClientSecret] = useState()

    // console.log("user id : ",token);
    // strip payment
    const stripePromise = loadStripe('pk_test_51MEANsBJ0sJv87nVc9oWnnxZ5fHFd9PuuEd4ypLF0abfYZychQtpGVgRL4TBpIy949DZsCX7TT56HSsE74sEfgL700DNPnlPHk');
    // const options = {
    //     clientSecret: `sk_test_51MEANsBJ0sJv87nV8Aqi9kNs3TYId3qce7ALSVwJFwR4Kml3DW18Hz47kh439aeIZZUKY9OqqLj5UCt6R2fCyIPY00FjbJsWIj`,
    // };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/checkout/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                UserId: userId,
            })
        }).then((res) => {
            return res.json();
            // console.log('data: ', data);
            // console.log('res:: ', res);
          }).then((data)=>{
            setClientSecret(data.client_secret);
          }).catch((error) => {
                console.error('Error:', error);
            });
    }, [])


    const [billingFormData, setBillingFormData] = useState({
        name: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
    });
    // const [startDate, setStartDate] = useState(new Date());
    const [creditFormData, setCreditFormData] = useState({
        cardNumber: '',
        cardHolderName: '',
        csv: '',
        date: '',
    });

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/checkout/?UserId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUserData(data);
            } else {
                console.log('Error:', response.status);
            }
        } catch (error) {
            console.log('Error:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "start", margin: "0px", fontFamily: "Gordita" }}>
            <Box display={"flex"} >
                <Box width={"50%"}>
                    <Typography sx={{ mt: "50px", fontSize: "24px", fontWeight: "600", fontFamily: "Gordita", color: "black" }}>
                        Order Summery
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: "center", mt: "10px" }}>
                        <Box sx={{ backgroundColor: "#FAFAFA", p: "30px 20px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", width: "80%", flexDirection: "column" }} >
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray", my: "15px", width: "100%" }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                    Total Credit
                                </Typography>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                    {userData?.data?.Credits.toLocaleString()}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray", my: "15px", width: "100%" }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                    Total Payment
                                </Typography>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                    {userData?.data?.Cost.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray", my: "15px", width: "100%" }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                    Tax
                                </Typography>
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>

                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray", my: "15px", width: "100%" }}>
                                <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "black" }}>
                                    Final Total
                                </Typography>
                                <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "black" }}>
                                    {userData?.data?.Cost.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box width={"50%"}>
                    <Typography sx={{ mt: "50px", fontSize: "24px", fontWeight: "600", fontFamily: "Gordita", color: "black" }}>
                        Choose a payment method
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: "center", mt: "10px" }}>
                        <Box sx={{ display: 'flex', flexWrap: "wrap", alignItems: "center", textAlign: "center", fontFamily: "Gordita", }}>
                            <Box sx={{ pr: "15px", width: "30%", height: "120px", my: "10px" }}>
                                <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    <img alt=' ' width={"100%"} src={logos_visa} />
                                </Box>
                            </Box>
                            <Box sx={{ pr: "15px", width: "30%", height: "120px", my: "10px" }}>
                                <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    <img alt=' ' width={"100%"} src={logos_paypal} />
                                </Box>
                            </Box>
                            <Box sx={{ pr: "15px", width: "30%", height: "120px", my: "10px" }}>
                                <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    <img alt=' ' width={"100%"} src={logos_mastercard} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box display={"flex"} width={"100%"} mt={"50px"}>
                    <Box width={"50%"}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "600", fontFamily: "Gordita", color: "black" }}>
                            Billing Info
                        </Typography>
                        <BillingInfo billingFormData={billingFormData} setBillingFormData={setBillingFormData} />
                    </Box>
                    <Box width={"50%"}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "600", fontFamily: "Gordita", color: "black" }}>
                            Credit Card info
                        </Typography>
                        {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm fetchData={setUserData}/>
                            </Elements>
                        )}
                        {/* <CreditCardInfo setCreditFormData={setCreditFormData} creditFormData={creditFormData} value={value} credits={credits} fetchData={setUserData} /> */}
                    </Box>
                </Box>
                {/* <Box display={"flex"} width={"40%"} mt={"50px"} mx={"auto"}>
                <Button variant="contained" fullWidth onClick={handleBillig}>Pay now </Button>
            </Box> */}
            </Box>
        </Box>
    )
}
export default Payment
