import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import Typography from '@mui/material/Typography';
import Modal from "./Modal";
// import HistoryModel from "./HistoryModel";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MembersTable() {
  /* eslint eqeqeq: 0 */
  const navigate = useNavigate()

  const [data, setData] = React.useState([]);
  const [rerender, setRerender] = React.useState(false)
  const [selectedUserData, setSelectedUserData] = React.useState();
  const [open, setOpen] = React.useState(false);
  var userId = localStorage.getItem("UserId");
  const token = localStorage.getItem("token")
  
  const handleClose = () => setOpen(false);

  const getData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?OrganizationId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const dataa = await response.json();
        if (dataa.message != "No members yet") {
          console.log(dataa.message);
          setData(dataa.message);
        }
        else{
          setData([])
        }
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    getData();
  }, [rerender]);

  const deleteMember = (Id) => {
    fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?Id=${Id}`,
      {
        method:"DELETE",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    ).then((response) => {
        if(response.status==200 || response.status==201){
            swal({
              title: "Successful",
              text: "Member has been deleted!",
              icon: "success",
              button: "Dismiss!",
            });
          }
        response.json()}).then((data) => setRerender(!rerender))
    ;
  };

  const openModal = (userData) => {
    setSelectedUserData(userData);
    setOpen(true);
  };

  const fetchHistoryModelData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/assigncredits/?UserId=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const dataa = await response.json();
        navigate("/organization/credithistory",{state:dataa})
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  console.log("data",data);
  return (
  <>
  <Modal handleClose={handleClose} open={open} propData={data} setOpen={setOpen} getData={getData} selectedUserData={selectedUserData}/>
          <TableContainer component={Paper} sx={{bgcolor:"transparent",boxShadow:"none"}}>
    <Table sx={{ minWidth: 650}} aria-label="simple table">
      <TableHead> 
        <TableRow>
          <TableCell>Members</TableCell>
          <TableCell>Credits Granted</TableCell>
          <TableCell align="left">Credits Used</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row)=>{
          const usedCredit = row?.credits_assigned - row?.credits;
          // console.log("row",row);
          return(
            <>
          <TableRow>
            <TableCell component="th" scope="row">
              {row?.email}
            </TableCell>
            <TableCell component="th" scope="row">
              {row?.credits_assigned.toLocaleString()}
            </TableCell>
            <TableCell component="th" scope="row">
              {usedCredit}
            </TableCell>
            <TableCell align="left" >
                  <Typography borderRadius={"10px"} textAlign={"center"} variant="button" display="block" gutterBottom
                  sx={
                    row?.status == "Pending"
                      ? { color:"white",py:"5px",backgroundColor: "brown" }
                      : row?.status == "InActive"
                      ? { color:"white",py:"5px",backgroundColor: "red" }
                      : { color:"white",py:"5px",backgroundColor: "green" }
                  }
                  >
                    Active
                  </Typography>
            </TableCell>
            <TableCell align="left">
              <Button sx={{color:"black",textDecoration:"none",cursor:"pointer",}} variant="outlined" onClick={()=>openModal(row)}  >
                Assign Credits
              </Button>
            </TableCell>
            <TableCell align="left">
              <Typography sx={{color:"black",textDecoration:"none",cursor:"pointer"}}  onClick={()=>fetchHistoryModelData(row?.Id)}  >
                History
              </Typography>
            </TableCell>
            <TableCell align="left" >
              {row?.isOrganizer == true? "":(
                <>
              <EditIcon sx={{ color: "#295598", }}/>
              <DeleteIcon sx={{ color: "#295598" }} onClick={() => {deleteMember(row?.Id)}} />
                </>
              )
              }
            </TableCell>
          </TableRow>
            </>
          )
        })}
      </TableBody>
    </Table>
    </TableContainer>
                    </>
  );
}
