import React from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import styles from "./EmailAndPassword.module.css";
import swal from "sweetalert";

const EmailAndPassword = () => {
  const token = localStorage.getItem("token")
  const [clicked, setClicked] = React.useState(false)
  const [password, setPassword] = React.useState("")
  const [currentpassword, setCurrentPassword] = React.useState(null)
  const [newpassword, setNewPassword] = React.useState(null)
  const [confirmpassword, setConfirmPassword] = React.useState(null)

  const handlePassword = async () =>{
    setPassword(true)
    if (password) {
      if (currentpassword == null || newpassword == null || confirmpassword == null) {
        swal({
          title: "Oh Snap!",
          text: "fields are required.",
          icon: "error",
          button: "Dismiss!",
        });
      } else if(newpassword !== confirmpassword ){
        swal({
          title: "Oh Snap!",
          text: "new password and confirm password is not same.",
          icon: "error",
          button: "Dismiss!",
        });
      }else{
        const data = {
          current_password : currentpassword,
          new_password : newpassword,
          confirm_password : confirmpassword
        }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/change-password/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          cache: "default",
          body: JSON.stringify(data),
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log(data);
        } else {
          console.log('Error:', response);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    }
    
  }
    }

  return (
    <>
      <Typography className={styles.textLabels}>
        Password
      </Typography>
      <Box display={"flex"} sx={{width:"100%",alignItems:"center"}} justifyContent={"space-between"}>
        <Box display={"flex"} sx={{width:"50%"}}>
          {!password ?
          (
            <>
            <Typography align="left" fontSize="24px">
          Your Password is
        </Typography>
        <Typography fontSize="24px" fontWeight={"bold"}>*********</Typography>
            </>
          )
        :
        (
          <Box sx={{display:"flex",flexDirection:"column",width:"100%"}}>
        <TextField
          id="outlined-password-input"
          label="Current Password"
          type="password"
          autoComplete="current-password"
          sx={{my:"8px"}}
          fullWidth
          onChange={(e)=>setCurrentPassword(e.target.value)}
          />
        <TextField
          id="outlined-password-input"
          label="New Password"
          type="password"
          autoComplete="New-password"
          sx={{my:"8px"}}
          fullWidth
          onChange={(e)=>setNewPassword(e.target.value)}
          />
        <TextField
          id="outlined-password-input"
          label="Confirm Password"
          type="password"
          autoComplete="Confirm-password"
          sx={{my:"8px"}}
          fullWidth
          onChange={(e)=>setConfirmPassword(e.target.value)}
          />
          </Box>
          )} 
        </Box>
        <Button onClick={handlePassword} className={styles.changeButton}>{password ? "Save Changes":"Change"}</Button>
      </Box>
      </>
  )
}

export default EmailAndPassword