import { Typography, TextField, Container, Button } from '@mui/material'
import React from 'react';
import swal from 'sweetalert';
import styles from "./AddAnswersManually.module.css";
import { useState } from 'react';

const AddAnswersManually = ({index, BotId, setOpen, question}) => {
  const [answer , setAnswer] = useState()
    /* eslint eqeqeq: 0 */

    const AddAnswer = () => {
        // let answer = document.getElementById('textArea').value

        // console.log("Answer is: ",answer)
        let path = window.location.href.split("/")[4]
        if(path=="scoreAndRating"){
          let body = {
            ChatId: index,
            Answer: answer,
            UserId: localStorage.getItem("UserId"),
            BotId: BotId
          };
          console.log("Body is: ", body)
          fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/getchats/`, {
            method: "PUT",
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body),
            cache: "default",
          })
            .then(function (response) {
              console.log(response)
              if(response.status==400){
                swal({
                  title: "Oh Snap!",
                  text: "Answer can not be empty!",
                  icon: "error",
                  button: "Dismiss!",
                });
              }
              else{
                swal({
                  title: "Success!",
                  text: "Answer has been updated!",
                  icon: "success",
                  button: "Dismiss!",
                });
              }
              return response.json();
            })
            .then(function (data) {
              setOpen(false);
              console.log(data)
            })
            .catch((error) => console.error("Error:", error));
    }
        else{
          let body = {
            Id: index,
            Answer: answer
          };
          console.log("Body is: ", body)
          fetch(`${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/`, {
            method: "PUT",
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body),
            cache: "default",
          })
            .then(function (response) {
              console.log(response)
              if(response.status==400){
                swal({
                  title: "Oh Snap!",
                  text: "Answer can not be empty!",
                  icon: "error",
                  button: "Dismiss!",
                });
              }
              return response.json();
            })
            .then(function (data) {
              setOpen(false);
            })
            .catch((error) => console.error("Error:", error));
    }
        }
        
  return (
    <Container>
        <Typography
        className={styles.title}
        >Write Answer</Typography>
        <TextField
            className={styles.textArea}
            // id="textArea"
            onChange={(e)=>setAnswer(e.target.value)}
            multiline
            rows={8}
            maxRows={Infinity}
            />
            <Container align={"right"} sx={{mt:10}}>
              <Button className={styles.closeButton} onClick={() => {setOpen(false)}}>Close</Button>
              <Button className={styles.addAnswerButton} onClick={AddAnswer}>Add Answer</Button>
            </Container>
    </Container>
  )
}

export default AddAnswersManually