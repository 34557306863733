import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from "./PaymentCardFree.module.css";
import { Divider } from '@mui/material';
import Tick from "../../../../assets/img/organization/Tick.svg";
import Vector from "../../../../assets/img/organization/Vector.svg";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
      <Typography className={styles.CardTitle}>
        $0/year
      </Typography>
      <Typography className={styles.TextBold}>
        Lite Version
      </Typography>
      <Divider sx={{color: "#295598"}}/>
      <Box className={styles.ListItem}>
        <img alt=' ' src={Tick} />
        <Typography className={styles.TextCommon}>
          Transform your words into written form with Whisper AI.
        </Typography>
      </Box>
      <Box className={styles.ListItem}>
        <img alt=' ' src={Tick} />
        <Typography className={styles.TextCommon}>
          Transform your words into written form with Whisper AI.
        </Typography>
      </Box>
      <Box className={styles.ListItem}>
        <img alt=' ' src={Vector} />
        <Typography className={styles.TextCommon}>
          Transform your words into written form with Whisper AI.
        </Typography>
      </Box>
      <Box className={styles.ListItem}>
        <img alt=' ' src={Vector} />
        <Typography className={styles.TextCommon}>
          Transform your words into written form with Whisper AI.
        </Typography>
      </Box>
    </CardContent>
  </React.Fragment>
);

export default function PaymentCard() {
  return (
      <Card variant="outlined" className={styles.Card}>{card}</Card>
  );
}