import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import {  Rating} from '@mui/material';
const rows = [
  { Question: 'What is the company policy on remote work?' },
  { Question: 'How often are performance reviews conducted?' },
  { Question: 'What are the health benefits provided?' },
  { Question: 'What is the process for requesting time off?' },
  { Question: 'Are there opportunities for career growth?' },
  { Question: 'Does the company offer training programs?' },
  { Question: 'What is the dress code policy?' },
  { Question: 'How does the company handle conflicts?' },
  { Question: 'What are the working hours?' },
  { Question: 'What is the policy on overtime?' },
];

export default function PolicyQuestionTable() {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPage = 5;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const totalPages = Math.ceil(rows.length / rowsPerPage);
    // const lastPage = Math.min(totalPages, Math.ceil(rows.length / rowsPerPage));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleBackButtonClick = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    handleChangePage(event, page + 1);
  };

  return (
    <div className='flex flex-col gap-4'>

      {rows.map((row,i) => (<div className='flex px-4 w-full justify-between text-base items-center rounded-md p-4 border-2 text-left   border-gray-300'>
        <div className='w-[90%] flex gap-2'>
        <p >
                {i+1}
              </p>
              <p>
                {row.Question}
              </p>
              </div>
        <Rating size="small "name="read-only" className='w-[10%] mr-[3%]' value={5} readOnly />


      </div>))}
      {/* <table>
        <tbody >
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row,i) => (
            <tr key={row.name} >
              <td style={{ width: 10 }} align="right" sx={{ borderBottom: "none" }}>
                {i+1}
              </td>
              <td component="th" scope="row" sx={{ borderBottom: "none" }}>
                {row.Question}
              </td>
              <td style={{ width: 160 }} align="right" sx={{ borderBottom: "none" }}>
                {/* {row.calories} */}
                {/* <Rating name="read-only" value={5} readOnly />
              </td>
            </tr>
          ))}

          {emptyRows > 0 && (
            <tr style={{ height: 53 * emptyRows }}>
              <td colSpan={6} />
            </tr>
          )}
        </tbody>

      </table>
    <Box sx={{display:"flex",justifyContent:"center"}}>
        <TableFooter sx={{bgcolor:"white",boxShadow:"none",borderBottom:"none"}}>
          <TableRow sx={{borderBottom:"none !important",boxShadow:"none",display:"flex",alignItems:"center"}}>
    <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
            <TablePagination sx={{borderBottom:"none !important",display:"none",width:"10px"}}/>
            <Typography sx={{px:"8px",py:"2px",my:"10px",bgcolor:"#E1F4FF",borderRadius:"16px"}}>{page + 1}</Typography>
        <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
          </TableRow>
        </TableFooter>
            </Box>
            <Typography sx={{px:"8px",py:"2px",my:"10px"}}>{page + 1} of {totalPages} pages</Typography> */} 
    </div>
  );
}
