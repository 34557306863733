import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const StyledSlider = styled(Slider)({
  color: '#295598',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
});

export default function MultiRange({credits,setCredits,value,setValue}) {

  useEffect(() => {
    const totalCredits = value * 1000;
    setCredits(totalCredits);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography sx={{ mt: "-30px", mx: "auto", width: "100%", display: "flex", justifyContent: "center" }}>
        {credits.toLocaleString()}
      </Typography>
      <Tooltip enterTouchDelay={0} placement="top" title={value}>
        <Box sx={{ width: "100%", px: "10px" }}>
          <StyledSlider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-label="custom thumb label"
            defaultValue={20}
          />
        </Box>
      </Tooltip>
      <Typography sx={{ mt: "10px", mx: "auto", width: "100%", display: "flex", justifyContent: "center" }}>
        {value.toLocaleString("en-US", {style:"currency", currency:"USD"})}
      </Typography>
    </>
  );
}
