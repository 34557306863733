import * as React from "react";
import ChatboxNew from "../../components/Classroom/ChatboxNew/ChatboxNew";

import { useLocation } from "react-router-dom";

const TestMyAvatareNew = () => {
  const location = useLocation();
  console.log(location)
  const { ...state } = location.state || {} ;
 
  console.log(state)
  /* eslint eqeqeq: 0 */
  const [avatares, setAvatares] = React.useState([]);
  const [pretrainedModels, setPretrainedModels] = React.useState([]);
  const [selectedAvatare, setSelectedAvatare] = React.useState(1);
  const [chat, setChat] = React.useState([]);
  const [selectedPretrainedModel, setSelectedPretrainedModel] =
    React.useState("Elastic Search");
  const [botName, setBotName] = React.useState("Zofi");
  const userName = localStorage.getItem("email").split("@")[0];

  const scoreAndRating = () => {
    window.location.href = "/classroom/scoreAndRating/" + selectedAvatare;
  };

  const getTrainedBots = async () => {
    let userId = localStorage.getItem("UserId");
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/gettrainedbots/?UserId=${userId}`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };

  const getModelsList = async () => {
    // let UserId = "7ed282afb12842529c03dd069f0c83da ";
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/modelslist/`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => data.json());
  };

  // React.useEffect(() => {
  //   let mounted = true;
  //   getTrainedBots().then((items) => {
  //     console.log("Items: ", items);
  //     setAvatares(items?.message);
  //     setSelectedAvatare(items?.message[0]?.BotId?.BotId);
  //   });
  //   getModelsList().then((items) => {
  //     console.log("Items: ", items);
  //     setPretrainedModels(items?.message);
  //     setSelectedPretrainedModel(items?.message?.BotId?.BotId);
  //   });
  // }, []);
  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const trainedBots = await getTrainedBots();
        const modelList = await getModelsList();
        if (mounted) {
          setAvatares(trainedBots.message);
          setSelectedAvatare(trainedBots.message[0]?.BotId?.BotId);
          setPretrainedModels(modelList.message);
          setSelectedPretrainedModel(modelList.message[0]?.BotId?.BotId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error if needed
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    console.log("avatares", avatares);
  }, [avatares]);

  const data = [
    {
      imgSrc: "/radio.svg",
      text: "Company Info",
      bgColor: "bg-gray-400",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Pain Points",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Value Proposition",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Secret Sauce",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Business Model",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Use Case",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Marketing Plan",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
    {
      imgSrc: "/radio.svg",
      text: "Funding Options",
      bgColor: "bg-black",
      textColor: "text-[#828282]",
    },
  ];

  // const handleAvatareChange = (e) => {
  //   console.log(e);
  //   setBotName(avatares?.filter(item => item?.BotId?.BotId == e.target.value)[0]?.BotId?.BotName);
  //   setSelectedAvatare(e.target.value);
  //   setChat([])
  //   return true
  // };

  return (
    <div className="w-full">
      
      <div className="bg-[#F7F8FC]">
        <div>
          <ChatboxNew
            {...state}
            SelectedPretrainedModel={selectedPretrainedModel}
            BotId={selectedAvatare}
            BotName={botName}
            userName={userName}
            chat={chat}
            setChat={setChat}
          />
        </div>
        {/* <Container
            sx={{
              height: "30vh",
              width: "30%",
              ml: 5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                bgcolor: "#E1F4FF",
                height: "25vh",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography fontSize={20} sx={{ mt: 2 }}>
                Select your trained Avatar
              </Typography>
              {avatares && avatares.length > 0 ? (
                <FormControl sx={{ m: 1, minWidth: 300, backgroundColor: "white" }}>
                  <InputLabel id="demo-simple-select-label" sx={{ fontSize: 12 }}>
                    Select your trained Avatar
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select your trained avatar"
                    width="300px"
                    placeholder="ElasticSearch"
                    value={selectedAvatare}
                    name={botName}
                    onChange={handleAvatareChange}
                  >
                    {Array.isArray(avatares) ? avatares?.map((bot) =>
                    (
                      <MenuItem value={bot?.BotId?.BotId} key={bot?.BotId?.BotId}>
                        {bot?.BotId?.BotName}
                      </MenuItem>
                    )
                    ) : <MenuItem value={null}>
                      No Avatar
                    </MenuItem>}
                  </Select>
                </FormControl>
              ) : (
                <div>Loading...</div>
              )}
            </Box>
            <Box
              sx={{
                bgcolor: "#E1F4FF",
                height: "25vh",
                // width: "20vw!important",
                mt: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography fontSize={20} sx={{ mt: 2 }}>
                Select from a pre-trained model
              </Typography>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 300,
                  height: 50,
                  backgroundColor: "white",
                }}
              >
                <InputLabel id="demo-simple-select-label" sx={{ fontSize: 12 }}>
                  Select a pre-trained model
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select from a pre-trained model"
                  width="100%"
                  maxHeight="10px"
                  value={selectedPretrainedModel}
                  onChange={(e) => setSelectedPretrainedModel(e.target.value)}
                >
                  {pretrainedModels.map((model) => (
                    <MenuItem value={Object.values(model)[0]}>
                      {Object.keys(model)[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Container> */}
      </div>
    </div>
  );
};

export default TestMyAvatareNew;
