import React from 'react'
import Container from "@mui/material/Container";
import AvatareModelTraining from "../../Administration/AvatareModelTraining/AvatareModelTraining";import { Typography } from '@mui/material';
;

const AvataresContainer = ({ avatares,getData, pageName, SetRerender, trainmymodel,totalCredits,setChangeCredits }) => {
    /* eslint eqeqeq: 0 */
    const SelectModel = (botId) => {
        console.log("href is: ", window.location.href.split("/")[4])
        if (window.location.href.split("/")[4] == "trainBySubject") {
            window.location.href = "/classroom/selectSubjectsToTrain/" + botId
        }
        else {
            window.location.href = "/classroom/selectModel/" + botId
        }
    }
    React.useEffect(() => {
        console.log("Avatares container rerendered")
    }, [])
    return (
        <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: "wrap",
          m: "0px!important",
          p: "0px!important"
        }}
      >
        {avatares == "No Avatars Yet" ? (
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", my: "20px" }}>No Avatars Yet</Typography>
        ) : (
          avatares?.map((avatare) => (
            <div >
              <AvatareModelTraining
                AllCredits={totalCredits}
                trainmymodel={trainmymodel}
                BotId={avatare.BotId}
                name={avatare.BotName}
                description={avatare.BotDescription}
                button_message={avatare.buttonMess}
                emoji={avatare?.emojiS3Link}
                pageName={pageName}
                SetRerender={SetRerender}
                SelectModel={SelectModel}
                setChangeCredits={setChangeCredits}
                getData={getData}
              />
            </div>
          ))
        )}
      </Container>
    )
}

export default AvataresContainer