import React from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import styles from "./BottomProgressStep2.module.css";

const BottomProgressStep2 = ({ title, message, onClickNext }) => {
  const [currentScreen, setCurrentScreen] = React.useState(
    window.location.href.split("/")[4]
  );
  return (
    <div>
      <Container className={styles.outermostCard} sx={{padding:"0px !important"}}>
        <Container sx={{ display: "flex", justifyContent:"flex-start",padding:"0px !important",width:"100% !important",marginLeft:"0px" }}>
          <Box className={styles.dataBox2}>
            <Typography
              variant="h7"
              className={styles.textStyles}
              sx={{ color: "#295598" }}
            >
              Name your avatare
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              Please name your avatare
            </Typography>
          </Box>
          <Box className={styles.dataBox}>
            <Typography
              variant="h7"
              className={styles.textStyles}
              sx={{ color: "#295598" }}
            >
              {title}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>{message}</Typography>
          </Box>
          <Container className={styles.triangle}></Container>
        </Container>
        {currentScreen == "addMyEmoji" ? (
          <>
          <Box
          sx={{width:"15vw",
        backgroundColor:"#295598"}}>
          </Box></>
        ) : (
          <>
            <Box
              className={styles.nextBox}
              minWidth={"150px!important"}
              onClick={onClickNext}
            >
              <Typography variant="h7" className={styles.textStyles}>
                Next
              </Typography>
              <ArrowCircleRightIcon
                sx={{ color: "#295598",mx:"5px"}}
              ></ArrowCircleRightIcon>
            </Box>
          </>
        )}
      </Container>
    </div>
  );
};

export default BottomProgressStep2;
