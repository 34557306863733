import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import { Typography, Breadcrumbs, InputLabel, FormControl } from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import MicIcon from '@mui/icons-material/Mic';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UploadBox from "../../components/Research/UploadBox";
import StartRecording from "../../components/Research/StartRecording";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const WhisperAI = () => {
  const navigate = useNavigate()
  const [audioFile, setAudioFile] = useState({})
  // console.log('audio File ::= ', audioFile);
  // console.log('audio File duration : ',audioFile?.duration);

  useEffect(()=>{
      if(audioFile?.file){
        swal({
          title: "Success",
          text: "Your file is uploaded Successfully",
          icon: "success",
          button: "Dismiss!",
        });
        navigate("/research/whisperplayground",{state:audioFile})
      }
  },[audioFile])

  // const addAudioElement = (blob) => {
  //   const url = URL.createObjectURL(blob);
  //   const audio = document.createElement("audio");
  //   audio.src = url;
  //   audio.controls = true;
  //   document.body.appendChild(audio);
  // };

  // const handleRecordingComplete = (blob) => {
  //   setAudioFile({file : blob ,duration:""});
  // };


  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <Breadcrumbs_component Left="Research & Development" Right="Speech to Text"/>
        <Typography fontSize={22} sx={{ m:2, fontWeight: "bold", display:"flex", justifyContent:"left"}}>
            Speech to text {'>'} Upload a new File
          </Typography>
        <Typography fontSize={22} sx={{ m:2, fontWeight: "bold", display:"flex", justifyContent:"left"}}>
            Attach Files
        </Typography>
        <Box sx={{width:"100%"}}>
        <UploadBox setAudioFile={setAudioFile}/>
        </Box>
        <Typography sx={{float:"left", color: "#5C5C5C"}}>Accepted File Types:.mp3, .mpeg, .mpga only.</Typography>
        <Typography sx={{ml:"32vw", fontWeight: "bold", mt:4, mb:4}}>OR</Typography>
        <Box sx={{width:"100%"}}>
        <StartRecording  setAudioFile={setAudioFile} />
        </Box>
        {/* <Typography sx={{mt:4}}>Back</Typography> */}
        {/* <KeyboardBackspaceIcon/> */}
      </Container>
    </React.Fragment>
  );
};

export default WhisperAI;