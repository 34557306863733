

// src/components/Sidebar.js
import { AccountCircle } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ setAllCredits, totalCredits, changeCredits }) => {
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState("home");
  const [active, setActive] = useState("-1");
  const [outerActive, setOuterActive] = useState(null);

  const handleSubMenu = (menu, active) => {
    setActive("2");
    setOuterActive(active);
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const handleLinkClick = (link, active) => {
    if (link === "/" || active ==="17" || active ==="18") {
      setOuterActive(null);
      setOpenSubMenu("home");
    }
    setActive(active);
    navigate(link);
  };

  const [userData, setUserData] = useState(null);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("UserId");

  // const [totalCredits,setTotalCredits] = React.useState({})
  const email = localStorage.getItem("email");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/auth/getcredits/?Id=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const dataa = await response.json();
        // setTotalCredits(dataa);
        setAllCredits(dataa);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [changeCredits]);

  React.useEffect(() => {
    setUserData(email);
    var value = localStorage.getItem("isOrganizer");
    var isTrueSet = value === "true";
    setIsOrganizer(isTrueSet);
  }, []);

  return (
    <div
      style={{
        background:"linear-gradient(0deg, #497964 5.56%, #20556E 99.7%)"
      }}
      className="flex flex-col xs:w-[0%] sm:w-[0%] md:w-[0%] lg:w-[20%] fixed h-screen  overflow-y-auto sidebar text-white"
    >
      <div className=" flex py-[18px]  px-[21px]  justify-between items-center border-b-[1px]">
        <img src="/Logo.svg" />
        <img src="/search_white.svg" />
      </div>
      <div
        style={{ background: "rgba(255, 255, 255, 0.10)" }}
        className="px-[33px] py-[10px] mt-[21px] flex gap-4"
      >
        <img src="/credit_icon.svg" className="w-10 h-10 flex self-center" />
        <div>
          <h2 className="text-base font-medium">4000 (Credits)</h2>
          <p className="text-xs">required to make purchase</p>
        </div>
      </div>
      <div className="pl-[24px] pt-[26px]" >
        <p className="sidebar-section-text font-xs font-medium">Sequential StartUp</p>
        <div className="mt-3 border-l  border-[#7FB04F] h-auto">
        <div
          style={{
            backgroundColor:
              active === "-1" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
          onClick={(e) => handleLinkClick("/", "-1")}
          className="flex hover-effect py-[10px] px-[14px] cursor-pointer text-sm gap-4"
        >
          <img src="/progress-icon.svg" className="w-[21px] h-[21px]" />
          <a>My Progress</a>
        </div>
        <div
          style={{
            backgroundColor:
              active === "0" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
          onClick={(e) => handleLinkClick("/sequential-startup/templates-libray", "0")}
          className="flex hover-effect py-[10px] px-[14px] cursor-pointer text-sm gap-4"
        >
          <img src="/template-library-icon.svg" className="w-[21px] h-[21px]" />
          <a>Templates Library</a>
        </div>
        <div
          style={{
            backgroundColor:
              active === "1" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
          onClick={(e) => handleLinkClick("/sequential-startup/templates-history", "1")}
          className="flex hover-effect py-[10px] px-[14px] cursor-pointer text-sm gap-4"
        >
          <img src="/history-icon.svg" className="w-[21px] h-[21px]" />
          <a>History</a>
        </div>


        </div>
      </div>
      <div className="pl-[24px] mt-[36px]" >
      <p className="sidebar-section-text font-xs font-medium">Conversational AI</p>
      <div className="mt-3 border-l  border-[#7FB04F] h-auto">
      <nav className="flex flex-col space-y-2">
        <div
          className="hover-effect pb-[1px]"
          style={{
            backgroundColor:
              outerActive === "1" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
        >
          <div
            onClick={() => handleSubMenu("admin", "1")}
            className="flex cursor-pointer items-center text-sm gap-4 px-[14px] py-[10px]"
          >
            <img src="/administration.svg" className="w-[21px] h-[21px]" />
            <button className="flex items-center justify-between w-full">
              Administration
              <span>
                {openSubMenu === "admin" ? (
                  <img src="/arrow_dropdown.svg" />
                ) : (
                  <img src="/arrow_dropdown_upward.svg" className="" />
                )}
              </span>
            </button>
          </div>
          {openSubMenu === "admin" && (
            <div
              style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.40" }}
              className="flex flex-col text-sm cursor-pointer ml-6 my-[14px]"
            >
              <a
                onClick={(e) => handleLinkClick("/admin/createAvatar", "2")}
                style={{
                  backgroundColor:
                    active === "2" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "2" && "1px solid #Fff",
                }}
                className="hover-effect-inner  p-2 pl-4 "
              >
                Create My Avatare
              </a>
              <a
                onClick={(e) => 
                  // handleLinkClick("/admin/addEmoji", "3")
                  handleLinkClick("/admin/myAvatare", "3")

                }
                className="hover-effect-inner p-2 pl-4"
                style={{
                  backgroundColor:
                    active === "3" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "3" && "1px solid #Fff",
                }}
              >
                My Avatare
              </a>
              <a
                onClick={(e) => handleLinkClick("/admin/cloneAvatare", "4")}
                className="hover-effect-inner p-2 pl-4 "
                style={{
                  backgroundColor:
                    active === "4" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "4" && "1px solid #Fff",
                }}
              >
                Clone Avatare
              </a>
              {/* <a  href="#" className="hover-effect-inner p-2 pl-4 ">User Administration </a> */}
            </div>
          )}
        </div>
        <div
          className="hover-effect pb-[1px]"
          style={{
            backgroundColor:
              outerActive === "2" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
        >
          <div
            onClick={() => handleSubMenu("library", "2")}
            className="flex  cursor-pointer items-center text-sm  gap-4 px-[14px] py-[10px]"
          >
            <img src="/Library.svg" className="w-[21px] h-[21px]" />
            <button className="flex items-center justify-between w-full">
              Library
              <span>
                {openSubMenu === "library" ? (
                  <img src="/arrow_dropdown.svg" />
                ) : (
                  <img src="/arrow_dropdown_upward.svg" />
                )}
              </span>
            </button>
          </div>
          {openSubMenu === "library" && (
            <div
              style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.40" }}
              className="flex flex-col text-sm cursor-pointer ml-6 my-[14px]"
            >
              <a
                onClick={(e) => handleLinkClick("/library/quickstart", "5")}
                style={{
                  backgroundColor:
                    active === "5" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "5" && "1px solid #Fff",
                }}
                className="hover-effect-inner  p-2 pl-4 "
              >
                QuickStart Tutorials
              </a>
              <a
                onClick={(e) => handleLinkClick("/library/datafiles", "6")}
                style={{
                  backgroundColor:
                    active === "6" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "6" && "1px solid #Fff",
                }}
                className="hover-effect-inner p-2 pl-4"
              >
                Data Files
              </a>
              <a
                onClick={(e) => handleLinkClick("/library/translation", "7")}
                style={{
                  backgroundColor:
                    active === "7" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "7" && "1px solid #Fff",
                }}
                className="hover-effect-inner p-2 pl-4 "
              >
                Translation Glossary
              </a>
              {/* <a  href="#" className="hover-effect-inner p-2 pl-4 ">User Administration </a> */}
            </div>
          )}
        </div>
        <div
          className="hover-effect  pb-[1px]"
          style={{
            backgroundColor:
              outerActive === "3" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
        >
          <div
            onClick={() => handleSubMenu("classroom", "3")}
            className="flex  items-center text-sm cursor-pointer  gap-4 px-[14px] py-[10px]"
          >
            <img src="/classroom.svg" className="w-[21px] h-[21px]" />
            <button className="flex items-center justify-between w-full">
              Classroom
              <span>
                {openSubMenu === "classroom" ? (
                  <img src="/arrow_dropdown.svg" />
                ) : (
                  <img src="/arrow_dropdown_upward.svg" />
                )}
              </span>
            </button>
          </div>
          {openSubMenu === "classroom" && (
            <div
              style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.40" }}
              className="flex flex-col text-sm cursor-pointer ml-6 my-[14px]"
            >
              <a
                onClick={(e) => handleLinkClick("/classroom/trainMyModel", "8")}
                style={{
                  backgroundColor:
                    active === "8" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "8" && "1px solid #Fff",
                }}
                className="hover-effect-inner  p-2 pl-4 "
              >
                Train By AI Model
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick("/classroom/trainBySubject", "9")
                }
                style={{
                  backgroundColor:
                    active === "9" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "9" && "1px solid #Fff",
                }}
                className="hover-effect-inner p-2 pl-4"
              >
                Train My Subject
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick("/classroom/testMyAvatare", "10")
                }
                style={{
                  backgroundColor:
                    active === "10" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "10" && "1px solid #Fff",
                }}
                className="hover-effect-inner p-2 pl-4 "
              >
                Test my Avatare
              </a>
              {/* <a  href="#" className="hover-effect-inner p-2 pl-4 ">User Administration </a> */}
            </div>
          )}
        </div>

        <div
          className="hover-effect pb-[1px]"
          style={{
            backgroundColor:
              outerActive === "4" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
        >
          <div
            onClick={() => handleSubMenu("operations", "4")}
            className="flex items-center text-sm cursor-pointer  gap-4 px-[14px] py-[10px]"
          >
            <img src="/operations.svg" className="w-[21px] h-[21px]" />
            <button className="flex items-center justify-between w-full">
              Operations
              <span>
                {openSubMenu === "operations" ? (
                  <img src="/arrow_dropdown.svg" />
                ) : (
                  <img src="/arrow_dropdown_upward.svg" className="" />
                )}
              </span>
            </button>
          </div>
          {openSubMenu === "operations" && (
            <div
              style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.40" }}
              className="flex flex-col text-sm cursor-pointer ml-6 my-[14px]"
            >
              <a
                onClick={(e) => handleLinkClick("/operations/analytics", "11")}
                className="hover-effect-inner  p-2 pl-4 "
                style={{
                  backgroundColor:
                    active === "11" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "11" && "1px solid #Fff",
                }}
              >
                Analytics
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick("/operations/integrations", "12")
                }
                className="hover-effect-inner p-2 pl-4"
                style={{
                  backgroundColor:
                    active === "12" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "12" && "1px solid #Fff",
                }}
              >
                Integrations
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick("/operations/documentation", "13")
                }
                className="hover-effect-inner p-2 pl-4 "
                style={{
                  backgroundColor:
                    active === "13" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "13" && "1px solid #Fff",
                }}
              >
                Documentation
              </a>
              {/* <a  href="#" className="hover-effect-inner p-2 pl-4 ">User Administration </a> */}
            </div>
          )}
        </div>
        <div
          className="hover-effect pb-[1px]"
          style={{
            backgroundColor:
              outerActive === "5" ? "rgba(255, 255, 255, 0.10)" : "initial",
          }}
        >
          <div
            onClick={() => handleSubMenu("research", "5")}
            className="flex items-center text-sm cursor-pointer  gap-4 px-[14px] py-[10px]"
          >
            <img src="/research.svg" className="w-[21px] h-[21px]" />
            <button className="flex items-center justify-between w-full">
              Research & Dev
              <span>
                {openSubMenu === "research" ? (
                  <img src="/arrow_dropdown.svg" />
                ) : (
                  <img src="/arrow_dropdown_upward.svg" className="" />
                )}
              </span>
            </button>
          </div>
          {openSubMenu === "research" && (
            <div
              style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.40" }}
              className="flex flex-col text-sm cursor-pointer ml-6 my-[14px]"
            >
              <a
                onClick={(e) => handleLinkClick("/research/whisperAI", "14")}
                className="hover-effect-inner  p-2 pl-4 "
                style={{
                  backgroundColor:
                    active === "14" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "14" && "1px solid #Fff",
                }}
              >
                Whisper AI
              </a>
              <a
                onClick={(e) => handleLinkClick("/research/dallE", "15")}
                className="hover-effect-inner p-2 pl-4"
                style={{
                  backgroundColor:
                    active === "15" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "15" && "1px solid #Fff",
                }}
              >
                Dall E
              </a>
              <a
                onClick={(e) => handleLinkClick("/research/chatGPT", "16")}
                className="hover-effect-inner p-2 pl-4 "
                style={{
                  backgroundColor:
                    active === "16" ? "rgba(255, 255, 255, 0.20)" : "initial",
                  borderLeft: active === "16" && "1px solid #Fff",
                }}
              >
                Chat GPT
              </a>
            </div>
          )}
        </div>
      </nav>
      </div>
      </div>
      <div className="pl-[24px] mt-[20%]" >
      <p className="sidebar-section-text font-xs font-medium">Admin</p>
      <div className="mt-3 border-l  border-[#7FB04F] h-auto">
      <nav className="flex flex-col  mb-[10%] space-y-2">
        <div
          onClick={(e) => handleLinkClick("#", "17")}
          className="flex hover-effect items-center text-sm cursor-pointer  gap-4 px-[14px] py-[10px]"
          style={{
            backgroundColor:
              active === "17" ? "rgba(255, 255, 255, 0.20)" : "initial",
          }}
        >
          <img src="/operations.svg" className="w-[21px] h-[21px]" />
          <a>Settings</a>
        </div>
        <div
          onClick={(e) => handleLinkClick("#", "18")}
          className="flex hover-effect items-center text-sm cursor-pointer  gap-4 px-[14px] py-[10px]"
          style={{
            backgroundColor:
              active === "18" ? "rgba(255, 255, 255, 0.20)" : "initial",
          }}
        >
          <img src="/help.svg" className="w-[21px] h-[21px]" />
          <a>Help Desk</a>
        </div>
      </nav>
      </div>
      </div>

      <div
        style={{ background: "rgba(255, 255, 255, 0.10)" }}
        className="px-[33px] py-[20px] mb-[10%] items-center text-sm flex  cursor-pointer gap-4"
      >
        <AccountCircle />
        <div className="flex gap-8 justify-between ">
          {/* <h2 className="text-lg font-semibold">4000 (Credits)</h2> */}
          <p className="text-sm">{userData}</p>
          <img
            src="/arrow_dropdown.svg"
            className=" w-[21px] h-[21px] transform rotate-90"
          />
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
