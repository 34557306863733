import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { TextField, Typography } from "@mui/material";
import Breadcrumbs_component from "../../../components/common/Breadcrumbs";

import Card from "@mui/material/Card";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CardMedia from "@mui/material/CardMedia";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import NoOfQuestionChart from "./NoOfQuestionChart";
import NoOfSessionChart from "./NoOfSessionChart";
import { useLocation } from "react-router-dom"
import { useState } from "react";
// import SelectRange from "./SelectRange";
import { FormControl, } from '@mui/material';
import { Select } from "antd";
// import { Select, MenuItem } from '@mui/material';


const data = [
 "7 days",
 "Month",
 "Year"
];

const optionsList = data.map((data) => ({
  value:data.trim().toLowerCase() ==="month" || data.trim().toLowerCase() ==="year" ? "1"+ data.trim().toLowerCase(): data.trim().toLowerCase(),
  label: data,
}));




const outermostCard = {
  width: "24%",
  height: "150px",
  margin: "0px 10px",
  border: "dashed grey",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
const ActivityOverview = ({superAdminEmail,id}) => {

const [questionData, setQuestionData] = useState()
const [sessionData, setSessionData] = useState()
const [selectedOption, setSelectedOption] = useState('7 days');


const [selectedDate, setSelectedDate] = useState()
const [selectedYear, setSelectedYear] = useState()

const [chartData, setChartData] = React.useState({})
const token = localStorage.getItem('token');
const userId = localStorage.getItem('UserId');
const isOrganizer = localStorage.getItem('isOrganizer');
const OrganizationId = localStorage.getItem('OrganizationId');

const [allMember, setAllMember] = useState()
const [allIds, setAllIds] = useState(true)
const [allMemberId, setAllMemberId] = useState(null)
const [allAvatars, setAllAvatars] = useState()
const [allAvatarsId, setAllAvatarsId] = useState(null)
const [selectActivityMember,setSelectActivityMember] = useState(null)
const [selectActivityAvatare,setSelectActivityAvatare] = useState(null)
console.log("allMemberId :",allMemberId);
console.log("allMember :",allMember);
console.log("allAvatarsId :",allAvatarsId);
console.log("allAvatars :",allAvatars);
console.log("selectActivityMember :",selectActivityMember);
console.log("selectActivityAvatare :",selectActivityAvatare);
console.log("allIds :",allIds);


React.useEffect(() => {
  if (allMember && allMember.message) {
    const allMemberIds = allMember.message.map((id) => id.Id).join(",");
    setAllMemberId(allMemberIds);
    setSelectActivityMember(allMemberId)
  }
}, [allMember]);
React.useEffect(() => {
  if(allMemberId !== selectActivityMember ){
    setAllIds(false)
  }
}, [selectActivityMember,selectActivityAvatare]);

React.useEffect(() => {
  if (allAvatars && allAvatars.message) {
    const allAvatarsIds = allAvatars.message.map((id) => id.BotId).join(",");
    setSelectActivityAvatare(allAvatarsId)
  }
}, [allAvatars]);


  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  const handleUserActivity = async () => {
    try {
      let endpoint = "";
  
      if (isOrganizer === "false") {
        if (selectActivityAvatare !== null) {
          endpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivity/?UserId=${userId}&BotId=${selectActivityAvatare}&OrganizationId=${isOrganizer === "false" ? OrganizationId : allMember?.message[0]?.OrganizationId}`;
        } else {
          endpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivity/?UserId=${userId}&OrganizationId=${isOrganizer === "false" ? OrganizationId : allMember?.message[0]?.OrganizationId}`;
        }
      } else {
        if (selectActivityAvatare !== null) {
          endpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivity/?${allIds==true ?`all_ids=`:`UserId=`}${userId}&OrganizationId=${OrganizationId}&BotId=${id}`;
        } else {
          endpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivity/?${allIds==true ?`all_ids=`:`UserId=`}${userId}&OrganizationId=${OrganizationId}`;
        }
      }
  
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const dataa = await response.json();
        setChartData(dataa);
        console.log(" dataa dataa ::: ", dataa);
      } else {
        const dataa = await response.json();
        console.log(" dataa dataa ::: ", dataa);
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    handleUserActivity();
  }, [selectActivityAvatare,selectActivityMember,allIds]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/useractivity/?BotId=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const dataa = await response.json();
        setChartData(dataa);
        console.log(dataa, "");
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchAllMember = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?OrganizationId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        // console.log(dataa, "");

        setAllMember(dataa)
      } else {
        const dataa = await response.json();
        console.log('Error:', response.status, dataa);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchAllMember();
  }, []);


  const fetchAllAvatar = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${userId}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        setAllAvatars(data)
      } else {
        const errorData = await response.json();
        console.log('Error:', response.status, errorData);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    fetchAllAvatar();
  }, []);
  



  const handleQuestion = async () => {
    try {
      let questionsEndpoint = "";
      if (isOrganizer === "false") {
        if (selectActivityAvatare !== null) {
          questionsEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivityquestiongraph`:selectedOption === '1month'?`useractivityquestiongraphmonthly`:`useractivityquestiongraphannually`}/?UserId=${userId}&BotId=${selectActivityAvatare}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        } else {
          questionsEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivityquestiongraph`:selectedOption === '1month'?`useractivityquestiongraphmonthly`:`useractivityquestiongraphannually`}/?UserId=${userId}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        }
      } else {
        if (selectActivityAvatare !== null) {
          questionsEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivityquestiongraph`:selectedOption === '1month'?`useractivityquestiongraphmonthly`:`useractivityquestiongraphannually`}/?${allIds==true ?`all_ids=`:`UserId=`}${selectActivityMember}&BotId=${selectActivityAvatare}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        } else {
          questionsEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivityquestiongraph`:selectedOption === '1month'?`useractivityquestiongraphmonthly`:`useractivityquestiongraphannually`}/?${allIds==true ?`all_ids=`:`UserId=`}${selectActivityMember}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        }
      }

      const response = await fetch(questionsEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setQuestionData(dataa);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  
  
  const handleSession = async () => {
    try {
      // let url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivitysessiongraph/?BotId=${userdata?.BotId}`; // Initialize the URL

      // if (selectedOption === '7days') {
      //   url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivitysessiongraph/?BotId=${userdata?.BotId}`;
      //   url += '&days=7';
      // } else if (selectedOption === '1month') {
      //   url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivitysessiongraphmonthly/?BotId=${userdata?.BotId}`;
      //   url += `&Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`;
      // } else if (selectedOption === '1year') {
      //   url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivitysessiongraphannually/?BotId=${userdata?.BotId}`;
      //   url += `&Year=${selectedYear?.$y}`; // Adjust the number of days for a year
      // }
      let sessionEndpoint = "";
      if (isOrganizer === "false") {
        if (selectActivityAvatare !== null) {
          sessionEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivitysessiongraph`:selectedOption === '1month'?`useractivitysessiongraphmonthly`:`useractivitysessiongraphannually`}/?UserId=${userId}&BotId=${selectActivityAvatare}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        } else {
          sessionEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivitysessiongraph`:selectedOption === '1month'?`useractivitysessiongraphmonthly`:`useractivitysessiongraphannually`}/?UserId=${userId}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        }
      } else {
        if (selectActivityAvatare !== null) {
          sessionEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivitysessiongraph`:selectedOption === '1month'?`useractivitysessiongraphmonthly`:`useractivitysessiongraphannually`}/?${allIds==true ?`all_ids=`:`UserId=`}${selectActivityMember}&BotId=${selectActivityAvatare}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        } else {
          sessionEndpoint = `${process.env.REACT_APP_API_ROOT}/api/bot/${selectedOption == '7days'?`useractivitysessiongraph`:selectedOption === '1month'?`useractivitysessiongraphmonthly`:`useractivitysessiongraphannually`}/?${allIds==true ?`all_ids=`:`UserId=`}${selectActivityMember}&${selectedOption === '1month'?`Month=${selectedDate?.$M+1}&Year=${selectedDate?.$y}`:selectedOption === '1year'?`Year=${selectedYear?.$y}`:""}`;
        }
      }

      const response = await fetch(sessionEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setSessionData(dataa);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    handleQuestion();
    handleSession();
  }, [selectedOption, selectedDate, selectedYear,chartData]);


  return (
    <div>     
      <div className="flex justify-start items-center gap-2 mt-[2%]">

               <FormControl sx={{ minWidth: 120,mt:1,mr:"10px"}}>
            
             <Select
              labelId="data-range-label"
              style={{ height: '55px', lineHeight: '50px' }}  // Adjust the height here
              id="data-range-select"
              optionFilterProp="label"
              size="large"
              value={selectedOption}
              options={optionsList}
              onChange={handleOptionChange}/>
              
            
          </FormControl>
          {selectedOption === '1month' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  
                  value={selectedDate}
                  views={['month', 'year']}
                  slotProps={{
                    textField: () => ({
                      color: 'success',
                      
                    }),
                  }}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} placeholder="Select Month and Year"  InputProps={{
                    ...params.InputProps,
                    sx: {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'green',  
                      },
                    }
                  }} />}
                
                />
              </DemoContainer>
            </LocalizationProvider>
          )}
          {selectedOption === '1year' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  views={['year']}
                  slotProps={{
                    textField: () => ({
                      color: 'success',
                      
                    }),
                  }}
                  value={selectedYear}
                  onChange={(newValue) => setSelectedYear(newValue)}
                  renderInput={(params) => <TextField {...params} placeholder="Select Year" />}
                  open={selectedYear ? false : true}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}

        </div>
        <div className="mt-[3%] flex w-full gap-3">
          <div className="flex flex-col gap-1 bg-green-50 justify-center items-center pt-2 pb-4 px-8 rounded-lg border-[0.5px] border-gray-400 border-opacity-60">
            <h1 className="font-medium"> {chartData?.message?.Total_Questions !== null ? chartData?.message?.Total_Questions : 0 }</h1>
            <p>Number of Questions</p>
            <img src="/graph.svg"/>

          </div>
          <div className="flex flex-col gap-1 bg-green-50 justify-center items-center pt-2 pb-4 px-8 rounded-lg border-[0.5px] border-gray-400 border-opacity-60">
            <h1 className="font-medium"> {chartData?.message?.Total_Sessions !== null ? chartData?.message?.Total_Sessions : 0}</h1>
            <p>Number of Sessions</p>
            <img src="/graph.svg"/>

          </div>
          <div className="flex flex-col gap-1 bg-green-50 justify-center items-center pt-2 pb-4 px-8 rounded-lg border-[0.5px] border-gray-400 border-opacity-60">
            <h1 className="font-medium"> {chartData?.message?.Total_Bots !== null ? chartData?.message?.Total_Bots : 0 }</h1>
            <p>Number of Bots</p>
            <img src="/graph.svg"/>

          </div>

         

        </div>
        <div className="mt-[5%]" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
           <div
            className="w-[48%] bg-[#F0F0F0] my-3 flex p-6 item-center rounded-md border-2 border-gray-400 flex-col"
           
          >
            <div className="w-full">
              <NoOfQuestionChart chartData={questionData} />
            </div>
          </div>
          <div
              className="w-[48%] bg-[#F0F0F0] my-3 flex p-6 item-center rounded-md border-2 border-gray-400 flex-col"
           
          >
            <div className="w-full">
              <NoOfSessionChart filteredData={sessionData} />
            </div>
          </div>
        </div>
    </div>

    // <React.Fragment>
    //   <CssBaseline />
    //   <Container
    //     fixed
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       // margin:"3vw"
    //     }}
    //   >
    //     <Breadcrumbs_component Left="Operations" Right="Analytics Overview" allMemberId={allMemberId} allMember={allMember} allAvatars={allAvatars} allAvatarsId={allAvatarsId} setSelectActivityMember={setSelectActivityMember} setSelectActivityAvatare={setSelectActivityAvatare} selectActivityMember={selectActivityMember} selectActivityAvatare={selectActivityAvatare} setAllIds={setAllIds}/>
    //     <Typography fontSize={24} fontWeight={600} sx={{ display: "flex", m: 2, justifyContent: "start" }}>
    //       User Activity Overview
    //     </Typography>
    //     <Box
    //       sx={{
    //         bgcolor: "#E1F4FF",
    //         height: "100%",
    //         width: "100%",
    //         display: "flex",
    //         py: 4,
    //         alignItems: "center",
    //         flexDirection: "column"
    //       }}
    //     >
    //       <Box sx={{ display: "flex", justifyContent: superAdminEmail ? "center" : "start", width: "100%" }}>
    //         <Card sx={outermostCard}>
    //           <CardMedia
    //           // className={styles.cardMedia}
    //           >
    //             <Typography fontSize={26} fontWeight="bold" py="10px">
    //               {chartData?.message?.Total_Questions !== null ? chartData?.message?.Total_Questions : 0 }
    //             </Typography>
    //             <Typography fontSize={16} fontWeight="bold" marginBottom="5px">
    //               Total Questions
    //             </Typography>
    //           </CardMedia>
    //           {/* <CardContent >
    //             <Bar options={options} data={data}  />
    //           </CardContent> */}
    //         </Card>
    //         <Card sx={outermostCard}>
    //           <CardMedia
    //           // className={styles.cardMedia}
    //           >
    //             <Typography fontSize={26} fontWeight="bold" py="10px">
    //               {chartData?.message?.Total_Sessions !== null ? chartData?.message?.Total_Sessions : 0}
    //             </Typography>
    //             <Typography fontSize={16} fontWeight="bold" marginBottom="5px">
    //               No of Sessions
    //             </Typography>

    //           </CardMedia>
    //         </Card>
    //         <Card sx={outermostCard}>
    //           <CardMedia
    //           // className={styles.cardMedia}
    //           >
    //             <Typography fontSize={26} fontWeight="bold" py="10px">
    //               {chartData?.message?.Total_Bots !== null ? chartData?.message?.Total_Bots : 0}
    //             </Typography>
    //             <Typography fontSize={16} fontWeight="bold" marginBottom="5px">
    //               No of Bots
    //             </Typography>

    //           </CardMedia>
    //         </Card>
    //         {superAdminEmail=="daren.jackson@avatare.com" && (
    //         <Card sx={outermostCard}>
    //           <CardMedia
    //           // className={styles.cardMedia}
    //           >
    //             <Typography fontSize={26} fontWeight="bold" py="10px">
    //               {chartData?.message?.Total_Sessions !== null ?chartData?.message?.Total_Sessions : 0}
    //             </Typography>
    //             <Typography fontSize={16} fontWeight="bold" marginBottom="5px">
    //               No of Sessions
    //             </Typography>
    //           </CardMedia>
    //         </Card>

    //         )}
    //       </Box>
    //     </Box>
    //     <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "end" }}>

    //       <FormControl sx={{ minWidth: 120, mt: 2 ,mr:"10px"}}>
    //         <Typography id="data-range-label" sx={{}}>Select Data Range</Typography>
    //         <Select
    //           labelId="data-range-label"
    //           id="data-range-select"
    //           size="large"
    //           value={selectedOption}
    //           onChange={handleOptionChange}
    //         >
    //           <MenuItem value="7days">7 Days</MenuItem>
    //           <MenuItem value="1month">1 Month</MenuItem>
    //           <MenuItem value="1year">1 Year</MenuItem>
    //         </Select>
    //       </FormControl>
    //       {selectedOption === '1month' && (
    //         <LocalizationProvider dateAdapter={AdapterDayjs}>
    //           <DemoContainer components={['DatePicker', 'DatePicker']}>
    //             <DatePicker
    //               label="Select Month and Year"
    //               value={selectedDate}
    //               views={['month', 'year']}
    //               onChange={(newValue) => setSelectedDate(newValue)}
    //               renderInput={(params) => <TextField {...params} />}
    //               // open={selectedDate?.$M && selectedDate?.$y ? false : true}
    //               // open={selectedOption === '1month'} 
    //             />
    //           </DemoContainer>
    //         </LocalizationProvider>
    //       )}
    //       {selectedOption === '1year' && (
    //         <LocalizationProvider dateAdapter={AdapterDayjs}>
    //           <DemoContainer components={['DatePicker', 'DatePicker']}>
    //             <DatePicker
    //               views={['year']}
    //               label="Select Year"
    //               value={selectedYear}
    //               onChange={(newValue) => setSelectedYear(newValue)}
    //               renderInput={(params) => <TextField {...params} />}
    //               open={selectedYear ? false : true}
    //             />
    //           </DemoContainer>
    //         </LocalizationProvider>
    //       )}

    //     </Box>
    //     <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
    //       <Box
    //         sx={{
    //           bgcolor: "#E1F4FF",
    //           width: "48%",
    //           my: "10px",
    //           display: "flex",
    //           py: 4,
    //           alignItems: "center",
    //           flexDirection: "column"
    //         }}
    //       >
    //         <Box sx={{ width: "100%" }}>
    //           <NoOfQuestionChart chartData={questionData} />
    //         </Box>
    //       </Box>
    //       <Box
    //         sx={{
    //           bgcolor: "#E1F4FF",
    //           width: "48%",
    //           my: "10px",
    //           display: "flex",
    //           py: 4,
    //           alignItems: "center",
    //           flexDirection: "column"
    //         }}
    //       >
    //         <Box sx={{ width: "100%" }}>
    //           <NoOfSessionChart filteredData={sessionData} />
    //         </Box>
    //       </Box>
    //     </Box>

    //   </Container>
    // </React.Fragment>
  )
}

export default ActivityOverview