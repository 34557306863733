// import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import { Typography,FormControl, TextField} from "@mui/material";
// import Breadcrumbs_component from "../../components/common/Breadcrumbs";
// import SendIcon from '@mui/icons-material/Send';
// import ChatGPTDetails from "../../components/Research/ChatGPTDetails";
// import ReactTyped from "react-typed";
// import swal from "sweetalert";

// const ChatGPT = () => {
//   const userId = localStorage.getItem("UserId")
//   const token = localStorage.getItem("token")

//   const [prompt, setPrompt] = React.useState(null)
//   const [promptData, setPromptData] = React.useState(null)
//   const [stop, setStop] = React.useState(false)
//   const [resetAnimation, setResetAnimation] = React.useState(false);


//   const fetchData = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/?UserId=${userId}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const dataa = await response.json();
//         console.log(dataa, "");
//       } else {
//         const dataa = await response.json();
//         console.log('Error:', response.status, dataa);
//       }
//     } catch (error) {
//       console.log('Error:', error.message);
//     }
//   };

//   React.useEffect(() => {
//     fetchData();
//   }, []);

//   // const handlePostPrompt = async () => {
//   //   const data = {
//   //     UserId: userId,
//   //     prompt: prompt,
//   //   }
//   //   try {
//   //     const response = await fetch(`${process.env.REACT_APP_API_ROOT/api/research/chatgpt/`, {
//   //       method: 'POST',
//   //       headers: {
//   //         'Content-Type': 'application/json',
//   //         Authorization: `Bearer ${token}`,
//   //       },
//   //       body: JSON.stringify(data),
//   //     });

//   //     if (response.ok) {
//   //       const result = await response.json();
//   //       console.log("result :", result);
//   //       setPromptData(result)
//   //       fetchData()
//   //     } else {
//   //       const result = await response.json();
//   //       if(result?.message == "You dont have sufficient credit points"){
//   //         swal({
//   //           title: "Oh Snap!",
//   //           text: "you Don't have enough credits.",
//   //           icon: "error",
//   //           button: "Dismiss!",
//   //         });
//   //       }
//   //       // console.log('result =:', result, response.status);
//   //     }
//   //   } catch (error) {
//   //     console.log('Error:', error);
//   //   }
//   // };
//   const handlePostPrompt = async () => {
//     const data = {
//       UserId: userId,
//       prompt: prompt,
//     }
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         const result = await response.json();
//         console.log("result :", result);
//         setPromptData(result);
//         setStop(false); // Reset the stop state to allow the animation to continue
//         fetchData();
//       } else {
//         const result = await response.json();
//         if (result?.message === "You dont have sufficient credit points") {
//           swal({
//             title: "Oh Snap!",
//             text: "You don't have enough credits.",
//             icon: "error",
//             button: "Dismiss!",
//           });
//         }
//       }
//     } catch (error) {
//       console.log('Error:', error);
//     }
//   };


//   return (
//     <React.Fragment>
//       <CssBaseline />
//       <Container>
//         <Breadcrumbs_component Left="Research & Development" Right="ChatGPT"/>
//         <Box
//           sx={{
//             bgcolor: "#E1F4FF",
//             maxHeight: "80vh",
//             width: "100%",
//             mt: 2,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//           }}
//         >
//           <Typography fontSize={20} sx={{ m: 2 }} fontWeight="bold">
//             Write whatever you want to ask!
//           </Typography>
//             <FormControl  sx={{ 
//               m: 1, 
//               width: "60%", 
//               height:"5px",
//               display:"flex",  
//               justifyContent: "center",
//               alignItems: "center",
//               flexDirection: "row"}}>
//             <TextField onChange={(e)=>setPrompt(e.target.value)} value={prompt} id="outlined-basic" label={prompt == null && "Write something here"}  variant="outlined" sx={{backgroundColor: "white", width:"100%"}} />
//             <Container onClick={handlePostPrompt} sx={{backgroundColor:"#295598", color:"white", height:"55px", width:"5vw", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center",cursor:"pointer"}}>
//                 <SendIcon sx={{fontSize:30}}/>
//             </Container>
//             </FormControl>
//             {promptData == null ?
//             <Box sx={{ 
//               my: 5, 
//               p:4,
//               width: "100%", 
//               height:"100%",
//               display:"flex",  
//               justifyContent: "center",
//               alignItems: "center",
//               flexDirection: "row",
//               }}>
//             <ChatGPTDetails setPrompt={setPrompt}/>
//             </Box>
//             :
//             <Box sx={{ 
//               my: 5, 
//               p:4,
//               width: "60%", 
//               height:"100%",
//               display:"flex",  
//               justifyContent: "center",
//               alignItems: "center",
//               flexDirection: "row",
//               backgroundColor:"white"
//               }}>
//             <ReactTyped
//               strings={[promptData?.message]}
//               typeSpeed={10}
//               // backSpeed={20}
//               cursorChar="|"
//               showCursor={true}
//               stopped={stop}
//               // key={stop}
//               // onTypingPaused={() => setNewLine(true)}
//               // onTypingResumed={() => setNewLine(false)}
//               // key={resetAnimation ? "reset" : "animation"}
//               style={{textAlign:"start",fontSize:"18px",whiteSpace: "pre-wrap"}}
//             />
//             </Box>
//           }
//         </Box>
//         <button onClick={()=>{
//           setStop(true);
//           // setResetAnimation(true);
//           // setTimeout(() => setStop(false), 100);
//           }} size="small" style={{ backgroundColor: "#295598", color: "white", width: "200px", padding: "15px 0px", fontWeight: "bold", justify: "center", margin:"20px 3px",border:"none",borderRadius:"12px",cursor:"pointer" }}>stop</button>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default ChatGPT;

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography, FormControl, TextField } from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import SendIcon from "@mui/icons-material/Send";
import ChatGPTDetails from "../../components/Research/ChatGPTDetails";
import ReactTyped from "react-typed";
import swal from "sweetalert";
import Loader from "../../components/common/Loader";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ContentCopy, DoneAll } from "@mui/icons-material";

const ChatGPT = ({totalCredits,setChangeCredits}) => {
  const userId = localStorage.getItem("UserId");
  const token = localStorage.getItem("token");

  const [prompt, setPrompt] = React.useState(null);
  const [promptData, setPromptData] = React.useState(null);
  const [stop, setStop] = React.useState(false);
  const [animationKey, setAnimationKey] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const [copied, setCopied] = React.useState(false);
  const [showcopied, setshowCopied] = React.useState(false);
  const [reGenerate, setReGenerate] = React.useState(false);
  


  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/?UserId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const dataa = await response.json();
        console.log(dataa, "");
      } else {
        const dataa = await response.json();
        console.log("Error:", response.status, dataa);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePostPrompt = async () => {
    setLoading(true);
    setPromptData(null);
    setReGenerate(false);
    setChangeCredits(false)
    const data = {
      UserId: userId,
      prompt: prompt,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/research/chatgpt/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result :", result);
        setPromptData(result);
        setStop(false);
        setAnimationKey((prevKey) => prevKey + 1);
        fetchData();
        totalCredits()
        setChangeCredits(true)
      } else {
        const result = await response.json();
        if (result?.message === "You dont have sufficient credit points") {
          swal({
            title: "Oh Snap!",
            text: "You don't have enough credits.",
            icon: "error",
            button: "Dismiss!",
          });
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Breadcrumbs_component Left="Research & Development" Right="ChatGPT" />
        <Box
          sx={{
            bgcolor: "#E1F4FF",
            minHeight: "80vh",
            width: "100%",
            height: "100%",
            my: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography fontSize={20} sx={{ m: 2 }} fontWeight="bold">
            Write whatever you want to ask!
          </Typography>
          <FormControl
            sx={{
              m: 1,
              width: "60%",
              height: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TextField
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
              id="outlined-basic"
              label={prompt == null && "Write something here"}
              variant="outlined"
              sx={{ backgroundColor: "white", width: "100%" }}
            />
            {loading == false ?
              <Container
                onClick={handlePostPrompt}
                sx={{
                  backgroundColor: "#295598",
                  color: "white",
                  height: "55px",
                  width: "5vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <SendIcon sx={{ fontSize: 30 }} />
              </Container> :
              <Container
                // onClick={handlePostPrompt}
                sx={{
                  backgroundColor: "#295598",
                  color: "white",
                  height: "55px",
                  width: "5vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Loader />
              </Container>
            }
          </FormControl>
          {promptData == null ? (
            <Box
              sx={{
                my: 5,
                p: 4,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <ChatGPTDetails setPrompt={setPrompt} />
            </Box>
          ) : (
            <Box
              sx={{
                my: 5,
                p: 4,
                width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                backgroundColor: "white",
                position: "relative",
              }}
            >
              <ReactTyped
                strings={[promptData?.message]}
                typeSpeed={10}
                cursorChar="|"
                showCursor={true}
                stopped={stop}
                key={animationKey}
                onComplete={() => {
                  setLoading(false);
                  setshowCopied(true);
                  setReGenerate(true)
                }}
                style={{
                  textAlign: "start",
                  fontSize: "18px",
                  whiteSpace: "pre-wrap",
                }}
              />
              {showcopied == true && loading == false &&
              <Box sx={{ position: "absolute", bottom: "10px", right: "10px",backgroundColor:"transparent",paddingTop:"20px" }}>
                {copied ? 
                  <button style={{backgroundColor:"transparent",border:"none",marginTop:"10px",paddingTop:"20px",cursor:"pointer"}}><DoneAll /></button>
                :
                <CopyToClipboard text={promptData?.message} onCopy={handleCopy}>
                  <button style={{backgroundColor:"transparent",border:"none",marginTop:"10px",paddingTop:"20px",cursor:"pointer"}}><ContentCopy /></button>
                </CopyToClipboard>
                }
              </Box>
              }
            </Box>
          )}
          {loading == true  &&
          <button
          onClick={() => {
            setStop(true);
            setPromptData(null)
            setLoading(false)
            }}
            size="small"
            style={{
              backgroundColor: "#295598",
              color: "white",
              width: "200px",
              padding: "15px 0px",
              fontWeight: "bold",
              justify: "center",
              margin: "20px 3px",
              border: "none",
              borderRadius: "12px",
              cursor: "pointer",
            }}
            >
            Stop
          </button>
          }
          {reGenerate == true && loading == false && 
            <button
            onClick={handlePostPrompt}
              size="small"
              style={{
                backgroundColor: "#295598",
                color: "white",
                width: "200px",
                padding: "15px 0px",
                fontWeight: "bold",
                justify: "center",
                margin: "20px 3px",
                border: "none",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              >
              Re Generate
            </button>
          }
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ChatGPT;
