import React from 'react'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography} from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import EastIcon from '@mui/icons-material/East';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const ChatGPTDetails = ({setPrompt}) => {
    const handleTypographyClick = (e) => {
        const promptValue = e.target.innerText;
        setPrompt(promptValue); 
      };
  return (
    <div>
        <Box sx={{display:"flex", flexDirection:"column", flexWrap:"wrap", alignContent:"center", justifyContent:"center", height:"50vh",width:"100%"}}>
                <Container sx={{height:"50vh", width:"30%", backgroundColor:"transparent", display:"flex", flexDirection:"column", justifyContent: "flex-start"}}> 
                    <TipsAndUpdatesIcon sx={{ color: "#295598", ml:"6.5vw", mt:"12vh"}}/>
                    <Typography variant="h7" fontWeight={"bold"}>Examples</Typography>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Explain Quantum computing in simple terms <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Got any creative ideas for a 10 years old's birthday? <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick} >Explain quantum computing in simple terms <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                </Container>
                <Container sx={{height:"50vh", width:"30%", backgroundColor:"transparent", display:"flex", flexDirection:"column", justifyContent: "flex-start",}}>
                    <SubtitlesIcon sx={{ color: "#295598", ml:"6.5vw", mt:"12vh"}}/>
                    <Typography variant="h7" fontWeight={"bold"}>Capabilities</Typography>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Remembers what user said earlier said in the conversation <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Allows user to provide follow up corrections <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Trained to decline inappropriate requests <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                </Container>
                <Container sx={{height:"50vh", width:"30%", backgroundColor:"transparent", display:"flex", flexDirection:"column", justifyContent: "flex-start"}}> 
                    <ReportProblemIcon sx={{ color: "#295598", ml:"6.5vw", mt:"12vh"}}/>
                    <Typography variant="h7" fontWeight={"bold"}>Limitations</Typography>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>May occasionally generate incorrect information <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>May occasionally produce harmful instructions or biased content <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                    <Box align="left" sx={{backgroundColor:"white", mt:2}}>
                        <Typography sx={{fontSize:14, padding:"0.5vw"}} onClick={handleTypographyClick}>Limited knowledge of the world and events after 2021 <EastIcon sx={{fontSize:14}}/></Typography>
                    </Box>
                </Container>
            </Box>
    </div>
  )
}

export default ChatGPTDetails