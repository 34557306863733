import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
    import { Line } from 'react-chartjs-2';


    
    const LineChartComp = ({chartData}) => {
      // const faker = [
      //     0,100,200,1300,400,500,1000,700,800,900,1000,200,600
      // ]
      ChartJS.register(
          CategoryScale,
          LinearScale,
          PointElement,
          LineElement,
          Title,
          Tooltip,
          Legend
        );
     const options = {
    scales: {
        x: {
          grid: {
            display: false,
          },
        },
  
          },
          responsive: true,
          plugins: {
          legend: {
            position: 'bottom',
            display: false,
            // display:"none"
          },
          title: {
            display: true,
            text: 'Chart.js Bar Chart',
            position:"bottom"
          },
          title: {
            display: true,
            text: 'Bar Chart 2',
            position:"left"
          },
        },
      };
      
      // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];
      
      const data = {
        labels: chartData.length > 0 ? chartData[0] : "",
        datasets: [
          {
              // label: 'Dataset 2',
            data: chartData.length > 0 ? chartData[1]?.map((faker) => faker) : [0],
            backgroundColor: '#295598',
          },
      ],
      };
      console.log(chartData,": chartData chartData ,");
        return(
          <>
         <Line options={options} data={data} />
        </>
          )
    }
    
    export default LineChartComp