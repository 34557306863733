import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {useState} from "react"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display:"flex",
  justifyContent:"center",
  flexDirection:"column",
  textAlign:"center"
};

export default function Modale({open, handleClose,MemberId,setOpen,orgnisationName}) {
    const [credits, setCredits]= useState()
    const [userData, setUserData] = useState({})
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('UserId');
      

      // const fetchData = async () => {
      //   try {
      //     const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/getorganizationcredits/?OrganizationId=${userId}`, {
      //       method: 'GET',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`,
      //       },
      //     });
          
      //     if (response.ok) {
      //       const dataa = await response.json();
      //       setUserData(dataa);
      //     } else {
      //       console.log('Error:', response.status);
      //     }
      //   } catch (error) {
      //     console.log('Error:', error.message);
      //   }
      // };
      
      // React.useEffect(() => {
      //   fetchData();
      // }, []);
      // console.log(userData,"userDatauserData");
     
      const postCredits = async () => {
        const intCredits = parseInt(credits);
        console.log(intCredits, "intCredits intCredits");
        const data = {
          OrganizationId: userId,
          MemberId: MemberId,
          credits: intCredits,
        };
      
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/super-admin/assigncredits/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data), // Convert data to a JSON string
          });
      
          if (response.ok) {
            const result = await response.json();
            setOpen(false)
            console.log(result, "result of post");
          } else {
            setOpen(false)
            console.log('Error:', response.status);
          }
        } catch (error) {
          console.log('Error:', error.message);
        }
      };
      // console.log(userId,"propData ");
      // console.log(orgnisationName,"orgnisationName ");
      
      
      return (
        <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" sx={{fontSize:"24px",fontWeight:"700",color:"black",fontFamily:"Gordita",my:"10px"}}>
            Assign Credits
          </Typography>
          <Box sx={{width:"100%",}}>
            <Box sx={{mx:"auto",width:"50%", display:"flex",justifyContent:"center",flexDirection:"column"}} >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",mt:"30px"}}>
            <InputLabel sx={{fontSize:"22px",fontWeight:"600",color:"black",fontFamily:"Gordita"}}>Enter Credits</InputLabel>
            <TextField id="outlined-basic" size='small' variant="outlined" onChange={(e)=>setCredits(e.target.value)}/>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",mt:"20px"}}>
            <InputLabel sx={{fontSize:"22px",fontWeight:"600",color:"black",fontFamily:"Gordita"}}>Organization Name</InputLabel>
            <TextField id="outlined-basic" size='small' variant="outlined" value={orgnisationName} disabled/>
            </Box>
            <Box my={"40px"}>
                <Button variant="contained" onClick={postCredits}>Assign</Button>
            </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}