
import React, { useEffect } from "react";
import VideoCard from "../../components/Library/Video/VideoCard";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { useState } from "react";
import VideoCardForPlaylist from "../../components/Library/PlaylistCard/VideoCardForPlaylist";
const Videos = () => {
  const [videoData, setVideoData] = useState([])
  const [transcript, setTranscript] = useState("")
  const [url, setUrl] = useState("https://coversationalsai.s3.us-east-2.amazonaws.com/videos/Video+1.mp4")
  const [videoName, setVideoName] = useState("Guide :How to create a New Avatare")

  const [activeCard, setActiveCard] = useState(0);

  const getData = async () => {
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/data/videos/`,
    {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
      .then(
        data => data.json())
}

useEffect(() => {
  getData()
    .then(items => {
      setTranscript(items[0].Transcript)
      setVideoData(items)
      
    })
  }, [])
  return (
    <>
    <TopBar backArrow={true} text="How to Guide" />
    <div className="pl-8 flex items-start gap-4">
    <div className="py-4 w-[55%]">
        <h1 className="text-lg leading-7 font-semibold">
          Lets get you started
        </h1>
        <div className="mt-6 bg-white h-max p-10 mb-10">
        <VideoCard videoName={videoName} url={url} transcript={transcript} />
        </div>
        </div>
        <div className="px-3 py-4  border-l-2 border-gray-300 h-full w-[45%]">
        <h1 className="text-xl font-semibold pl-7">How to Guides</h1>
        <h1 className="text-lg font-normal pl-7">Avatare - 6 Videos</h1>

        <div className="mt-6 w-full">

        {videoData.map((item, index) => {

                return <VideoCardForPlaylist index={index} activeCard={activeCard} setActiveCard={setActiveCard} videoName={item.VideoName} setVideoName={setVideoName} url={item.url} transcript={item.Transcript} setTranscript={setTranscript} setUrl={setUrl} thumbnail={item.thumbnail} />
              })}

        </div>
        </div>
      </div>
    </>
  );
};

export default Videos;