import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import OrderSummryTable from './OrderSummryTable'
import MultiRange from './MultiRange'
import { useNavigate } from "react-router-dom"

const Credits = ({ credits, setCredits, value, setValue }) => {
  const [userData, setUserData] = useState([]);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');
  const navigate = useNavigate();
  const cardlist = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
  ];

  const addToCart = async (pay) => {
    const data = {
      UserId: userId,
      cost: pay,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/Addtocart/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        fetchData(); // Update userData after making a payment
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/Addtocart/?UserId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Only fetch data once when the component mounts

  const handleCheckout = () => {
    if(userData.length > 0){
      navigate('/organization/payment');
    }
  };

  // console.log(userData, 'Array.isArray(cartItems)');
  
  return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "start", margin: "0px", fontFamily: "Gordita" }}>
            <Box>
                <Typography sx={{ mt: "50px", fontSize: "34px", fontWeight: "500", fontFamily: "Gordita" }}>
                    Credits
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", textAlign: "center", fontFamily: "Gordita" }}>
                    <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center" }} >
                        <Typography sx={{ fontSize: "24px", fontWeight: "400", fontFamily: "Gordita" }}>
                            Buy
                        </Typography>
                        <Typography sx={{ fontSize: "24px", fontWeight: "500", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Gordita" }}>
                            {credits.toLocaleString()}<Typography sx={{ fontSize: "20px", fontWeight: "500", pl: "5px", fontFamily: "Gordita" }}>(Credits)</Typography>
                        </Typography>
                        <Typography sx={{ fontFamily: "Gordita" }}>
                            for
                        </Typography>
                        <Typography sx={{ fontSize: "24px", fontWeight: "500", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Gordita" }}>
                            ${value}<Typography sx={{ fontSize: "20px", fontWeight: "500", pl: "5px", fontFamily: "Gordita" }}>(price)</Typography>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ mt: "50px" }}>
                <MultiRange credits={credits} setCredits={setCredits} value={value} setValue={setValue} />
            </Box>
            <Box sx={{my: "20px",display:"flex",justifyContent:"center",}}>
                <Button onClick={() => addToCart(value)} sx={{  textAlign: "center", border: "2px solid #295598" }}>
                  Add to Cart
                  </Button>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', alignItems: "center", textAlign: "center", fontFamily: "Gordita" }}>
                   {cardlist.map((pay,i)=>{
                    const cridets = pay.value * 1000;
                    return (
                        <>
                       <Box sx={{ p: "0px 5px" }} key={i}>
                        <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 30px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center" }} >
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", fontFamily: "Gordita" }}>
                                ${pay.value}
                            </Typography>
                            <Typography sx={{ fontSize: "20px", fontWeight: "500", fontFamily: "Gordita" }}>
                                you will get
                            </Typography>
                            <Typography nowrap={true} sx={{ fontSize: "20px", fontWeight: "400", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Gordita" }}>
                                {cridets.toLocaleString()} Credits
                            </Typography>
                            <Button onClick={()=>addToCart(pay.value)} variant="contained" fullWidth sx={{ my: "5px" }}>Add to cart</Button>
                        </Box>
                    </Box>
                        </>
                    )
                }
                )}

                </Box>

            </Box>
            <Box sx={{ mt: "50px" }}>
                <Typography sx={{ fontSize: "30px", fontWeight: "500", fontFamily: "Gordita", mb: "20px" }} >Order Summery</Typography>
                <Box>
                    <OrderSummryTable
                     cartItems={userData} 
                     />
                </Box>
            </Box>
            <Box sx={{width:"100%",mt:"20px"}}>
                <Box sx={{width:"20%",ml:"auto",mr:"0px"}}>
                {/* <Button onClick={addToCart} fullWidth sx={{ my: "5px",textAlign:"center",border:"2px solid #295598" }}>add to cart</Button> */}
                <Button onClick={handleCheckout} variant="contained" fullWidth sx={{ my: "5px" }}>checkout</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Credits