import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography, Breadcrumbs } from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import "react-h5-audio-player/lib/styles.css";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import swal from "sweetalert";
import ReactTyped from "react-typed";


const WhisperPlayground = ({totalCredits}) => {
  const location = useLocation();
  const userId = localStorage.getItem("UserId")
  const token = localStorage.getItem("token")
  const audioFile = location?.state;
  const [whisperData, setWhisperData] = useState(null)
  const [checkcredits,setCheckcredits] = useState()

  // console.log('audio File is :  ', audioFile);


  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/whisper/?UserId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        // setWhisperData(dataa);
        // console.log(dataa, "mmm");
        setCheckcredits(dataa?.message )
      } else {
        const dataa = await response.json();
        setCheckcredits(dataa?.message)
        if(dataa?.message == "You dont have sufficient credit points"){
          swal({
            title: "Oh Snap!",
            text: "you Don't have enough credits.",
            icon: "error",
            button: "Dismiss!",
          });
        }
        console.log('Error:', response.status, dataa);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [audioFile]);

  const uploadFile = async () => {
    fetchData()
    if(checkcredits !== "You dont have sufficient credit points" ){
      const formData = new FormData();
      formData.append("UserId", userId)
      formData.append("audio", audioFile?.file);
      formData.append("duration", audioFile?.duration);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/research/whisper/`, {
          method: "POST",
          body: formData,
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.ok) {
          const dataa = await response.json()
          setWhisperData(dataa)
          fetchData()
          totalCredits()
          console.log("success :", dataa);
        } else {
          console.log("Error :", response);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <Breadcrumbs_component Left="Research & Development" Right="Whisper AI" />
        <Typography fontSize={22} sx={{ m: 2, fontWeight: "bold", display: "flex", justifyContent: "left" }}>
          Whisper Playground
        </Typography>
        <Box sx={{ width: "100%", my: "20px", border: "1px solid #295598", py: "50px", borderRadius: "8px" }}>
          <AudioPlayer
            src={URL.createObjectURL(audioFile?.file)}
            className="custom-audio-player"
            style={{ border: "none", boxShadow: "none", margin: "0px auto", width: "60%" }}
            showJumpControls={false}
            customAdditionalControls={[]}
          />
        </Box>
        <Box sx={{ display: "flex", width: "100%", justifyContent:"center"}}>
          {/* <Box width={"40%"} sx={{ display: "flex", justifyContent: "start", alignItems: "flex-start", flexDirection: "column" }}>
            <Typography sx={{ textAlign: "start" }}>Back</Typography>
            <KeyboardBackspaceIcon />
          </Box> */}
          <Box >
            <button onClick={uploadFile} style={{ width: "170px", backgroundColor: "#295598", padding: "10px 14px", display: "flex", alignItems: "center", justifyContent: "center", color: "white", border: "none", borderRadius: "5px",cursor:"pointer" }}>
              Reveal Transcript
            </button>
          </Box>
        </Box>
          {whisperData == null ? <></> :
        <Box sx={{ display: "flex", width: "100%", p: "20px", border: "1px solid #295598", borderRadius: "8px", my: "10px" }}>
            <ReactTyped
              strings={[whisperData?.message]}
              typeSpeed={50}
              // backSpeed={20}
              cursorChar="|"
              showCursor={true}
            />
        </Box>
          }

      </Container>
    </React.Fragment>
  );
};

export default WhisperPlayground;
