import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {
  Typography,
  Button,
  Pagination
} from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import ScoreStars from "../../assets/img/classroom/ScoreStars.svg";
import { useParams } from "react-router";
import SessionQuestions from "../../components/Classroom/SessionQuestions/SessionQuestions";
import PublishIcon from '@mui/icons-material/Publish';

const ScoreAndRating = () => {
  // const [dataObjects, setDataObjects] = React.useState([]);
  let { Id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [QuestionsPerSession, setQuestionsPerSession] = React.useState([]);
  const [rerender, setRerender] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [file, setFile] = React.useState(null);
  // Create a reference to the hidden file input element
  // const hiddenFileInput = React.useRef(null);
  const [selectedSessions, setSelectedSessions] = React.useState([]);

  const handleCheckboxChange = (sessionId) => (event) => {
    if (event.target.checked) {
      setSelectedSessions((prevSelectedSessions) => [
        ...prevSelectedSessions,
        sessionId,
      ]);
    } else {
      setSelectedSessions((prevSelectedSessions) =>
        prevSelectedSessions.filter((id) => id !== sessionId)
      );
    }
  };

  // console.log("selectedSessions",selectedSessions);

  const getData = async () => {
    // let userId = localStorage.getItem("UserId");
    return await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/getchats/?BotId=${Id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => {
      return data.json();
    });
  };

  React.useEffect(() => {
    getData().then((items) => {
      // console.log("Items: ", items);
      // setDataObjects(items.message);
      const grouped = {};
      for (let i = 0; i < items.message.length; i++) {
        const item = items.message[i];
        if (grouped[item.SessionId]) {
          grouped[item.SessionId].push({Answer :item.Answer, BotId: item.BotId, ChatDateTime: item.ChatDateTime, ChatId: item.ChatId, Question: item.Question, Rating: item.Rating});
        } else {
          grouped[item.SessionId] = [{Answer :item.Answer, BotId: item.BotId, ChatDateTime: item.ChatDateTime, ChatId: item.ChatId, Question: item.Question, Rating: item.Rating}];
        }
      }

      console.log(grouped);

      const keyValuePairs = Object.keys(grouped).map((key) => ({
        sessionId: key,
        data: grouped[key],
      }));
      console.log(keyValuePairs);

      setQuestionsPerSession(keyValuePairs.reverse());
    });
  }, [open, rerender]);

  const goToPage = (e, page) => {
    setPageNumber(page - 1);
    console.log(page);
  };

  const exportQuestions = async () => {
    const selectedSessionIdsString = selectedSessions.join(",");
    
    return await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/exportchat?BotId=${Id}&SessionIds=${selectedSessionIdsString}`, {
      headers: { "content-type": 'text/csv' ,
      Authorization: "Bearer " + localStorage.getItem("token") 
    },
    }).then((data) => {
      return data.text();
    }).then((response) => {
      console.log(response)
      downloadBlob(response, 'export.csv', 'text/csv;charset=utf-8;')
      return response
    });
  }

  const downloadBlob = (content, filename, contentType) => {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  // const handleImportFileChange = async (event) => {
  //   // const MIN_FILE_SIZE = 1; // 1KB
  //   // const MAX_FILE_SIZE = 300; // 300KB

  //   // let success = false;
  //   var formData = new FormData()
  //   formData.append("file", event.target.files[0])
  //   setFile(event.target.files[0]);
  //   return await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/importexportchat/`, {
  //     method: "POST",
  //     headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token") 
  //   },
  //   body: formData
  //   })
  //   .then((data) => {
  //     return data.json();
  //   })
  //   .then((response) => {
  //     console.log("Response is : ", response)
  //     return response
  //   });

    
  // };


  
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        // fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          // margin: "3vw",
          // width: "2000px",
          width:"100%"
        }}
      >
        <BreadcrumbsComponent Left="Classroom" Right="Test My Avatare" />
        <Container
          // fixed
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            mt: "10px",
            // width: "2000px!important",
            // gap: "1vw",
          }}
        >
          <img alt=" " src={ScoreStars} />
          <Typography
            variant="h3"
            sx={{ fontSize: 24, fontWeight: "bold", fontFamily: "Gordita" }}
          >
            Scoring and Rating
          </Typography>
          <Button variant="contained"
              size="large"
              endIcon={<PublishIcon />}
              sx={{
                mt: 0,
                ml:"auto",
                width:"fit-content",
                padding:1,
                backgroundColor: "#295598",
                fontSize:14,
                fontWeight: "bold",
                color: "#ffffff",
                position:"relative",
                float: "left",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#295598",
                },
              }}
              onClick={exportQuestions}
              >
            Export Questions
          </Button>
          
        </Container>
        {Array.isArray(QuestionsPerSession)
        ? QuestionsPerSession.slice(pageNumber * 10, pageNumber * 10 + 10).map(
            (pair) => (
              <SessionQuestions
                open={open}
                setOpen={setOpen}
                dataObjects={pair.data}
                Id={Id}
                SessionId={pair.sessionId}
                rerender={rerender}
                setRerender={setRerender}
                handleCheckboxChange={handleCheckboxChange(pair.sessionId)}
                isChecked={selectedSessions.includes(pair.sessionId)}
              />
            )
          )
        : null}
      </Container>
      <Container
        sx={{
          m: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
      <Pagination
          count={parseInt(QuestionsPerSession.length / 10) + 1}
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
          size="large"
          onChange={goToPage}
        />
        </Container>
    </React.Fragment>
  );
};

export default ScoreAndRating;
