import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const BarChartComp = ({ chartData }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const options = {
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Bar Chart',
        position: 'bottom',
      },
    },
  };

  const data = {
    labels: chartData.length > 0 ? chartData[0]?.slice(0, 10) : "0", // Slice the labels to show only the first 10
    datasets: [
      {
        data: chartData.length > 0 ? chartData[1]?.slice(0, 10)?.map((value) => (value !== null ? value : 0)) : [0], // Show only the first 15 bars and set others to 0
        backgroundColor: '#295598',
        barPercentage: 0.1,
        categoryPercentage: 1,
      },
    ],
  };

  // console.log("data",chartData);

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

export default BarChartComp;
