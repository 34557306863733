import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Step, Typography } from "@mui/material";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy, DoneAll } from "@mui/icons-material";
import swal from "sweetalert";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { Steps } from "antd";

const Documentation = () => {
  const [copiedEndpoints, setCopiedEndpoints] = React.useState({});
  const [copiedData, setCopiedData] = React.useState({});
  const [showCopiedEndpoint, setShowCopiedEndpoint] = React.useState({});
  const [showCopiedData, setShowCopiedData] = React.useState({});

  const [sidebar, setSidebar] = useState([]);
  const [module, setModule] = useState("Authentication");

  const [current, setCurrent] = useState(0); // To control the current step

  // Handle click to update the step and trigger setModule
  const handleStepClick = (index, links) => {
    setCurrent(index); // Update the current step index
    setModule(links); // Trigger the setModule with the clicked link
  };
  const [data, setData] = useState([]);
  const [selectedStatusCode, setSelectedStatusCode] = useState({
    code: 200,
  });
  const token = localStorage.getItem("token");

  console.log("module ::", module);
  console.log("selectedStatusCode ::", selectedStatusCode);

  useEffect(() => {
    console.log("data", data);
    data?.map((data1) => {
      console.log("dataresponse", data);
      if (data1?.responses?.length > 0) {
        return setSelectedStatusCode({
          code: data1.responses[0].code,
          id: data1?.id,
        });
      }
    });
  }, [data]);

  const handleCopyEndpoint = (id) => {
    setCopiedEndpoints((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    setTimeout(() => {
      setCopiedEndpoints((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }, 2000);
  };

  const handleCopyData = (id) => {
    setCopiedData((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    setTimeout(() => {
      setCopiedData((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }, 2000);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/developer/docs/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const dataa = await response.json();
        setSidebar(dataa);
        handlePostPrompt();
        // console.log("data ::",dataa);
      } else {
        const dataa = await response.json();
        swal({
          title: "Oh Snap!",
          text: "Something went Wrong",
          icon: "error",
          button: "Dismiss!",
        });
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePostPrompt = async () => {
    const data = {
      module: module,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/developer/docs/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();

        console.log(
          "result :",
          result.message.map((item, i) => ({ id: i, ...item }))
        );
        setData(
          result?.message?.map((item, i) => ({
            id: i,
            code: item.responses[0].code,
            ...item,
          }))
        );
      } else {
        const result = await response.json();
        swal({
          title: "Oh Snap!",
          text: "Something went Wrong",
          icon: "error",
          button: "Dismiss!",
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    handlePostPrompt();
  }, [module]);

  const handleStatusChange = (code, id) => {
    const itemIndex = data.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const newData = [...data];
      newData[itemIndex] = { ...newData[itemIndex], code: code };
      setData(newData);
    }
  };

  return (
    <>
      <TopBar text="Documentation" />
      <div>
        <div className="flex gap-4 w-full text-left p-8">
          <div className="w-[80%] bg-white p-4">
            <div>
              <h1 className="text-lg font-semibold my-2.5">
                Conversational AI Documentation
              </h1>
              <h1
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  p: "5px",
                  my: "10px",
                }}
              >
                <b>Conversational AI </b> is a cutting-edge platform that
                empowers users to create personalized avatars, or chatbots,
                tailored to their specific needs. Explore our comprehensive{" "}
                <b>REST API </b> documentation to unlock the full potential of
                conversational AI and revolutionize your digital interactions.
              </h1>

              <h1
               className="text-lg font-medium mt-2"
                
              >
                {module}
              </h1>

              {data?.map((item) => (
                <Box key={item.id} my="20px">
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "600", p: "5px" }}
                  >
                    <span
                      style={
                        item?.Method == "GET"
                          ? { color: "rgb(0, 127, 49)", marginRight: "5px" }
                          : item?.Method == "POST"
                          ? { color: "rgb(173, 122, 3)", marginRight: "5px" }
                          : item?.Method == "DELETE"
                          ? { color: "rgb(142, 26, 16)", marginRight: "5px" }
                          : { color: "rgb(98, 52, 151)", marginRight: "5px" }
                      }
                    >
                      {item?.Method}
                    </span>{" "}
                    {item?.name}
                  </Typography>
                  <Box
                    className="rounded-md"
                    bgcolor={"rgb(249, 249, 249)"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: "8px",
                      alignItems: "center",
                    }}
                    width={"100%"}
                    onMouseEnter={() =>
                      setShowCopiedEndpoint((prevState) => ({
                        ...prevState,
                        [item.id]: true,
                      }))
                    }
                    onMouseLeave={() =>
                      setShowCopiedEndpoint((prevState) => ({
                        ...prevState,
                        [item.id]: false,
                      }))
                    }
                  >
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: "500", p: "5px" }}
                    >
                      {item?.url}
                    </Typography>
                    {showCopiedEndpoint[item.id] && (
                      <Box sx={{ backgroundColor: "transparent" }}>
                        {copiedEndpoints[item.id] ? (
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <DoneAll />
                          </button>
                        ) : (
                          <CopyToClipboard
                            text={item?.url}
                            onCopy={() => handleCopyEndpoint(item.id)}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              <ContentCopy />
                            </button>
                          </CopyToClipboard>
                        )}
                      </Box>
                    )}
                  </Box>
                  {item?.Authorization !== null && (
                    <Box my={"20px"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          p: "5px",
                          my: "10px",
                        }}
                      >
                        Authorization{" "}
                        <span style={{ color: "gray" }}>Bearer Token</span>
                      </Typography>
                    
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "60%",
                          fontSize: "16px",
                          fontWeight: "600",
                          p: "5px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Token
                        </Typography>
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          {"<Token>"}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      p: "5px",
                      my: "10px",
                    }}
                  >
                    <span style={{ color: "gray" }}>{item?.mode}</span>
                  </Typography>
                  <Box
                    className="rounded-md border border-gray-400"
                    sx={{
                      
                      my: "10px",
                      width: "100%",
                      p: "15px",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Box sx={{ backgroundColor: "transparent" }}>
                        {copiedData[item.id] ? (
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <DoneAll />
                          </button>
                        ) : (
                          <CopyToClipboard
                            text={JSON.stringify(item?.data)}
                            onCopy={() => handleCopyData(item.id)}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              <ContentCopy />
                            </button>
                          </CopyToClipboard>
                        )}
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item?.params !== null
                        ? JSON.stringify(item?.params, null, 2)
                        : JSON.stringify(item?.data, null, 2)}
                    </Typography>
                  </Box>
                  {item?.responses !== null && (
                    <Box sx={{ my: "20px" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          p: "5px",
                          my: "10px",
                        }}
                      >
                        Response
                      </Typography>
                      <Box my="5px" sx={{ display: "flex", mx: "5px" }}>
                        {item?.responses?.map((data1) => (
                          <button
                            key={data1?.code}
                            onClick={() =>
                              handleStatusChange(data1?.code, item?.id)
                            }
                            style={{
                              backgroundColor:
                                item.code === data1?.code ? "#7FB04F" : "white",
                              border: "1px solid #7FB04F",
                              cursor: "pointer",
                              color:
                                item.code === data1?.code ? "white" : "black",
                              borderRadius: "8px",
                              padding: "8px 15px",
                              margin: "0px 5px",
                            }}
                          >
                            {data1?.code}
                          </button>
                        ))}
                      </Box>
                    
                      {item?.responses
                        ?.filter(
                          (response) =>
                            response?.code === item?.code &&
                            item?.id === item?.id
                        )
                        ?.map((filteredResponse) => (
                          <Box
                           className="rounded-md border border-gray-400"
                            key={filteredResponse.code}
                            sx={{
                              border: "1px solid gray",
                              my: "10px",
                              width: "100%",
                              p: "15px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {JSON.stringify(filteredResponse.body, null, 2)}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
              ))}
            </div>
          </div>
          <div className="w-[20%] bg-white min-h-[100vh] text-center py-5">
            <h1 className="text-lg font-semibold text-center mx-auto">
              Conversational AI
            </h1>

            <div className="my-2.5 text-left w-full px-8 h-full">
              <Steps
                progressDot
               
                current={current}
                direction="vertical"
              >
                {sidebar?.message?.map((links, index) => (
                  <Step
                    
                    key={index}
                    title={links}
                    onClick={() => handleStepClick(index, links)}
                  />
                ))}
              </Steps>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documentation;

// import React, { useEffect, useState } from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
// import { Container } from "@mui/material";
// import { Typography } from "@mui/material";
// import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { ContentCopy, DoneAll } from "@mui/icons-material";
// import swal from "sweetalert";

// const Documentation = () => {
//     const [value, setValue] = React.useState('1');
//     const [copied, setCopied] = React.useState(false);
//     const [showcopied, setshowCopied] = React.useState(false);
//     const [sidebar, setSidebar] = useState([])
//     const [module, setModule] = useState("Authentication")
//     const [data, setData] = useState()
//     const [selectedStatusCode, setSelectedStatusCode] = useState({
//         code: 200
//     });
//     const token = localStorage.getItem("token");

//     console.log("module ::", module);
//     console.log("selectedStatusCode ::", selectedStatusCode);

//     useEffect(() => {
//         console.log("data", data)
//         data?.map((data1) => {
//             console.log("dataresponse", data)
//             if (data1?.responses?.length > 0) {
//                 return setSelectedStatusCode({ code: data1.responses[0].code, id: data1?.id })
//             }
//         })
//     }, [data]);

//     const handleCopy = () => {
//         setCopied(true);
//         setTimeout(() => setCopied(false), 2000);
//     };

//     const fetchData = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/developer/docs/`,
//                 {
//                     method: "GET",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );

//             if (response.ok) {
//                 const dataa = await response.json();
//                 setSidebar(dataa)
//                 handlePostPrompt()
//                 // console.log("data ::",dataa);
//             } else {
//                 const dataa = await response.json();
//                 swal({
//                     title: "Oh Snap!",
//                     text: "Somthing went Wrong",
//                     icon: "error",
//                     button: "Dismiss!",
//                 });
//                 console.log("Error:", response.status);
//             }
//         } catch (error) {
//             console.log("Error:", error.message);
//         }
//     };

//     React.useEffect(() => {
//         fetchData();
//     }, []);

//     const handlePostPrompt = async () => {
//         const data = {
//             //   UserId: userId
//             module: module
//         };
//         try {
//             const response = await fetch(
//                 `${process.env.REACT_APP_API_ROOT}/api/developer/docs/`,
//                 {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${token}`,
//                     },
//                     body: JSON.stringify(data),
//                 }
//             );

//             if (response.ok) {
//                 const result = await response.json();

//                 console.log("result :", result.message.map((item, i) => ({ id: i, ...item })));
//                 setData(result?.message?.map((item, i) => ({ id: i, code: item.responses[0].code, ...item })))
//             } else {
//                 const result = await response.json();
//                 swal({
//                     title: "Oh Snap!",
//                     text: "Somthing went Wrong",
//                     icon: "error",
//                     button: "Dismiss!",
//                 });
//             }
//         } catch (error) {
//             console.log("Error:", error);
//         }
//     };
//     useEffect(() => {
//         handlePostPrompt()
//     }, [module])

//     const handleStatusChange = (code, id) => {

//         const itemIndex = data.findIndex(item => item.id === id);
//         if (itemIndex !== -1) {
//           const newData = [...data];
//           newData[itemIndex] = { ...newData[itemIndex], code: code };

//           setData(newData);
//         }
//       };

//     return (
//         <React.Fragment>
//             <CssBaseline />
//             <Container>
//                 <BreadcrumbsComponent Left="Operations" Right="documentation" />
//                 <Container>
//                     <Box sx={{ display: "flex", width: "100%", textAlign: "left" }}>
//                         <Box sx={{ width: "80%", }}>
//                             <Box>
//                                 <Typography sx={{ fontSize: "22px", fontWeight: "600", p: "5px", my: "10px" }}>
//                                     Conversational AI
//                                 </Typography>
//                                 <Typography sx={{ fontSize: "14px", fontWeight: "500", p: "5px", my: "10px" }}>
//                                     <b>Conversational AI </b> is a cutting-edge platform that empowers users to create personalized avatars, or chatbots, tailored to their specific needs. Explore our comprehensive  <b>REST API </b> documentation to unlock the full potential of conversational AI and revolutionize your digital interactions.
//                                 </Typography>
//                                 <hr />
//                                 <Typography sx={{ fontSize: "22px", fontWeight: "600", py: "15px", px: "5px" }}>
//                                     {module}
//                                 </Typography>
//                                 <hr />
//                                 {data?.map((data) => (
//                                     <Box my="20px">
//                                         <Typography sx={{ fontSize: "16px", fontWeight: "600", p: "5px" }}>
//                                             <span style={data?.Method == "GET" ? { color: "rgb(0, 127, 49)", marginRight: "5px" } : data?.Method == "POST" ? { color: "rgb(173, 122, 3)", marginRight: "5px" } : data?.Method == "DELETE" ? { color: "rgb(142, 26, 16)", marginRight: "5px" } : { color: "rgb(98, 52, 151)", marginRight: "5px" }}>{data?.Method}</span> {data?.name}
//                                         </Typography>
//                                         <Box bgcolor={"rgb(249, 249, 249)"} sx={{ display: "flex", justifyContent: "space-between", p: "8px", alignItems: "center" }} width={"80%"} onMouseEnter={() => setshowCopied(true)} onMouseLeave={() => setshowCopied(false)}>
//                                             <Typography sx={{ fontSize: "14px", fontWeight: "500", p: "5px" }}>
//                                                 {data?.url}
//                                             </Typography>
//                                             {showcopied == true &&
//                                                 <Box sx={{ backgroundColor: "transparent", }}>
//                                                     {copied ?
//                                                         <button style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}><DoneAll /></button>
//                                                         :
//                                                         <CopyToClipboard text={data?.url} onCopy={handleCopy}>
//                                                             <button style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}><ContentCopy /></button>
//                                                         </CopyToClipboard>
//                                                     }
//                                                 </Box>
//                                             }
//                                         </Box>
//                                         {data?.Authorization !== null && (
//                                             <Box my={"20px"}>
//                                                 <Typography sx={{ fontSize: "16px", fontWeight: "600", p: "5px", my: "10px" }}>
//                                                     Authorization <span style={{ color: "gray" }} >Bearer Token</span>
//                                                 </Typography>
//                                                 <hr />
//                                                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "60%", fontSize: "16px", fontWeight: "600", p: "5px", }}>
//                                                     <Typography sx={{ fontSize: "16px", fontWeight: "600", }}>
//                                                         Token
//                                                     </Typography>
//                                                     <Typography sx={{ fontSize: "16px", fontWeight: "600", }}>
//                                                         {"<Token>"}
//                                                     </Typography>
//                                                 </Box>
//                                             </Box>
//                                         )}
//                                         <Typography sx={{ fontSize: "16px", fontWeight: "600", p: "5px", my: "10px" }}>
//                                             <span style={{ color: "gray" }}>{data?.mode}</span>
//                                         </Typography>
//                                         <hr />
//                                         <Box sx={{ border: "1px solid gray", my: "10px", width: "90%", p: "15px" }}>
//                                             <Box sx={{ display: "flex", justifyContent: "end" }}>
//                                                 <Box sx={{ backgroundColor: "transparent", }}>
//                                                     {copied ?
//                                                         <button style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}><DoneAll /></button>
//                                                         :
//                                                         <CopyToClipboard text={JSON.stringify(data?.data)} onCopy={handleCopy}>
//                                                             <button style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}><ContentCopy /></button>
//                                                         </CopyToClipboard>
//                                                     }
//                                                 </Box>
//                                             </Box>
//                                             <Typography sx={{ fontSize: "14px", fontWeight: "500", whiteSpace: "pre-line", }}>
//                                                 {data?.params !== null ? JSON.stringify(data?.params, null, 2) : JSON.stringify(data?.data, null, 2)}
//                                             </Typography>
//                                         </Box>
//                                         {data?.responses !== null && (
//                                             <Box sx={{ my: "20px" }}>
//                                                 <Typography sx={{ fontSize: "18px", fontWeight: "600", p: "5px", my: "10px" }}>
//                                                     Response
//                                                 </Typography>
//                                                 <Box my="5px" sx={{ display: "flex", mx: "5px" }}>
//                                                     {data?.responses?.map((data1) => (
//                                                         <button
//                                                             key={data1?.code}
//                                                             // onClick={() => setSelectedStatusCode({code : data1?.code , id : data?.id})}
//                                                             onClick={() => handleStatusChange(data1?.code, data?.id)}
//                                                             style={{
//                                                                 backgroundColor: data.code === data1?.code ? "#295598" : "white",
//                                                                 border: "1px solid #295598",
//                                                                 cursor: "pointer",
//                                                                 color: data.code === data1?.code ? "white" : "black",
//                                                                 borderRadius: "8px",
//                                                                 padding: "8px 15px",
//                                                                 margin: "0px 5px"
//                                                             }}
//                                                         >
//                                                             {data1?.code}
//                                                         </button>
//                                                     ))}
//                                                 </Box>
//                                                 <hr />
//                                                 {data?.responses?.filter((response) => response?.code === data?.code && data?.id === data?.id)?.map((filteredResponse) => (
//                                                     <Box sx={{ border: "1px solid gray", my: "10px", width: "90%", p: "15px" }}>
//                                                         <Typography sx={{ fontSize: "14px", fontWeight: "500", whiteSpace: "pre-line" }}>
//                                                             {JSON.stringify(filteredResponse.body, null, 2)}
//                                                         </Typography>
//                                                     </Box>
//                                                 ))}

//                                             </Box>
//                                         )}
//                                     </Box>
//                                 ))}
//                             </Box>
//                         </Box>
//                         <Box sx={{ width: "20%", bgcolor: "#f1f1f1", minHeight: "100vh" }}>
//                             <Typography sx={{ width: "90%", mx: "auto", bgcolor: "lightgray", textAlign: "center", fontSize: "16px", fontWeight: "600", p: "5px" }}>
//                                 Conversational AI
//                             </Typography>

//                             <Box sx={{ my: "10px", textAlign: "right" }}>
//                                 {sidebar && sidebar?.message?.map((links) => (
//                                     <Typography onClick={(e) => setModule(links)} sx={{ my: "10px", fontSize: "16px", fontWeight: "600", px: "10px", cursor: "pointer" }}>
//                                         {links}
//                                     </Typography>
//                                 ))}
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Container>

//             </Container>
//         </React.Fragment>
//     )
// }

// export default Documentation

{
  /* <Container sx={{textAlign:"left"}}>
    <Box>
        <Typography fontSize={22} sx={{ m: 2, fontWeight: "bold", display: "flex", justifyContent: "left" }}>
            Events
        </Typography>
        <Typography fontSize={22} sx={{ m: 2, fontSize:"20px",fontWeight:"600" }}>
            Use the REST API to interact with GitHub events.
        </Typography>
        <hr />
        <Box sx={{py:"20px"}}>
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                GitHub events power the various activity streams on the site.
            </Typography>
            <br/>
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                You can use the REST API to return different types of events triggered by activity on GitHub. For more information about the specific events that you can receive, see "GitHub Event types." Endpoints for repository issues are also available. For more information, see "Issue Events.
            </Typography>
            <br/>
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                Events are optimized for polling with the "ETag" header. If no new events have been triggered, you will see a "304 Not Modified" response, and your current rate limit will be untouched. There is also an "X-Poll-Interval" header that specifies how often (in seconds) you are allowed to poll. In times of high server load, the time may increase. Please obey the header.
            </Typography>
        </Box>
        <Box sx={{ bgcolor: "#E1F4FF", p:"20px",my:"20px"}}>
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"5px"}}>
                {` $ curl -I https://api.github.com/users/tater/events > HTTP/2 200 > X-Poll-Interval: 60 > ETag: "a18c3bded88eb5dbb5c849a489412bf3"`}
            </Typography>
            <br /><br />
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"5px"}}>
                {` # The quotes around the ETag value are important $ curl -I https://api.github.com/users/tater/events \ $ -H 'If-None-Match: "a18c3bded88eb5dbb5c849a489412bf3"' > HTTP/2 304 > X-Poll-Interval: 60`}
            </Typography>
        </Box>
        <Box sx={{py:"20px",my:"20px"}}>
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                Only events created within the past 90 days will be included in timelines. Events older than 90 days will not be included (even if the total number of events in the timeline is less than 300).
            </Typography>
        </Box>
        <Box sx={{py:"20px",my:"20px"}}>
            <Typography variant='h4'>
                List Public Events
            </Typography>
            <Typography variant='h6'>
                Works with Github Apps
            </Typography>
            <hr />
            <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                Only events created within the past 90 days will be included in timelines. Events older than 90 days will not be included (even if the total number of events in the timeline is less than 300).
            </Typography>
        </Box>
        <Typography variant='body' sx={{fontSize:"20px",fontWeight:"600"}}>
            Parameters
        </Typography>
        <Box sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ width: "60%" }}>
                <Box sx={{py:"15px"}} >
                    <Typography variant='body' sx={{fontSize:"20px",fontWeight:"600"}}>
                        Headers
                    </Typography>
                    <hr />
                    <Box sx={{ display: "flex",py:"10px" }}>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Headers
                        </Typography>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Strings
                        </Typography>
                    </Box>
                    <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                        Setting to application/vnd.github+json is recommended.
                    </Typography>
                </Box>
                <Box sx={{py:"15px"}} >
                    <Typography variant='body' sx={{fontSize:"20px",fontWeight:"600"}}>
                        Headers
                    </Typography>
                    <hr />
                    <Box sx={{ display: "flex",py:"10px" }}>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Headers
                        </Typography>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Strings
                        </Typography>
                    </Box>
                    <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                        Setting to application/vnd.github+json is recommended.
                    </Typography>
                </Box>
                <Box sx={{py:"15px"}}>
                    <Typography variant='body' sx={{fontSize:"20px",fontWeight:"600"}}>
                        Headers
                    </Typography>
                    <hr />
                    <Box sx={{ display: "flex",my:"20px" }}>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Headers
                        </Typography>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Strings
                        </Typography>
                    </Box>
                    <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                        Setting to application/vnd.github+json is recommended.
                    </Typography>
                </Box>
                <Box sx={{py:"15px"}}>
                    <Typography variant='body' sx={{fontSize:"20px",fontWeight:"600"}}>
                        Headers
                    </Typography>
                    <hr />
                    <Box sx={{ display: "flex",my:"20px" }}>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Headers
                        </Typography>
                        <Typography variant='body' sx={{mx:"15px",fontSize:"18px",fontWeight:"600"}}>
                            Strings
                        </Typography>
                    </Box>
                    <Typography variant='body' sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                        Setting to application/vnd.github+json is recommended.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ width: "40%" }}>
                <Box sx={{ width: '80%',mx:"auto", typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="cURL" value="1" />
                                <Tab label="JavaScript" value="2" />
                                <Tab label="GitHub CLI" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" >{`curl \ -H "Accept: application/vnd.github+json" \ -H "Authorization: Bearer <YOUR-TOKEN>"\ -H "X-GitHub-Api-Version: 2022-11-28" \ https://api.github.com/events`}</TabPanel>
                        <TabPanel value="2">Item Two</TabPanel>
                        <TabPanel value="3">Item Three</TabPanel>
                    </TabContext>
                </Box>
                <Box sx={{ width: '80%',mx:"auto", typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="cURL" value="1" />
                                <Tab label="JavaScript" value="2" />
                                <Tab label="GitHub CLI" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" >{`curl \ -H "Accept: application/vnd.github+json" \ -H "Authorization: Bearer <YOUR-TOKEN>"\ -H "X-GitHub-Api-Version: 2022-11-28" \ https://api.github.com/events`}</TabPanel>
                        <TabPanel value="2">Item Two</TabPanel>
                        <TabPanel value="3">Item Three</TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
        <Box sx={{py:"20px",my:"20px"}}>
            <Typography sx={{fontSize:"20px",fontWeight:"700",}}>
                List public events for a network of repositories
            </Typography>
            <Typography sx={{fontSize:"20px",fontWeight:"500",py:"5px"}}>
                Use the REST API to interact with GitHub events.
            </Typography>
            <hr />
            <Typography sx={{fontSize:"18px",fontWeight:"400",my:"20px",py:"15px"}}>
                GitHub events power the various activity streams on the site.
                You can use the REST API to return different types of events triggered by activity on GitHub. For more information about the specific events that you can receive, see "GitHub Event types." Endpoints for repository issues are also available. For more information, see "Issue Events."
                Events are optimized for polling with the "ETag" header. If no new events have been triggered, you will see a "304 Not Modified" response, and your current rate limit will be untouched. There is also an "X-Poll-Interval" header that specifies how often (in seconds) you are allowed to poll. In times of high server load, the time may increase. Please obey the header.
            </Typography>
        </Box>
        <Box sx={{ bgcolor: "#E1F4FF",p:"20px",my:"20px" }}>
            <Typography sx={{fontSize:"18px",fontWeight:"400",py:"5px"}}>
                $ curl -I https://api.github.com/users/tater/events
            </Typography>
            <Typography sx={{fontSize:"18px",fontWeight:"400",py:"5px"}}>
                {"> HTTP/2 200 > X-Poll-Interval: 60"}
            </Typography>
            <Typography sx={{fontSize:"18px",fontWeight:"400",py:"5px"}}>
                {"> ETag: `a18c3bded88eb5dbb5c849a489412bf3`"}
            </Typography>
        </Box>
    </Box>
</Container> */
}
