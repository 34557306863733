import React, { useEffect, useState } from "react";
import {
  Checkbox,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleSVG from "../../../assets/img/auth/google.svg";
import MicrosoftSVG from "../../../assets/img/auth/microsoft.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { message } from "antd";

const OrgLoginComponent = ({ setIsOrgnizer }) => {

  const [togglePassword, setTogglePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleLogin = (e) => {
    e.preventDefault();

    if(email.trim() ==="" || pass.trim()===""){
      message.error('Input is empty')
      return
    }
    if(emailRegex.test(email)){
    let body = {
      email: email,
      password: pass,
    };
    console.log(body);
    fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify(body),
      cache: "default",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        if (data.success == true) {
          message.success("Logged in successfully!")
          localStorage.setItem("email", data.data[0].email);
          localStorage.setItem("UserId", data.data[0].Id);
          localStorage.setItem("token", data.data[0].token);
          localStorage.setItem("isOrganizer", data.data[0].isOrganizer);
          localStorage.setItem("OrganizationId", data.data[0].OrganizationId);
          // window.location.href = "/home";
          navigate("/");
        } else {
        message.error("Invalid credentials! Please try again.")
        }

        return data;
      });
  }
  else{
    message.error("Email is not valid.")
  }
}

  const { state } = useLocation();
  if (state) {
    const { emailAddress, password } = state;
  } else {
    var emailAddress = "";
    var password = "";
  }

  useEffect(() => {
    setPass(password);
    setEmail(emailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePass = (e) => {
    setPass(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const idToken = credentialResponse.credential;
    // console.log("id token :",idToken);
    const requestBody = {
      id_token: idToken,
      isOrganizer: true,
    };
    // Send the POST request to your backend endpoint
    fetch(`https://conversational.avatare.com/api/auth/google/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the backend if needed
        console.log("google login data :", data);
        localStorage.setItem("UserId", data?.data?.Id);
        localStorage.setItem("token", data?.data?.token);
        localStorage.setItem("email", data?.data?.email);
        localStorage.setItem("name", data?.data?.name);
        localStorage.setItem("isOrganizer", data?.data?.isOrganizer);
        window.location.href = "/";
      })
      .catch((error) => {
        // Handle errors that occurred during the fetch
        console.error("Error:", error);
      });
  };

  const handleGoogleLoginError = () => {
    console.log("Login Failed");
  };

  return (
    // <Box
    //   sx={{
    //     height: "100%",
    //     width: "100%",
    //     textAlign:"center"
    //   }}
    // >
    //   <Typography data-testid="title" sx={{textAlign:"center"}}>Log in for Chatbot AI Framework</Typography>
    //   <Typography variant="h3" fontWeight={"bold"} sx={{textAlign:"center"}}>
    //     Welcome Back
    //   </Typography>
    //   <Container
    //     sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    //   >
    //     <Typography fontWeight={"bold"} mr={1}>
    //       Get back to Generating{" "}
    //     </Typography>
    //     <Typography sx={{ color: "#295598", fontWeight: "bold" }}>
    //       AI Chatbots For Your Organization
    //     </Typography>{" "}
    //     <Typography fontWeight={"bold"} ml={1}>
    //       Now!
    //     </Typography>
    //   </Container>
    //   <Container sx={{ display: "grid", width: "70%", gap: "2vw", mt: "5vh" }}>
    //     <form onSubmit={handleLogin} data-testid="form" style={{ display: "flex", flexDirection: "column", gap: "5vh" }}>
    //       <FormControl color={"primary"}>
    //         <Typography align="left" fontWeight={"bold"}>
    //           Email address
    //         </Typography>
    //         <TextField
    //           data-testid="email"
    //           placeholder="Write your email address here ..."
    //           aria-describedby="my-helper-text"
    //           type="email"
    //           value={email}
    //           onChange={handleChangeEmail}
    //           sx={{
    //             border: "none",
    //             borderRadius: "3vw",
    //             "& .MuiInputBase-input": { backgroundColor: "#E1F4FF" },
    //           }}
    //           required
    //           autoComplete
    //         />
    //       </FormControl>
    //       <FormControl>
    //         <Typography align="left" fontWeight={"bold"}>
    //           Password
    //         </Typography>
    //         <TextField
    //           data-testid="password"
    //           type={togglePassword ? "text" : "password"}
    //           placeholder="Write your password here ..."
    //           aria-describedby="my-helper-text"
    //           value={pass}
    //           onChange={handleChangePass}
    //           sx={{
    //             borderRadius: "3vw",
    //             "& .MuiInputBase-input": { backgroundColor: "#E1F4FF" },
    //           }}
    //           inputProps={{
    //             maxLength: 30,
    //             minlength: 4
    //           }}
    //           InputProps={{
    //             endAdornment: (
    //               <InputAdornment position="end" sx={{ cursor: "pointer" }}>
    //                 {" "}
    //                 {togglePassword ? (
    //                   <Visibility
    //                     className="cursor_pointer"
    //                     onClick={() => {
    //                       setTogglePassword(false);
    //                     }}
    //                   />
    //                 ) : (
    //                   <VisibilityOff
    //                     onClick={() => {
    //                       setTogglePassword(true);
    //                     }}
    //                   />
    //                 )}
    //               </InputAdornment>
    //             ),
    //           }}
    //           autoComplete
    //           required
    //         />
    //       </FormControl>
    //       <Container
    //         sx={{
    //           display: "flex",
    //           justifyContent: "flex-start",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Checkbox size="large" />
    //         <Typography>Remember Me</Typography>
    //       </Container>
    //       <Container
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           flexDirection: "column",
    //         }}
    //       >
    //         <Button
    //           variant="contained"
    //           size="large"
    //           type="submit"
    //           data-testid="LoginButton"
    //           sx={{
    //             alignSelf: "center",
    //             width: "12vw",
    //             backgroundColor: "#295598",
    //             border: "solid",
    //             borderColor: "black",
    //             fontWeight: "bold",
    //             color: "#ffffff",
    //           }}
    //         >
    //           Login
    //         </Button>
    //         <Typography color={"grey"} pt={"15px"}>
    //           Dont have an account? <a href="/signup">Sign Up Here</a>
    //         </Typography>
    //       </Container>
    //     </form>
    //     <Divider>O</Divider>
    //     <Typography variant="h6">Sign up with</Typography>
    //     <Container sx={{ display: "flex", justifyContent: "space-evenly", pb: "20px" }}>
    //       <GoogleOAuthProvider clientId="364039229228-1iv7i68nnhbtg4vn059kjuc1qnevn60d.apps.googleusercontent.com">
    //         <GoogleLogin
    //           onSuccess={handleGoogleLoginSuccess}
    //           onError={handleGoogleLoginError}
    //           width='12vw'
    //           size='large'
    //           text='signin'
    //           shape='square'
    //           theme='filled_black'

    //         />
    //       </GoogleOAuthProvider>
    //     </Container>
    //   </Container>
    // </Box>
    <div className="flex flex-col justifycenter items-center gap-8 py-4">
      <img src="/auth-logo.svg" className="lg:w-[10%] h-10" />
      <div className="relative lg:w-[65%] h-[550px]  max-w-[812px] flex flex-col items-center gap-2 py-6 px-6 bg-white rounded-lg">
        <img src="/auth-container-bg.svg" className="absolute top-14 -z-[10px]" />
        <h1 className="text-xl font-bold">Sign In</h1>
        <p className="text-black text-opacity-80">
          Welcome back to Avatare. Let’s access your account
        </p>
        <div className="mt-5 flex justify-center items-center">
          <GoogleOAuthProvider clientId="364039229228-1iv7i68nnhbtg4vn059kjuc1qnevn60d.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginError}
              width="12vw"
              size="large"
              text="Continue with Google"
              shape="square"
            />
          </GoogleOAuthProvider>
        </div>
        <div className="lg:px-28 mt-5 w-full">
          <div className="flex w-full justify-center items-center gap-2">
            <div className="w-full h-[0.5px] min-w-[25%] bg-black bg-opacity-20"></div>
            <p className="text-black text-opacity-40">OR</p>
            <div className="w-full h-[0.5px] min-w-[25%] bg-black bg-opacity-20"></div>
          </div>
          <div className="relative w-full bg-[#F7F8FC]  rounded-[4px] mt-5">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <img src="/email-icon.svg" />
            </div>
            <input
              type="text"
              style={{
                border: "0.5px solid rgba(0, 0, 0, 0.50)",
                borderRadius: "4px",
              }}
              value={email}
              required
              onChange={handleChangeEmail}
              placeholder="Email Address"
              className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
            />
          </div>
          <div className="relative w-full bg-[#F7F8FC]  rounded-[4px] mt-6">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <img src="/password-icon.svg" />
            </div>
            <input
              type="password"
              style={{
                border: "0.5px solid rgba(0, 0, 0, 0.50)",
                borderRadius: "4px",
              }}
              placeholder="Password"
              value={pass}
              onChange={handleChangePass}
              className="w-full p-3 pl-12 border bg-[#F7F8FC]  custom-outline"
            />
          </div>
          <div className="w-full mt-2">
            <div className="flex items-center gap-2">
            <Checkbox size="medium" sx={{padding:"0px !important"}} />
            <h2 className="font-bold text-black text-opacity-70">Remember Me</h2>
            </div>
          </div>
          <div className=" lg:w-[63%] mt-8  absolute cursor-pointer transition ease-out flex flex-col justify-start items-center gap-4">
           <button className="primary px-8 py-2 cursor-pointer" onClick={handleLogin}>Login</button>
            <p  className="w-full text-wrap text-center">Don’t have an account? <span onClick={(e)=> navigate("/signup")} className="font-semibold text-[#20556E] underline underline-offset-4"> Create Account</span> here</p>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default OrgLoginComponent;
