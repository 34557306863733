import { Typography, Container } from '@mui/material'
import React from 'react';
import TeamInvite from '../../components/Organization/Team/TeamInvite/TeamInvite';
import MembersTable from '../../components/Organization/Team/MembersTable/MembersTable';

const Teams = () => {
  return (
    <Container>
        <TeamInvite/>
        <MembersTable/>
    </Container>
  )
}

export default Teams