import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TopBar } from "../../CommonComponent/Topbar";
import ActivityOverview from "./ActivityOverview";
import TrainingData from "./TrainingData";


const AvatarMonitoringAndControl = () => {

  const location = useLocation();
  const id = location.state;
  const [active,setActive]= useState(1)

  const activeTab = (value)=>{
    setActive(value);
  }
  return (
    <div>
    <TopBar text="Bot Analytics" />
    <div className="py-2 px-8 bg-white m-10 rounded-sm">
      
      
      <div>
      <div className="flex w-full justify-between items-center mt-3 pr-2">
              <h2 className="text-lg font-medium text-black">
              Activity Overview
              </h2>
              <div>
                <div className="flex gap-4 mr-3">
                  <button onClick={(e)=>activeTab(1)} style={{borderColor : active === 1 && "#7FB04F" }} className="border-b-2 bg-[#F7F8FC] px-2 py-1">User Activity</button>
                  <button onClick={(e)=>activeTab(2)} style={{borderColor : active === 2 && "#7FB04F" }} className="border-b-2 bg-[#F7F8FC px-2 py-1">Training Data</button>
                </div>
                

             
              </div>
            </div>
            <p className=" text-black text-opacity-70 font-normal mb-2">
            Here is the activity overview of selected bot.
            </p>
           {active ===1 ?<div>
              <ActivityOverview id={id} />
            </div>:
            <div>
              <TrainingData id={id} />
            </div>}
        
      </div>
    </div>
  </div>
  )
}

export default AvatarMonitoringAndControl