
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import swal from "sweetalert";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: "30px 10px",
  fontFamily: "Gordita",
  borderRadius: "18px"
};

const MembersModel = ({ openmember, handleClosememberModel, setOpenmember, shareBotId }) => {
  const [members, setMember] = useState(null);
  const [membersId, setMemberId] = useState(null);

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');

//   console.log("members :", members,"membersId",membersId, "shareBotId :", shareBotId?.BotId);

  const handleMemberChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMemberId(e.target.value);
  };
  

  const handleShareBot = async (e) => {
    // console.log("Selected member ID:", membersId);
    const data = {
    UserId: membersId,
    BotId: shareBotId?.BotId,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/sharebot/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        setOpenmember(false);
        swal({
            title: "Success",
            text: "Avatare shared successfully.",
            icon: "success",
            button: "Dismiss!",
          });
      } else {
        console.log('Error:', response.status);
        swal({
            title: "Oh Snap!",
            text: "Somthing went wrong!",
            icon: "error",
            button: "Dismiss!",
          });
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?OrganizationId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setMember(dataa);
      } else {
        const dataa = await response.json();
        console.log('Error:', response.status, dataa);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Modal
        open={openmember}
        onClose={handleClosememberModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
            <Typography id="modal-modal-title" sx={{ fontSize: "20px", fontWeight: "500", color: "black", textAlign: "center",my:"10px" }}>
              Are you sure you want to Share Bot?
            </Typography>
            <Box>
              <Box sx={{ mx: "5px" }}>
                <FormControl size="small" fullWidth sx={{maxWidth:"80%", mx:"auto",display:"flex",justifyContent:"center"}}>
                  <Typography size={16} align="left">
                    Select Member
                  </Typography>
                  <Select
                    fullWidth
                    style={{width:"100%",display:"flex"}}
                    value={membersId}
                    onChange={(e)=>handleMemberChange(e)}
                    displayEmpty
                    IconComponent={() => (
                      <KeyboardArrowDownIcon />
                    )}
                    sx={{
                      color: "black",
                      width: "200px",
                      float: "right",
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    }}
                  >
                    {members?.message?.map((member) => {
                      return (
                        <MenuItem value={member?.Id}><Typography>{`${member?.first_name} ${member?.last_name}`}</Typography></MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ ml: "auto", mt: "30px", mb: "5px" }}>
              <Button variant='contained' sx={{ mx: "5px" }} onClick={handleShareBot}>
                Share
              </Button>
              <Button variant='outlined' sx={{ mx: "5px" }} onClick={handleClosememberModel}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default MembersModel;
