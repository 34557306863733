import React, { useEffect, useState } from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { AvatarFromScratchCard } from "../../components/MyAvatarNew/AvatarFromScratchCard";
import { getData } from "../../components/common/api";

export const MyAvatar = () => {
  const [avatares, setAvatares] = useState([]);
  const [rerender, setRerender] = useState(false);
  useEffect(() => {
    getData(setAvatares);
  }, [rerender]);
  return (
    <div>
      <TopBar text="My Avatare" />
      <div className="py-2 px-8">
        
        <div>
          <h1 className="text-xl font-medium mt-3">
            Created from Scratch
          </h1>
          <div className="mt-3.5 mb-20">
            <div className="flex gap-6 m-w-[50%] flex-wrap">
              {avatares?.map((data, key) => (
                <AvatarFromScratchCard dotsIcon={true} key={key} data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
