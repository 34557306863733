import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import Modale from './Model';
import { Button } from '@mui/material';

const CheckoutForm = ({fetchData}) => {
    const stripe = useStripe();
    const elements = useElements();
    const userId = localStorage.getItem('UserId');
    const token = localStorage.getItem('token');


    const [updatedStatus,setUpdatedStatus] = useState()
    const [open, setOpen] = useState(false);
    const [modelData ,setModelData]= useState({})

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                    return_url: `${window.location.origin}/organization/billing`,
            },
            redirect: "if_required",
        });
        // console.log('result: ', result);
        
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // console.log('result in else: ', result);
            // console.log("somthing ::");
            if (result?.paymentIntent?.status == "succeeded") {
                fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/creditupdate/`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserId: userId,
                        status: "succeeded",
                    })
                }).then((res) => {
                    return res.json();
                }).then((data) => {
                    setUpdatedStatus(data)
                    handleOpen()
                    setModelData(result?.paymentIntent)
                    fetchData()
                }).catch((error) => {
                    console.error('Error ::', error);
                });
            }
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <>
        <Modale open={open} handleOpen={handleOpen} handleClose={handleClose} setOpen={setOpen} modelData={modelData} />
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {/* <button disabled={!stripe}>Submit</button> */}
            <Button disabled={!stripe} type='submit' variant='contained' sx={{mx:"auto",width:"100%",my:"20px"}}>Submit</Button>
        </form>
        </>
    )
};

export default CheckoutForm;