import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const OrderSummryTable = ({cartItems}) => {
  return (
    <TableContainer component={Paper} sx={{bgcolor:"transparent",boxShadow:"none"}}>
    <Table sx={{ minWidth: 650}} aria-label="simple table">
      <TableHead> 
        <TableRow>
          <TableCell>Credits</TableCell>
          <TableCell align="left">price</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cartItems.length >= 0 && cartItems?.map((items)=>{
          return(
            <>
          <TableRow>
            <TableCell component="th" scope="row">
              {items.credits.toLocaleString()}
            </TableCell>
            <TableCell align="left">
                {items.cost.toLocaleString("en-US", {style:"currency", currency:"USD"})}
            </TableCell>
            <TableCell align="left" sx={{display:"flex",justifyContent:"end",py:"22px"}}>
              <EditIcon sx={{ color: "#295598", }}/>
              <DeleteIcon sx={{ color: "#295598", }}/>
            </TableCell>
          </TableRow>
            </>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export default OrderSummryTable