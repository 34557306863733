import React from "react";

const PopUpData = ({ isOpen, onClose, text, desc,buttonTextOne,buttonTextTwo,avatareName,setAvatareName ,handleClone,fromClone}) => {
  if (!isOpen) return null;
  const divStyle = {
    backgroundImage: "url(/popup-bg-image.svg)",
    backgroundRepeat: "no-repeat",
    width: "100%",
  };
  return (
    <div className="fixed inset-0  bg-opacity-70 bg-black flex items-center justify-center">
      <div
        style={divStyle}
        className="bg-white fixed rounded-lg shadow-lg px-[1%] py-[0.5%] flex flex-col  gap-1 items-center w-full h-full max-h-[220px] max-w-xl"
      >
        <img
          src="/cross-popup.svg"
          onClick={onClose}
          className="flex self-end w-10 cursor-pointer"
        />
        <h2 className="text-lg font-semibold px-8">{text}</h2>
        {desc &&<p className="px-6 mt-1">{desc}</p>}
       {fromClone && <input
        type="text"
        value={avatareName}
        onChange={(e)=>setAvatareName(e.target.value)}
        placeholder="add clone avatare name here"
        style={{ border: "1px solid rgba(116, 116, 116, 0.50)" }}
        className="w-[80%] p-2 mx-8 mt-1 text-base rounde  cursor-pointer custom-outline"
      />}
        <div className="flex justify-center items-center gap-3 mt-3">
        <button onClick={handleClone} className="primary px-4 py-2" >{buttonTextOne}</button>
        {!fromClone && <button
          onClick={onClose}
          className="secondary px-4 py-2"
        >
          {buttonTextTwo}
        </button>}
        </div>
      </div>
    </div>
  );
};

export default PopUpData;
