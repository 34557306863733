import React from "react";

export const AvatareName = (props) => {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-1.5">
      <h1 className="font-medium text-lg">Add your Avatare name</h1>
      <p>Give a name to your Avatare suited best for its purpose.</p>
      <input
        type="text"
        value={props.avatareName}
        onChange={(e)=>props.setAvatareName(e.target.value)}
        placeholder="add avatare name here"
        style={{ border: "1px solid rgba(116, 116, 116, 0.50)" }}
        className="w-full p-2 mt-4 text-base rounded bg-white cursor-pointer custom-outline"
      />
    </div>
  );
};
