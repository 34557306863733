import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import UsageComp from '../../components/Organization/Usage/Usage'

const Usage = () => {
  return (
    <>
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      // gap: "4vh",
      py:"20px"
    }}
  >
       <Box>
            <Typography
                align={"left"}
                fontSize={"38px"}
                fontWeight={600}
                fontFamily={"Gordita"}
            >
               Credits History
            </Typography>
            <Typography align={"left"} sx={{ fontFamily: "Gordita", }}>
            Track your credits usage history.
            </Typography>
            <Typography
                align={"left"}
                fontSize={"38px"}
                fontWeight={600}
                fontFamily={"Gordita"}
                sx={{mt:"40px"}}
            >
               Usage
            </Typography>
        </Box>
        <UsageComp />
  {/* <Index credits={credits} setCredits={setCredits} value={value} setValue={setValue} /> */}
  </Container>
  </>
  )
}

export default Usage