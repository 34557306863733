import React from 'react'

export const AvatarFromScratchCard = ({dotsIcon,data,handleCLick,fromIntegration}) => {
  return (
    <div onClick={!dotsIcon && (()=> handleCLick(fromIntegration? data?.BotId?.BotId:data?.BotId))}  className='flex flex-col justify-center  items-center gap-1.5 border  hover:border-[#497964] text-wrap m-h-[165px] bg-white rounded-md min-w-[203px] py-3 cursor-pointer'>
        {dotsIcon && <img src="/threeIcon.svg" className="w-[6px] self-end mr-4  cursor-pointer" />}
        <img src={data?.emojiS3Link ? data.emojiS3Link:"/default-bot-icon.svg"} className='w-[60px] h-[60px] mt-3 rounded-full' />
        <h1 className='text-lg text-black font-medium  pb-5'>{fromIntegration? data?.BotId?.BotName:data?.BotName}</h1>
        {/* <p className='text-[#1F1F1F]  font-[400] mt-0.5'  >{data?.BotDescription}</p> */}
    </div>
  )
}
