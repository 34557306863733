import React from 'react';


const CreateFolderModal = ({ isOpen, createFolderButton,handleClose,setter,getter,text,desc,placeholder,buttonTextOne}) => {
  if (!isOpen) return null;
  const divStyle = {
    backgroundImage: "url(/popup-bg-image.svg)",
    backgroundRepeat: "no-repeat",
    width: "100%",
  };

   
  return (
    <div className="fixed inset-0  bg-opacity-70 bg-black flex items-center justify-center">
    <div
      style={divStyle}
      className="bg-white fixed rounded-lg shadow-lg px-[1%] py-[0.5%] flex flex-col  gap-1 items-center w-full h-full max-h-[240px] max-w-xl"
    >
      <img
        src="/cross-popup.svg"
        onClick={handleClose}
        className="flex self-end w-10 cursor-pointer"
      />
      <h2 className="text-lg font-semibold px-8">{text}</h2>
      {desc &&<p className="px-6 mt-1">{desc}</p>}
   <input
      type="text"
      value={getter}
      onChange={(e)=>setter(e.target.value)}
      placeholder={placeholder}
      style={{ border: "1px solid rgba(116, 116, 116, 0.50)" }}
      className="w-[80%] p-2 mx-8 mt-1 text-base rounde  cursor-pointer custom-outline"
    />
      <div className="flex justify-center items-center gap-3 mt-3">
      <button onClick={createFolderButton} className="primary px-4 py-2" >{buttonTextOne}</button>
     
      </div>
    </div>
  </div>
    // <Modal
    //     open={open}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //     sx={{
    //         ".MuiBackdrop-root": {
    //             backgroundColor: "rgba(29, 77, 106, 0.43)",
    //           }
    //     }}
    //   >
    //     <Box className={styles.outermostCard}>
    //       <Typography id="modal-modal-title" variant="h6" fontWeight={"bold"} component="h2">
    //         {createFolderPrompt}
    //       </Typography>
    //       <hr  className={styles.divider}/>
    //       <Typography id="modal-modal-description" fontWeight={"bold"} sx={{ mt: 2, mb:2 }}>
    //         Name of your folder:
    //       </Typography>
    //       <TextField id="FolderName" variant="outlined" className={styles.textField}  fullWidth/>
    //       <Container
    //       className={styles.buttonContainer}>
    //         <Button variant="contained"
    //                 size="small"
    //                 onClick={handleClose}
    //                 sx={{
    //                 backgroundColor: "#ffffff",
    //                 border:"solid",
    //                 borderColor:"black",
    //                 color: "#295598",
    //                 mr:2,
    //                 "&.MuiButtonBase-root:hover": {
    //                     bgcolor: "#ffffff",
    //                 },
    //                 }}
    //                 >Close</Button>
    //         <Button variant="contained"
    //                 size="small"
    //                 onClick={createFolderButton}
    //                 sx={{
    //                   backgroundColor: "#295598",
    //                   border:"solid",
    //                   borderColor:"black",
    //                   color: "#ffffff"}}>
    //                     Create Folder</Button>
                    
    //       </Container>
    //     </Box>
    //   </Modal>

  )
}

export default CreateFolderModal