import React from 'react'

export const OverallProgress = () => {
  return (
    <div className="flex flex-col items-center  mt-[5%]">
    <h1 className="text-3xl font-bold mb-2">Overall Progress</h1>
    <p className="text-gray-600 mb-4">Below timeline shows your current progress so-far. Scroll vertically to see details.</p>
    <div className="relative flex items-center justify-center">
      {/* <div className="absolute animate-spin w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full"></div> */}
      <div className="w-36 h-36 rounded-full border-8 border-gray-200 flex items-center justify-center">
        <span className="text-2xl font-semibold text-gray-600">0%</span>
      </div>
    </div>
  
  </div>
  )
}
