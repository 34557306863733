import * as React from "react";
import { OverallProgress } from "../components/Home/OverallProgress";
import TrackorComponent from "../components/Home/Tracker";
import { TopBar } from "../components/CommonComponent/Topbar";

const data = {
  block1: {
    text: "Identify Problems & Trends",
    textColor: "#10426E",
    lineColor: "var(--Turquise, #6CA6C4)",
  },
  block2: {
    text: "Evaluate Problems & Trends",
    textColor: "#2B2649",
    lineColor: "#37315D",
  },
  block3: {
    text: "Select the Focus Problem",
    textColor: "var(--Fern, #497964)",
    lineColor: "var(--Fern, #497964)",
  },
  block4: {
    text: "Identify Pain Points",
    textColor: "var(--Kiwi, #7FB04F)",
    lineColor: "var(--Kiwi, #7FB04F)",
  },
  block5: {
    text: "Determine Jobs to be Done",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
  },
  block6: {
    text: "Profitable Market Need",
    textColor: "var(--Turquise, #6CA6C4)",
    lineColor: "var(--Turquise, #6CA6C4)",
  },
  block7: {
    text: "Identify a Potential Solution to the Problem",
    textColor: "var(--Kiwi, #7FB04F",
    lineColor: "var(--Kiwi, #7FB04F",
  },
  block8: {
    text: "Strategic Innovation Statement",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
  },
  block9: {
    text: "Idea Testing with Mini Focus Group",
    textColor: "var(--Fern, #497964",
    lineColor: "var(--Fern, #497964)",
  },
  block10: {
    text: "Quick Market Analysis",
    textColor: "var(--CERULEAN, #20556E)",
    lineColor: "var(--CERULEAN, #20556E)",
  },
};


const Home = () => {
  return (
    <>
      <TopBar text="My Progress" />
      <OverallProgress />
      <div className="flex flex-col justify-center self-center items-center mt-[6%] w-[90%] lg:w-[86%]   relative">
        <div className="absolute top-10 left-[-100px] transform -rotate-90 self-start text-[#7FB04F] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>1</span>
          
          <span className="ml-2">Nail the pain : Spark & Explore</span>
        </div>

        <div
          style={{ background: "rgba(127, 176, 79, 0.20)" }}
          className="absolute -top-14 ml-[150px] px-20 flex justify-center items-center h-[580px] w-[923px]"
        ></div>

        <div> 
          <TrackorComponent showFirst={true} key={1} data={data} last={true}/>
        </div>

        <div
          style={{ background: "rgba(32, 85, 110, 0.12)" }}
          className="absolute -mt-[2500px] ml-[150px] px-20 flex justify-center items-center h-[1050px] w-[923px]"
        ></div>

        <div
          style={{ background: "rgba(73, 121, 100, 0.19)" }}
          className="absolute -mt-[820px] ml-[150px] px-20 flex justify-center items-center h-[500px] w-[923px]"
        ></div>
        <div
          style={{ background: "rgba(108, 166, 196, 0.19)" }}
          className="absolute mt-[250px] ml-[150px] px-20 flex justify-center items-center h-[500px] w-[923px]"
        ></div>
        <div
          style={{ background: "rgba(73, 121, 100, 0.19)" }}
          className="absolute -mt-[-2500px] ml-[150px] px-20 flex justify-center items-center h-[1650px] w-[923px]"
        ></div>
        <div
          style={{ background: "rgba(32, 85, 110, 0.12)" }}
          className="absolute -mt-[-4600px] ml-[150px] px-20 flex justify-center items-center h-[400px] w-[923px]"
        ></div>
        <div className="mt-[100px]">
          <TrackorComponent showFirst={false} key={2} data={data} last={true}/>
        </div>
        <div className="absolute top-[900px] right-[-272px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>2</span>
          <span className="ml-2">Nail the Solution : Questions & Uncover</span>
        </div>

        <div className="absolute top-[1750px] left-[-100px] transform -rotate-90 self-start leading-4 text-[#497964] font-bold  text-center mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>3</span>
          <span className="ml-2">Nail the Go-to-Market Strategy <br />- Experiment & Network</span>
        </div>
        <div className="absolute top-[2250px] right-[-262px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>4</span>
          <span lassName="ml-2">Nail the business model- Collaborate</span>
        </div>

        <div className="absolute top-[3500px] left-[-35px] transform -rotate-90 self-start text-[#497964] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>5</span>
          <span className="ml-2">Scale It- Exit</span>
        </div>
        <div className="absolute top-[4400px] right-[-195px] transform -rotate-90 self-end text-[#5C94C6] font-bold  mt-[15%]">
          <span style={{ fontSize: "32px",fontStyle:"bold" }}>6</span>
          <span className="ml-2">Raising the Capital</span>
        </div>
        
        <div className=" mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true} />
        </div>
        <div className=" mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true} />
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className=" mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true}/>
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={true} />
        </div>
        <div className="  mt-[100px]">
          <TrackorComponent showFirst={false} key={3} data={data} last={false} />
        </div>
      </div>
      </>
  );
};

export default Home;
