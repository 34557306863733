// api.js

import { message } from "antd";

let UserId = localStorage.getItem('UserId')
let token = localStorage.getItem('token')
const API_ROOT = process.env.REACT_APP_API_ROOT;
export const getData = async (setAvatares) => {
   
    try {
      const response = await fetch(`${API_ROOT}/api/bot/createbot/?UserId=${UserId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setAvatares(data?.message);
      } else {
        const data = await response.json();
        if (data.message === "No Avatars Yet") {
          setAvatares(data.message);
        }
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  export const getTrainedData = async (setAvatares) => {
   
    try {
      const response = await fetch(`${API_ROOT}/api/bot/gettrainedbots/?UserId=${UserId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setAvatares(data?.message);
      } else {
        const data = await response.json();
        if (data.message === "No Avatars Yet") {
          setAvatares(data.message);
        }
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  // api.js



export const cloneAvatar = async (botId, name,setisOpen,setIsModalOpen) => {
  const data = {
    BotId: botId,
    BotName: name,
    UserId: UserId
    ,
  };

  try {
    const response = await fetch(`${API_ROOT}/api/bot/clonebot/?BotId=${botId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      cache: "default",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      setisOpen(true);
      setIsModalOpen(false)
      return { success: true, data: responseData };
    } else {
      const errorData = await response.json();
      message.error(errorData?.message)
      return { success: false, error: errorData };
    }
  } catch (error) {
   
    return { success: false, error };
  }
};

// src/services/apiService.js

export const fetchData = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/api/data/glossary/?UserId=${UserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const data = await response.json();
      return { success: false, data, status: response.status };
    }
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return { success: false, error: error.message };
  }
};

export const postLanguage = async (language) => {
  if (!language) {
    throw new Error("Language is required");
  }

  const data = {
    UserId: UserId,
    language: language,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/api/data/glossary/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const data = await response.json();
      return { success: false, data, status: response.status };
    }
  } catch (error) {
    console.error("Error posting language:", error.message);
    return { success: false, error: error.message };
  }
};


  