import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Typography,
} from "@mui/material";
import Breadcrumbs_component from "../../components/common/Breadcrumbs";
import { useLocation } from "react-router-dom";
import AvataresContainer from "../../components/Classroom/AvataresContainer/AvataresContainer";

const TrainMyModel = ({totalCredits,setChangeCredits}) => {
  /* eslint eqeqeq: 0 */
  const [avatares, setAvatares] = React.useState([]);

  const [botId, setBotId] = React.useState([]);
  const buttonMessage="";

  const { state } = useLocation();

  const trainmymodel = true

  let bData = {};
  let bMessage = {};

  const getData = async () => {
    let userId = localStorage.getItem('UserId');
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${userId}`,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
    ).then((data) => data.json());
  };
  React.useEffect(() => {
    let mounted = true;
    if (state) {
      const { botData, buttonMessage } = state;
      bData = botData;
      bMessage = buttonMessage;
    }
    getData().then((items) => {
      var bot = items.message.map((botObject) => {
        if (botObject.BotId === parseInt(bData.BotId)) {
          setBotId([...botId, bData.BotId]);

          if (buttonMessage == "") {
            return Object.assign(botObject, { buttonMess: bMessage });
          } else {
            return Object.assign(botObject, { buttonMess: buttonMessage });
          }
        } else {
          return Object.assign(botObject, { buttonMess: "Train" });
        }
      });
      setAvatares(bot);
    });

    return () => (mounted = false);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          // margin: "3vw",
        }}
      >
        <Breadcrumbs_component
          Left="Classroom"
          Right="Train My Avatare By AI Model"
        />
        <Box
          sx={{
            bgcolor: "#E1F4FF",
            height: "100%",
            width: "100%",
            display:"flex",
            justifyContent:"space-around",
            flexWrap: "wrap",
            alignItems:"center",
            my: "20px",
            py:"20px",
            borderStyle: "dashed",
            borderWidth: "1px",
            overflowY:"auto"
          }}
        >
          <Typography fontSize={24} sx={{ m: 2 }}>
            Please select your avatare to start training!
          </Typography>
          <AvataresContainer trainmymodel={trainmymodel} avatares={avatares} pageName={"TrainMyModel"} totalCredits={totalCredits} setChangeCredits={setChangeCredits} />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default TrainMyModel;
