import * as React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import PolicyQuestionTable from "./PolicyQuestionTable";


const TrainingData = ({id}) => {
  const location = useLocation()
const userdata = location.state
const [chartData , setChartData] = React.useState([])
const token = localStorage.getItem('token');
const navigate = useNavigate()

const handleQuestions =(DataId)=>{
  navigate("/operations/policyquestion",{state:{ DataId }})
}

const fetchData = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainedbotdataset/?BotId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.ok) {
      const dataa = await response.json();
      setChartData(dataa);
      // console.log(dataa ,"");
    } else {
      console.log('Error:', response.status);
    }
  } catch (error) {
    console.log('Error:', error.message);
  }
};

React.useEffect(() => {
  fetchData();
}, []);
// console.log("this is taring Data ",chartData);


  return (
    <>
    <div className="mt-2">
    <PolicyQuestionTable />
    </div>
    
  </>
  )
}

export default TrainingData