import React from 'react'
import { Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from "@mui/material/Button";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Container from "@mui/material/Container";
import styles from './AddMyEmojiCard.module.css';
import { useNavigate } from 'react-router-dom';

const AddMyEmojiCard = ({ botId, name, desc, variant, emoji }) => {
    console.log('botId: ', botId);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/admin/addEmoji', { state: { botId: botId, avatareName: name, description: desc } })
    }
    const showDataFiles = () => {
        window.location.href = '/library/dataFiles/' + botId
    }
    React.useEffect(() => {
        console.log("botId is: ", botId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
            <Card className={styles.outermostCard} sx={{ border: "dashed", borderWidth: 1,position:"relative",textAlign:"center"  }}>
                <CardMedia
                    className={styles.cardMedia}
                >
                    {variant !== "DataFiles" ?
                        <AccountCircleIcon className={styles.AccountCircleIcon} /> : <><img alt=' ' src={emoji} className={styles.AccountCircleIcon} /></>}
                </CardMedia>
                <CardContent sx={{ padding: 0,}}>
                    <Typography className={styles.AvatareName}>
                        {name}
                    </Typography>
                    <Typography fontSize={12}>
                        {desc}
                    </Typography>
                    <Container
                        className={styles.buttonContainer} sx={{position:"absolute",left:"50%",bottom:"10px",transform:"translate(-50%)"}} >

                        {variant !== "DataFiles" ? <><Button
                            variant="contained"
                            size="medium"
                            className={styles.button}
                            onClick={handleClick}
                            sx={{
                                backgroundColor: "#295598",
                                color: "#ffffff",
                                "&.MuiButtonBase-root:hover": {
                                    bgcolor: "#295588",
                                },
                            }}
                        >
                            Add an Emoji
                        </Button></> :
                            <Button
                                variant="contained"
                                size="medium"
                                className={styles.button}
                                onClick={showDataFiles}
                                sx={{
                                    backgroundColor: "#295598",
                                    color: "#ffffff",
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "#295588",
                                    },
                                }}
                            >
                                Add Data Files
                            </Button>}

                    </Container>
                </CardContent>
            </Card>
    )
}

export default AddMyEmojiCard






