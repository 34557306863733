import React from 'react'
import YouTubeIconOutlined from '@mui/icons-material/YouTube';
import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player';
import MuiPlayerDesktopPlugin from 'mui-player-desktop-plugin';


const Video = ({url, thumbnail}) => {
    React.useEffect(() => {
        let thumbnails = {
            preview:[thumbnail], 
            tile:[10,10], 
            scale:[160,90], 
        }
        var mp = new MuiPlayer({
            container:'#mui-player',
            src:url,
            lang: 'en',
            themeColor:"#295598",
            autoplay:false, 
            preload:'auto', 
            loop:false, 
            width:'100%',
            height:'100%',
            volume:1, 
            autoFit:false, 
            poster:thumbnail,
            live:false, 
            initFullFixed:false, 
            autoOrientaion:true, 
            dragSpotShape:'circular', 
            objectFit:'contain', 
            
            
            videoAttribute:[
                {attrKey:'webkit-playsinline',attrValue:''},
                {attrKey:'playsinline',attrValue:''},
                {attrKey:'x5-playsinline',attrValue:''},
                {attrKey:'t7-video-player-type',attrValue:'inline'},
                {attrKey:'x5-video-player-type',attrValue:'h5-page'},
                {attrKey:'x-webkit-airplay',attrValue:'allow'},
                {attrKey:'controlslist',attrValue:'nodownload'},
            ],
            showMiniProgress:true,
            pageHead:true, 
            custom:{
                headControls:[ 
                    {
                        slot:'castScreen',
                        click:function(e) {
                            console.log('The head first custom button is clicked >>>');
                        }
                    },
                    {
                        slot:'likeButton',
                        click:function(e) {
                            console.log('The head second custom button is clicked >>>');
                            mp.showRightSidebar('HD');
                        }
                    }
                ],
                footerControls:[ 
                    {
                        slot:'nextMedia',
                        position:'left', 
                        tooltip:'下一集', 
                        oftenShow:false, 
                        style:{},
                        click:function(e) {
                            console.log('The footer first custom button is clicked >>>');
                        }
                    },
                    {
                        slot:'toggleleProgram',
                        position:'right',
                        tooltip:'切换选集',
                        oftenShow:false,
                        style:{
                            padding: 0,
                        },
                        click:function(e) {
                            console.log('The footer second custom button is clicked >>>');
                        },
                    },
                ],
                rightSidebar:[ 
                    { slot:'HD',width:'200px' }
                ],
            },
            plugins:[ 
                typeof MuiPlayerDesktopPlugin == 'function' ? new MuiPlayerDesktopPlugin({
                    fullScaling:1.2,
                    thumbnails:thumbnails,
                    leaveHiddenControls:false,
                    contextmenu:[ 
                        {
                            name:'share',
                            context:'Share',
                            zIndex:0,
                            show:true,
                            click:function(close) {
                                mp.showToast('Trigger Click！');
                                close();
                            }
                        },
                    ],
                }) : {}
            ],
                })
                mp.on('ready',function() {
                    
                    
                    var th = document.querySelectorAll('[type="toggleHD"]');
                    for(var i = 0;i < th.length;i++) {
                        th[i].onclick = (e) => {
                            
                        }
                    }
                });
            
                
                mp.on('duration-change',function(data) {
                });
            
               
                mp.on('seek-progress',function(data) {
                });
            
                mp.on('back',function() {
                });
            
                mp.on('controls-toggle',function(data) {
                });
            
                mp.on('error',function(e) {
                    console.error(e);
                });
            
                mp.on('destroy',function() {
                })
            
               
            
            
                // --------------------- mui-player-mobile-plugin.js ---------------------
                mp.on('volume-change',function(e) {
                   
                });
                
                mp.on('luminance-change',function(e) {
                   
                });
                
                mp.on('share',function(e) {
                    console.log(e);
                });
      });
  return (
    <div id="mui-player"></div>
  )
}

export default Video