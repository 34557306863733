import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Typography,
} from "@mui/material";
import {  atomOneLight, CopyBlock} from "react-code-blocks";
import ChatWidget from "./ChatWidget";
import { useParams } from "react-router-dom";
import { TopBar } from "../../components/CommonComponent/Topbar";

const SelectIntegration = () => {
  const [cssForReact, setCssForReact] = useState("");

  const { id } = useParams();
  console.log(id)

  const getLink = () => {
    return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/downloadcss/`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }).then((data) => data.json());
  };

  useEffect(() => {
    getLink().then((items) => {
      console.log("Items: ", items);
      setCssForReact(items.message);
    });
  }, []);



  const JAVASCRIPT_WIDGET_CODE = `
  <style>
    #popmake-22601 {
      padding:0px!important;
      margin:0px!important
    }
    #send {
      padding: 10px;
      border-radius: 5px;
      color: black;
      height:53px;
      width: 90px;
    }
    .reply__container {
      display:flex;
      justify-content:flex-end;
    }
    * {
        box-sizing: border-box;
        margin: 0;    
        padding: 0;
        }
    *, html {   
       --primaryGradient: linear-gradient(93.12deg, #F26724 100%, #F26724 100%);   
        --secondaryGradient: linear-gradient(268.91deg, #581B98 -2.14%, #9C1DE7 99.69%);    
        --primaryBoxShadow: 0px 10px 15px rgba(0, 0, 0, 0.1);    
        --secondaryBoxShadow: 0px -10px 15px rgba(0, 0, 0, 0.1);    
        --light: 300;
        --regular: 400;    
        --semiBold: 600;    
        --extraLight: 300;    
        --italic: 300;    
        --primary: #F26724;
      }
      /* 300;0,400;0,600;1,300 */body {
        background: #F1F1F1;
      }.chatbox__support {
        background: #F9F9F9;    
        height: 450px;    
        width: 275px;    
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);    
        border-top-left-radius: 20px;    
        border-top-right-radius: 20px;
      }
      /* HEADER */.chatbox__header {    
        background: var(--primaryGradient);    
        display: flex;    
        flex-direction: row;    
        align-items: center;    
        justify-content: center;    
        padding: 5px 7px;    
        border-top-left-radius: 20px;    
        border-top-right-radius: 20px;    
        box-shadow: var(--primaryBoxShadow);
      }
      .chatbox__image--header {    
        margin-right: 5px;
      }
      .chatbox__heading--header {    
        font-size: 1.2rem;
        color: white;
      }
      .chatbox__description--header {    
        font-size: .9rem;    
        color: white;}
        /* Messages */
        .chatbox__messages {    
          padding: 0 10px;    
          height: 280px;    
          overflow:scroll!important;
        }
      .messages__item {    
        margin-top: 5px;    
        background: #E0E0E0;    
        padding: 4px 6px;    
        max-width: 70%;
      }
      .messages__item--visitor,.messages__item--typing {    
        border-top-left-radius: 20px;    
        border-top-right-radius: 20px;    
        border-bottom-right-radius: 20px;    
        padding:10px;
      }
      .messages__item--operator {    
          border-top-left-radius: 20px;    
          border-top-right-radius: 20px;    
          border-bottom-left-radius: 20px;    
          background: var(--primary);    
          height: fit-content;    
          color: white;    padding:10px;}
          /* FOOTER */
    .chatbox__footer {    
      display: flex;    
      flex-direction: row;    
      align-items: center;    
      justify-content: space-between;    
      padding: 5px 5px;    
      background: var(--primaryGradient);    
      box-shadow: var(--secondaryBoxShadow);    
      border-bottom-right-radius: 10px;    
      border-bottom-left-radius: 10px;    
      margin-top: 5px;
    }
    .chatbox__footer input {    
      border: none;    
      padding: 5px 5px;    
      border-radius: 0px;    
      height:53px;    
      text-align: left;
    }
    .chatbox__send--footer {}
  .chatbox__button button,.chatbox__button button:focus, .chatbox__button button:visited {    
    padding: 5px;    
    background: white;    
    border: none;    
    outline: none;    
    border-top-left-radius: 50px;    
    border-top-right-radius: 50px;    
    border-bottom-left-radius: 50px;    
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);    
    cursor: pointer;}
    
    </style>
  <div class="container">
    <div class="chatbox">
      <div class="chatbox__support">
        <div class="chatbox__header">
          <div class="chatbox__content--header">
            <h4 class="chatbox__heading--header">Chat support</h4>
          </div>
        </div>
      <div class="chatbox__messages">
        <div id="messages__list">
          <div id="Query" class="messages__item messages__item--visitor">
          How can I help you?
          </div>
          <div class="reply__container">
          </div>
        </div>
      </div>
  <div class="chatbox__footer">
    <input id="msg" size="35" type="text" placeholder="Write a message..." />
    <button id="send">
        Send
    </button>
  </div>
  </div>
  </div>
  </div>
  <script src='//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js'></script>
  <script>$(document).ready(()=>{
  console.log('hello')})
  $('#send').on('click',function(e){
  console.log(e);
  var Query=$('#msg').val();
  console.log(Query);
  $.ajax({
  type:"POST",
  url:'https://avatare.com/api/bot/askquestion/',
  dataType: "json",
  contentType: "application/json",
  data:JSON.stringify({BotId: ${id}, Question: Query, API_Key: '1234qwerty'}),
  success:function(result){
  console.log(result)
  const container = document.createElement("div");
  container.setAttribute("class",'reply__container');
  const node1 = document.createElement("div");
  node1.setAttribute("class","messages__item messages__item--operator");
  const textnode1 = document.createTextNode(Query);
  node1.appendChild(textnode1);
  container.appendChild(node1);
  document.getElementById("messages__list").appendChild(container);
  const node = document.createElement("div");
  node.setAttribute("class","messages__item messages__item--visitor");
  const textnode = document.createTextNode(result.message);
  node.appendChild(textnode);
  document.getElementById("messages__list").appendChild(node);
  document.getElementById('messages__list').scrollIntoView(false);
  document.getElementById('msg').value = ""
  },
  error:function(e){
  console.log(e);
  }
  });
  });</script>
        `;

  const REACT_WIDGET_CODE =
    `
  import React from 'react';
  import { Widget, addResponseMessage, addUserMessage, toggleWidget } from 'react-chat-widget';
  import styles from "./ChatWidget.css";
  
  const ChatWidget = () => {
      const [rerender, setRerender] = React.useState(true)
  
    React.useEffect(() => {
      console.log("rerender")
    }, [rerender]);
  
    const handleNewUserMessage = (newMessage) => {
      console.log('New message incoming! ', newMessage);
      // Now send the message throught the backend API
      let body = {
          BotId: ` +
    id +
    `,
          Question: newMessage
      }
    fetch("https://avatare.com/api/bot/query/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify(body),
      cache: "default",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
          console.log(data.message);
          addResponseMessage(data.message);
          setRerender(!rerender)
    })
  }
  
  
    return (
      <div style={{height:"30vh", width:"30vw"}}>
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          title="Chatbot"
          showCloseButton={true}/>
      </div>
    );
  }
  
  export default ChatWidget;`;
  return (
    <>
     
      {/* <div
        fixed
        style={{
          display: "flex",
          flexDirection: "column",
          // margin: "3vw",
        }}
      >
       
        <Typography
          fontSize={22}
          sx={{
            m: 2,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Please select a solution to integrate!
        </Typography>
        <Box
          sx={{
            bgcolor: "#E1F4FF",
            height: "35vh",
            width: "65vw",
            mt: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              overflow: "auto",
            }}
          >
            <IntegrationBox />
          </Container>
        </Box> 
      </div> */}
      <TopBar text={"Bot Integrations"} backArrow={true} />
      <div className="bg-white p-6 mx-8 mt-8 mb-12 h-fit rounded-md space-y-5 w-[95%]">
        <h1
         className="text-lg font-medium"
         
        >
          Javascript and HTML code to integrate bot into your website
        </h1>
        <div className="p-4 w-full rounded-md border-2 border-gray-400 text-left h-[40vh] overflow-y-auto">
          <CopyBlock
            text={JAVASCRIPT_WIDGET_CODE}
            language="HTML"
            showLineNumbers={false}
            theme={atomOneLight}
            codeBlock
            wrapLines
          />
        </div>
     
        <h1
         className="text-lg font-medium"
         
        >
        
          React JSX hook based component to integrate bot into your website
        </h1>
        <div  className="p-4 w-full rounded-md border-2 border-gray-400 text-left h-[40vh] overflow-y-auto">
          <CopyBlock
            text={REACT_WIDGET_CODE}
            language="jsx"
            showLineNumbers={false}
            theme={atomOneLight}
            codeBlock
            wrapLines
          />
        </div>
      
      <div className="mt-7 text-left" >
        <h1 className="text-xl font-medium">Instructions: </h1>
        <p className="text-base font-medium mt-1">
          You can use a javascript library by the name of React chat widget for
          implementation of this chatbot. Details of installation and usage can
          be seen{" "}
          <a  className="underline underline-offset-2 font-semibold" href="https://www.npmjs.com/package/react-chat-widget">here.</a>
        </p>
        <h1 className="text-base font-medium mt-1">
          For demonstration on how your avatar will look like after integration,
          Toggle the chat widget below.
        </h1>
        <h1 className="text-base font- mt-1">
          The CSS file that we used can be downloaded from{" "}
          <a className="underline underline-offset-2 font-semibold" href={cssForReact}>here</a>. You can also add custom CSS to improve
          the UI of your chatbot.
        </h1>
        <ChatWidget BotId={id} />
      </div>
      </div>
    </>
  );
};

export default SelectIntegration;
