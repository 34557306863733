
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

function AudioRecorder({ onRecordingComplete, setAudioFile }) {
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [file, setFile] = useState(null);

  // console.log('duration: ', duration);

  const onStart = (e) => {
    // console.log('e: ', e);
    setIsRecording(true);
  }

  const onStop = (recordedBlob) => {
    setIsRecording(false);
    // console.log('onstop recorded Blob is : ', recordedBlob);
    const startTimeInSeconds = recordedBlob.startTime / 1000;
    const stopTimeInSeconds = recordedBlob.stopTime / 1000;
    const timeDuration = stopTimeInSeconds - startTimeInSeconds
    // console.log('timeDuration : ', timeDuration);
    // setDuration(timeDuration);
    
    // Convert the blob into a file
    const audioFile = new File([recordedBlob.blob], 'recorded_audio.mp3', {
      type: 'audio/mpeg',
      lastModified: new Date().getTime(),
      size: recordedBlob.blob.size,
    });
    
    console.log('audio File ::: ', audioFile);
    setAudioFile({ file: audioFile, duration: timeDuration });
    setFile(audioFile);
    // You can now save the audioFile to your server or perform other actions.
  }


  const onData = (recordedBlob) => {
    // console.log('b: ', recordedBlob);
  }

  return (
    <div>
      <h2 style={{display:"flex",justifyContent:"center"}}>Audio Recorder</h2>
      <Box
        sx={{
          backgroundColor: "#E1F4FF",
          border: "solid",
          borderRadius: 2,
          height: "180px",
          width: "100%",
          mt: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >


      <ReactMic
        record={isRecording}
        className="sound-wave"
        onStart={onStart}
        onStop={onStop}
        onData={onData}
      />
      <Box sx={{display:"flex",my:"10px"}}>
      <button style={{margin:"0px 5px",backgroundColor:"transparent",border:"none",outline:"none",}} onClick={onStart} disabled={isRecording}>
        <MicIcon />
      </button>
      <button style={{margin:"0px 5px",backgroundColor:"transparent",border:"none",outline:"none"}} onClick={onStop} disabled={!isRecording}>
        <StopIcon />
      </button>
      </Box>
      </Box>
    </div>
  );
}

export default AudioRecorder;
