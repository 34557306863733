import React from "react";
import "mui-player/dist/mui-player.min.css";
import Video from "./Video";

const VideoCard = ({ videoName, url, transcript, thumbnail }) => {
  return (
    <div>
      <div className="w-full h-[60%]">
        <div>
          <div>
            <Video url={url} thumbnail={thumbnail} />
          </div>

          <div className="w-full text-left text-wrap mt-4">
            <h1 className="text-lg font-semibold">How to Guide : {videoName}</h1>
            {transcript.split(".").map((item) => {
              return (
                <p className="font-[400] text-black text-opacity-[0.82] leading-5">
                  {item}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
