import React from 'react';
import TemplateDetail from '../../components/Template/TemplateDetail';
import SlideViewer from '../../components/Template/SlideViewer';
import { TopBar } from '../../components/CommonComponent/Topbar';
import { useLocation } from 'react-router-dom';

const slides = [
 
  'slide_1.svg', 'slide_2.svg', 'slide_3.svg',
  'slide_4.svg','slide_5.svg','slide_6.svg','slide_7.svg','slide_8.svg','slide_9.svg','slide_10.svg','slide_11.svg','slide_12.svg',
 
];

function Template() {
  const location = useLocation();
 
  const { ...state } = location.state || {};

  return (
    <div>
      <TopBar backArrow={true} text={state.title}/>
      <div className='flex py-16 px-12 bg-[#F7F8FC]'>
       <div className='bg-white w-full flex'> 
      <TemplateDetail  {...state}/>
      <SlideViewer  index={state.index} heading={state.heading} title={state.title} newChat={state.newChat} />
      </div>
      </div>
    </div>
  );
}

export default Template;
// import React from 'react';
// import { Steps } from 'antd';


// const { Step } = Steps;

// const CustomSteps = () => (
//   <Steps size="small" progressDot current={2} className="custom-steps">
//     <Step title="Company Details"  />
//     <Step title="Pain Points" />
//     <Step title="Value Proposition" />
//     <Step title="Pain Points" />
//     <Step title="Value Proposition" />
//     <Step title="Pain Points" />
//     <Step title="Value Proposition" />
    
//   </Steps>
// );

// export default CustomSteps;

