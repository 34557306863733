import { Container } from '@mui/material'
import React from 'react';
import HistoryModel from '../../components/Organization/Team/MembersTable/HistoryModel';

const HistoryTable = () => {
  return (
    <Container>
       <HistoryModel />
    </Container>
  )
}

export default HistoryTable