import React, { useEffect, useState } from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import { Select, message } from "antd";
import { fetchData, postLanguage } from "../../components/common/api";

const languages = [
  "Arabic",
  "Spanish",
  "Greek",
  "Dutch",
  "French",
  "Latin",
  "Nepali",
  "Irish",
  "Korean",
  "Filipino",
  "Russian",
  "German",
  "Japanese",
  "Thai",
  "Chinese",
  "Italian",
  "Serbian",
  "Turkish",
  "Hindi",
  "Bengali",
];

const languageOptions = languages.map((language) => ({
  value: language.toLowerCase(),
  label: language,
}));

const Translation = () => {
  const [language, setLanguage] = useState(null);
  const onValueChange = (value) => {
    setLanguage(value);
  };
  const handleFetchData = async () => {
    const result = await fetchData();
    if (result.success) {
      console.log(result);
      setLanguage(result?.data?.message[0]?.language);
    }
  };

  const handlePostLanguage = async () => {
    if (!language) {
      message.error("Please select your language");
    } else {
      const result = await postLanguage(language);
      if (result.success) {
        message.success("Your selected language added successfully");
      } else {
        message.error(result.status || "Failed to post language");
      }
    }
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      <TopBar text="Translation Glossary" />
      <div className="mx-10 my-12 bg-white">
        <div className="flex flex-col justify-start items-center py-9 h-[400px]">
          <h1 className="font-medium text-lg text-black">
            {" "}
            Please help us create a "Translation glossary" by voting for your
            preferred language
          </h1>
          <p className="mt-1 text-black text-opacity-70 text-sm">
            Here is the list of all available language options.
          </p>
          <Select
            showSearch
            className="mt-3 w-1/2 focus:outline-none focus:border-[#000]"
            placeholder={
              language
                ? `Your already selected language:${language}`
                : "Select your preferred language for translation"
            }
            optionFilterProp="label"
            onChange={onValueChange}
            onSearch={onValueChange}
            options={languageOptions}
          />
          <button
            className="primary px-2 py-1 mt-4"
            onClick={handlePostLanguage}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Translation;
