import { Box } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom"
import Appbar from "../components/common/AppBar";
import SideNav from "../components/common/SideNav";


const Main = ({setTotalCredits,totalCredits,changeCredits}) => {
  const navigate = useNavigate()
  const token = localStorage.getItem("token")

  const checkLogin = () => {
    // console.log()
    if (token && token!=='null') {
     return true
    }
    else{
    return false
    }
  }

  React.useEffect(() => {
    var isLogin = true
    isLogin = checkLogin()
    // console.log(isLogin)
    !isLogin && navigate('/login') 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box  sx={{width:"100%",display:"flex",position:"relative"}}>
      <Box className="xs:w-[0%] sm:w-[0%] md:w-[0%] lg:w-[20%]" sx={{position:"relative"}}>
        <SideNav  setAllCredits={setTotalCredits}  totalCredits={totalCredits} changeCredits={changeCredits}/>
      </Box>
      <Box  className="md:w-[98%] lg:w-[80%]" sx={{ backgroundColor: "",position:"relative",mr:"0px" }}>
        {/* <Appbar setAllCredits={setTotalCredits}  totalCredits={totalCredits} changeCredits={changeCredits} /> */}
        <Outlet/>
      </Box>
    </Box>
  );
};

export default Main;
