import { Container } from '@mui/material'
import React from 'react'
import Index from '../../../components/Organization/Billing/credits/Credits'
import Header from './Header'


const Billing = ({credits, setCredits, value, setValue} ) => {
  return (
    <>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // gap: "4vh",
        py:"20px"
      }}
    >
      <Header />
    <Index credits={credits} setCredits={setCredits} value={value} setValue={setValue} />
    </Container>
    </>
  )
}

export default Billing