import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Typography, TextField, Button} from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import AddEmojiCard from '../../components/Administration/AddEmojiCard/AddEmojiCard';
import swal from "sweetalert";

const EditAvatare = () => {
  /* eslint eqeqeq: 0 */
    // const [botId, setBotId] = React.useState(1);
    const [BotData, setBotData] = React.useState({
      BotId: "",
      BotName: "",
      BotDescription: "",
      emojiS3Link: ""
    })
    const Navigate = useNavigate()
    const {state} = useLocation()
    const { botData } = state

    React.useEffect(() => {
      if(botData){
        setBotData(botData)
      }
      
    }, [botData])
    
    
    const HandleNextClick = () => {
      let avatareName = document.getElementById("avatareName").value;
      let description = document.getElementById("description").value;
      // API Call for avatare creation
      if (avatareName != "") {
        const formData = new FormData();
        console.log("Bot id is: ", botData.BotId)
        formData.append("BotId", BotData.BotId);
        formData.append("BotName", avatareName)
        formData.append("BotDescription", description);
        // console.log("Body is: ", formData)
        fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/`, {
          method: "PUT",
          body: formData,
          cache: "default",
          headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
        })
          .then(function (response) {
            console.log(response)
            var status = Promise.resolve(response)
            if (response.status == 400 || response.status == 500) {
              swal({
                title: "Oh Snap!",
                text: "Bot of this name already exists!",
                icon: "error",
                button: "Dismiss!",
              });
            }
              else{
                swal({
                  title: "Success!",
                  text: "Bot has been updated!",
                  icon: "success",
                  button: "Dismiss!",
                });
                Navigate("/admin/createAvatar")
              }
            return response.json();
          })
          .then(function (data) {
            console.log("Bot ID is: ", data.message);
            
          })
          .catch((error) => console.error("Error:", error));
      } else {
        console.log("Name is null");
        swal({
          title: "Oh Snap!",
          text: "Avatare name can not be null.",
          icon: "error",
          button: "Dismiss!",
        });
      }
    };
    return (
      <React.Fragment>
        <CssBaseline />
        <Container
          fixed
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // margin:"3vw"
          }}
        >
          <BreadcrumbsComponent Left="Administration" Right="Create Avatare" />
          <Typography fontWeight={"bold"} align={"left"} mt={3} fontSize={18}>
            Updated Avatare Name
          </Typography>
          <TextField
            id="avatareName"
            variant="outlined"
            placeholder={BotData.BotName}
            sx={{
              bgcolor: "#E1F4FF",
              height: "100%",
              width: "100%",
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderStyle: "solid",
              borderRadius: 1,
              borderWidth: "1px",
              borderColor: "lightgrey!important",
            }}
            fullWidth
          />
  
          <Typography fontWeight={"bold"} align={"left"} mt={3} fontSize={18}>
            Add New Description
          </Typography>
          <TextField
            id="description"
            variant="outlined"
            placeholder={BotData.BotDescription}
            sx={{
              bgcolor: "#E1F4FF",
              width: "100%",
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderStyle: "solid",
              borderRadius: 1,
              borderWidth: "1px",
              borderColor: "lightgrey!important",
            }}
            inputProps={{ maxLength: 500 }}
            fullWidth
            multiline
            rows={8}
          />
                    <Typography sx={{display:"flex",justifyContent:"start",fontSize:"10px",fontWeight:"400"}}>Maximaum length is 500 charector</Typography>
          <Typography fontWeight={"bold"} align={"left"} mt={3} fontSize={18}>
            Add New Emoji
          </Typography>
          <AddEmojiCard botId={BotData.BotId} name={BotData.BotName} description={BotData.BotDescription} emojiS3Link={BotData.emojiS3Link} page="editAvatar">  </AddEmojiCard>
          <Button
            onClick={HandleNextClick}
            sx={{
              backgroundColor: "#295598",
              maxWidth:"300px",
              px:"20px",
              my:"20px",
              mx:"auto",
              color: "#ffffff",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#295588",
              },
            }}
          >Save Changes</Button>
        </Container>
      </React.Fragment>
    );
  };

export default EditAvatare