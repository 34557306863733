import React from "react";
import { Container } from "@mui/material";
import SignupComponent from "../../components/Auth/OrgSignup/SignupComponent";

const Signup = () => {
  return (
    <Container sx={{align:"center", mt:"3vh"}}>
      <SignupComponent/>
    </Container>
  );
};

export default Signup;
