import React from 'react'
import { useNavigate } from 'react-router-dom'

export const TopBar = ({text,button,backArrow}) => {
const navigate =useNavigate();
  return (
    <div style={{borderColor:"rgba(0, 0, 0, 0.40)"}} className="flex items-center justify-between py-[25px] px-8 w-full bg-white  border-b-[1px]">
        <div className='flex gap-4 cursor-pointer'> 
             
            {backArrow && <img src="/back_arrow.svg" onClick={()=>navigate(-1)} />}
            <h1 className="text-xl font-medium text-black">{text}</h1>
        </div>
       {/* {button && <button onClick={()=>navigate("/admin/createNewAvatar")} className="primary text-white py-2 px-4 rounded-[4px]  cursor-pointer transition duration-200">
                + Create New Avatare
        </button>} */}

    </div>
  )
}
