import { Typography, Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";


const DataBoxes = () => {
  const [boxData , setBoxData ] =useState({})
  const token = localStorage.getItem("token")
  
  const fetchData = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/super-admin/getrevenuedetails/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setBoxData(data);
        } else {
            console.log('Error:', response.status);
        }
    } catch (error) {
        console.log('Error:', error.message);
    }
};

useEffect(() => {
    fetchData();
}, []);
console.log(boxData, "boxData");

  return (
    <>
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:"70px",mb:"50px"}}>
    <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column",mx:"10px"}} >
      <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "black",py:"10px"}}>
      {boxData?.message?.total_organizations}
      </Typography>
      <Typography sx={{pb:"10px"}}>
        Total Organization
      </Typography>
    </Box>
    <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column",mx:"10px"}} >
      <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "black",py:"10px"}}>
        {boxData?.message?.total_revenue?.toLocaleString("en-US", {style:"currency", currency:"USD"})}
      </Typography>
      <Typography sx={{pb:"10px"}}>
        Total revenue
      </Typography>
    </Box>
    <Box sx={{ backgroundColor: "#FAFAFA", p: "15px 35px", border: "1px solid rgba(0, 0, 0, 0.35)", borderRadius: "8px", textAlign: "center", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column",mx:"10px"}} >
      <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "black",py:"10px"}}>
        {boxData?.message?.free_credits?.toLocaleString()}
      </Typography>
      <Typography sx={{pb:"10px"}}>
        Total free credits
      </Typography>
    </Box>

    </Box>
    </>
  );
};
export default DataBoxes;
