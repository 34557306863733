import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const MyAvatarCard = ({
  backGround,
  number,
  title,
  date,
  chatData,
  index,
  heading,
  handleDelete,
}) => {
  console.log(chatData);

  const [isOpen, setIsOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const popupRef = useRef();

  const togglePopup = (index) => {
    setClickedIndex(index);
    setIsOpen(!isOpen);
  };

  const toggleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        toggleClose();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const generateExcel = (xlsx) => {
    const link = document.createElement("a");
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsx}`;
    link.download = "templated_output.xlsx";
    link.click();
  };

  const downloadFile = (filename, content, mimeType) => {
    const link = document.createElement("a");
    link.href = `data:${mimeType};base64,${content}`;
    link.download = filename;
    link.click();
  };

  const generate = (pdf, docx) => {
    downloadFile("Summary.pdf", pdf, "application/pdf");
    downloadFile(
      "Summary.docx",
      docx,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );
  };
  const navigate = useNavigate();
  return (
    <div className="min-w-full  w-fit bg-white shadow-md rounded-md border border-[#00000026]  hover:bg-gray-100 cursor-pointer">
      <div className="p-4 flex max-xl:h-full items-center justify-between gap-4">
        <div className="flex items-center justify-center gap-4">
          <div
            style={{ background: backGround }}
            className="text-lg flex items-center justify-center font-bold w-[34px] h-[34px] text-white rounded-full"
          >
            {number}
          </div>
          <div className="flex flex-col  text-start justify-start">
            <div className="text-black font-medium">{title}</div>
            <div className="text-sm  text-gray-500">
              Avatare will be based on templates
            </div>
          </div>
        </div>
        <div className="flex flex-col  text-center items-center justify-center ml-[4%]">
          <div
            style={{ color: "rgba(0, 0, 0, 0.70)" }}
            className="text-sm font-normal"
          >
            Created on:
          </div>
          <div className="text-sm font-medium">{date.split("T")[0]}</div>
        </div>
        <button
          style={{
            background: chatData.is_last
              ? "linear-gradient(90deg, #7FB04F 0%, #497964 100%)"
              : "linear-gradient(90deg, #6CA6C4 0%, #20556E 100%)",
          }}
          className="rounded-[16px] px-2 ml-5 text-white"
        >
          {chatData.is_last ? "completed" : "Incomplete"}
        </button>

        {chatData.is_last ? (
          <div className="flex items-center justify-end w-[40%] gap-6 ml-auto mr-4">
            <div
              style={{ color: "rgba(0, 0, 0, 0.70)" }}
              onClick={() =>
                downloadFile(
                  "Summary.pdf",
                  chatData?.response?.pdf,
                  "application/pdf"
                )
              }
              className="flex gap-2 font-medium items-center"
            >
              <img src="/pdf-icon.svg" width={25} alt="icon" />
              <button className="underline underline-offset-4">
                Download Pdf
              </button>
            </div>
            <div
              style={{ color: "rgba(0, 0, 0, 0.70)" }}
              onClick={() =>
                downloadFile(
                  "Summary.docx",
                  chatData?.response?.docx,
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                )
              }
              className="flex gap-2 font-medium items-center"
            >
              <img src="/doc-icon.svg" width={25} alt="icon" />
              <button className="underline underline-offset-4">
                Download Doc
              </button>
            </div>
            <div
              style={{ color: "rgba(0, 0, 0, 0.70)" }}
              onClick={() => generateExcel(chatData?.response?.xlsx)}
              className=" flex gap-2 font-medium items-center"
            >
              <img src="/excel-icon.svg" width={25} alt="icon" />
              <button className="underline underline-offset-4">
                Download Xlsx
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-end w-[40%] gap-6 ml-auto mr-[7%]">
            <button
              onClick={() =>
                navigate("/sequential-startup/test-template-avatare", {
                  state: {
                    heading: heading,
                    title: title,
                    index: index,
                    data: chatData,
                    page: "history",
                  },
                })
              }
              className="secondary px-3 py-2"
            >
              Resume Template
            </button>
          </div>
        )}
        <div className="relative">
          <img
            src="/threeIcon.svg"
            className="w-[8px]"
            onClick={(e) => {
              e.stopPropagation();
              togglePopup(index);
            }}
          />
          {isOpen && clickedIndex === index && (
            <div
              ref={popupRef}
              className="absolute right-0 w-[120px] bg-white rounded-md border border-gray-300 shadow-lg"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(chatData.session_id);
                }}
                className="flex gap-2 p-4 cursor-pointer justify-start items-center"
              >
                <img src="/delete.svg" className="w-5" />
                <p className="font-medium text-lg text-black ">
                  Delete
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
