import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const NoOfSessionChart = ({ BotId,selectedOption, filteredData }) => {
  // const [chartData, setChartData] = useState({});
  // const [filteredData, setFilteredData] = useState({});
  // const token = localStorage.getItem('token');

  // console.log('session chart data:', chartData);

  // const fetchData = async () => {
  //   try {
  //     let url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivitysessiongraph/?BotId=${BotId}`;
  //     if (selectedOption === '7days') {
  //       url += '&days=7';
  //     } else if (selectedOption === '1month') {
  //       url += '&days=30';
  //     }

  //     const response = await fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.ok) {
  //       const dataa = await response.json();
  //       setFilteredData(dataa);
  //     } else {
  //       console.log('Error:', response.status);
  //     }
  //   } catch (error) {
  //     console.log('Error:', error.message);
  //   }
  // };

  // React.useEffect(() => {
  //   fetchData();
  // }, [selectedOption]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
        // display:"none"
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
        position: 'bottom',
      },
    },
  };

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const data = {
    labels: filteredData?.message?.Dates,
    datasets: [
      {
        data: filteredData?.message?.Sessions_Count?.map((faker) => faker),
        backgroundColor: 'green',
      },
    ],
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', px: '10px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Gordita' }}>
          Number of Sessions
        </Typography>
      </Box>
      <Line options={options} data={data} />
    </>
  );
};

export default NoOfSessionChart;
