import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
    import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';

const NoOfQuestionChart = ({chartData}) => {

      // const [chartData , setChartData] = useState({})
      // const token = localStorage.getItem('token');
    
      // const fetchData = async () => {
      //   try {
      //     let url = `${process.env.REACT_APP_API_ROOT}/api/bot/useractivityquestiongraph/?BotId=${BotId}`;
      //     if (selectedOption === '7days') {
      //       url += '&days=7';
      //     } else if (selectedOption === '1month') {
      //       url += '&days=30';
      //     }
    
      //     const response = await fetch(url, {
      //       method: 'GET',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`,
      //       },
      //     });
    
      //     if (response.ok) {
      //       const dataa = await response.json();
      //       setChartData(dataa);
      //     } else {
      //       console.log('Error:', response.status);
      //     }
      //   } catch (error) {
      //     console.log('Error:', error.message);
      //   }
      // };
      
      // React.useEffect(() => {
      //   fetchData();
      // }, [selectedOption]);

      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
   const options = {
  scales: {
      x: {
        grid: {
          display: true,
        },
      },

        },
        responsive: true,
        plugins: {
        legend: {
          position: 'bottom',
          display: false,
          // display:"none"
        },
        title: {
          display: false,
          text: 'Chart.js Bar Chart',
          position:"bottom"
        },
      },
    };
    
    const data = {
      labels: chartData?.message?.Dates,
      datasets: [
        {
          data: chartData?.message?.Questions_Count?.map((faker) => faker),
          backgroundColor: 'green',
        },
      ],
    };

  return (
    <>
           <Box sx={{display:"flex",justifyContent:"space-between",px:"10px"}}>
        <Typography sx={{fontSize:"18px",fontWeight:"500",fontFamily:"Gordita"}}>
            Number of Questions
        </Typography>
        {/* <Typography sx={{fontSize:"18px",fontWeight:"500",fontFamily:"Gordita",mx:"10px"}}>
            {chartData?.message?.Questions_Count?.length}
        </Typography> */}
    </Box>
    <Line options={options} data={data} />
    </>
  )
}

export default NoOfQuestionChart