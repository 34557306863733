import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import styles from "./ProfileDetailForm.module.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileDetailForm = () => {
  const Id = localStorage.getItem("UserId")
  const token = localStorage.getItem("token")
  const [selectedImageFile, setSelectedImageFile] = useState(null); // Store the file, not the URL
  const [selectedImage, setSelectedImage] = useState(null); // Store the file, not the URL
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleImageSelect = (event) => {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImageFile(imageFile); // Store the file
    setSelectedImage(imageUrl)

  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append("Id", Id);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (selectedImageFile) {
      formData.append("pic", selectedImageFile);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/profile/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cache: "default",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

    
  return (
    <div className={styles.outermostDiv}>
      <Box className={styles.contentBox}>
        <FormControl color={"primary"}>
          <Typography className={styles.textLabels}>First Name</Typography>
          <TextField
            id="my-input"
            className={styles.textField}
            aria-describedby="my-helper-text"
            required
            onChange={(e)=>setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl color={"primary"}>
          <Typography className={styles.textLabels}>Last Name</Typography>
          <TextField
            id="my-input"
            className={styles.textField}
            aria-describedby="my-helper-text"
            required
            onChange={(e)=>setLastName(e.target.value)}
          />
        </FormControl>
      </Box>
      <Box className={styles.avatare}>
        {selectedImageFile ? (
          <img
            src={selectedImage}
            alt="Selected Profile"
            className={styles.accountCircleIcon}
          />
        ) : (
          <label htmlFor="image-input">
            <AccountCircleIcon className={styles.accountCircleIcon} />
          </label>
        )}
        <input
          type="file"
          id="image-input"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageSelect}
        />
        <Button className={styles.updateButton} onClick={handleUpdateProfile}>
          Update
        </Button>
        </Box>
    </div>
  );
};

export default ProfileDetailForm;
