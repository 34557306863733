import React, { useEffect, useRef, useState } from "react";

import CreateFolderModal from "../../components/Library/CreateFolderModal/CreateFolderModal";

import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { TopBar } from "../../components/CommonComponent/Topbar";
import FolderComponent from "../../components/Library/FolderView/FolderComponent";
import { message } from "antd";

const DataFiles = () => {

  let { id ,name} = useParams();
  
  const hiddenFileInput = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showFolders, setShowFolders] = useState(true);
  const [folderName, setFolderName] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [createFolder, setCreateFolder] = useState("Folder Name");
  const [dataFolders, setDataFolders] = useState({ message: [] }); 
  const [fileFolders, setFileFolders] = useState([]); 
  const [manualFolders, setManualFolders] = useState([]); 
  const [scoreFolders, setScoreFolders] = useState([]); 
  const [dataFiles, setDataFiles] = useState({ message: [] });
  const [currentDataId, setCurrentDataId] = useState(1);

  const [questions, setQuestions] = useState([]);
  const [folderPrompt, setFolderPrompt] = useState("");
  const [answers, setAnswers] = useState([]);
  const [botId, setBotId] = useState(186);
  const [dataId, setDataId] = useState("");
  const [rerender, setRerender] = useState(true);
  const [openDefaultCreationModal, setOpenDefaultCreationModal] =
    useState(false);
  const [isScore, setIsScore] = useState(false);
  const [buttonText, setbuttonText] = useState("");
  const [heading, setHeading] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [editData, setEditData] = useState({});
  const [editTrue, setEditTrue] = useState(false);

  const userId = localStorage.getItem("UserId");
  const [UserId, setUserId] = useState(userId);

  // Hooks
  var navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    getData().then((items) => {
        if (Array.isArray(items.message)){
            console.log(items.message)
            setFileFolders(items.message);
        }
       
    
    });
    return () => (mounted = false);
  }, [id]);


  const getData = async () => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/data/upload/?DataId=${id}`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };
  


  const handleNewClick = (text, placeholder, heading, buttonText) => {
   navigate(`/library/dataFiles/file-upload/${id}`)
  };

 
  const createFolderButton = () => {
    let folderType;
    if (folderName.trim() === "") {
      message.error("Folder name cannot be empty");
      return;
    }
    if (!editTrue) {
      if (folderPrompt === "Uploaded Files") {
        folderType = "Files";
      } else {
        folderType = "Manual";
      }
      let body = {
        BotId: botId,
        Name: folderName,
        UserId: UserId,
        FolderType: folderType,
      };

      fetch(`${process.env.REACT_APP_API_ROOT}/api/data/createDataset/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(body),
        cache: "default",
      })
        .then(function (response) {
          console.log(response);
          if (response.status === 200 || response.status == 201) {
            message.success("Folder has been created!");
            return response.json();
          } else {
            message.error(
              "Folder with this name already exists or it is invalid"
            );
          }
        })
        .then(function (data) {
          setShowModal(false);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      let body = {
        DataId: editData.DataId,
        BotId: editData.BotId,
        Name: folderName,
        FolderType: editData.FolderType,
      };
      fetch(`${process.env.REACT_APP_API_ROOT}/api/data/createDataset/`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(body),
        cache: "default",
      })
        .then(function (response) {
          console.log(response);
          if (response.status === 200 || response.status == 201) {
            message.success("Folder Name has been updated!");
            return response.json();
          } else {
            message.error(
              "Folder with this name already exists or it is invalid"
            );
          }
        })
        .then(function (data) {
          setShowModal(false);
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  const uploadFile = async (fileUploaded, fileName, api) => {
    if (isScore) {
      const formData = new FormData();
      formData.append("file", fileUploaded);
      formData.append("DataId", currentDataId);
      try {
        // Uploading file to server
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}/api/data/importchat/`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.status == 400 || response.status == 401) {
          swal({
            title: "Oh Snap",
            text: "File has not been uploaded due to format issues or lack of relevant data!",
            icon: "error",
            button: "Dismiss!",
          });
          setIsScore(true);
          return true;
        } else {
          const result = await response.json();
          // console.log("Result of first API call is: ", result.message)
          if (result.message.constructor == Object) {
            // Only works when upload is successful i.e. status=200 or 201
            // Preparing data for sending parsed QAs to server
            setQuestions(result.message.Question);
            setAnswers(result.message.Answer);
            setDataFiles({ message: [...dataFiles.message, result.message] });
            let body = {
              DataId: result.message.DataId,
              SourceId: result.message.SourceId,
              Question: result.message.Question,
              Answer: result.message.Answer,
            };
            // Sending parsed QAs to server
            const response = await fetch(
              `${process.env.REACT_APP_API_ROOT}/api/data/addQA/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "*/*",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(body),
              }
            );
            const resultSecondEndpoint = await response.json();
            console.log(resultSecondEndpoint);
            navigate("/classroom/QuestionsAndAnswers", {
              state: {
                name: result.message.SourceName,
                description: "description",
                currentDataId: result.message.DataId,
                currentSourceId: result.message.SourceId,
                questions: result.message.Question,
                answers: result.message.Answer,
              },
            });
            swal({
              title: "Successful",
              text: "File has been uploaded!",
              icon: "success",
              button: "Dismiss!",
            });
          } else {
            setDataFiles({ message: [...dataFiles.message, result.message] });
            swal({
              title: "Successful",
              text: "File has been uploaded!",
              icon: "success",
              button: "Dismiss!",
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // Preparing data to upload the file to server through API
      const formData = new FormData();
      console.log(currentDataId);
      formData.append("DataId", currentDataId);
      formData.append("SourceFile", fileUploaded);
      formData.append("SourceName", fileName);
      try {
        // Uploading file to server
        const response = await fetch(api, {
          method: "POST",
          body: formData,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.status == 400 || response.status == 401) {
          swal({
            title: "Oh Snap",
            text: "File has not been uploaded due to format issues or lack of relevant data!",
            icon: "error",
            button: "Dismiss!",
          });
          setIsScore(true);
          return true;
        } else {
          const result = await response.json();
          // console.log("Result of first API call is: ", result.message)
          if (result.message.constructor == Object) {
            // Only works when upload is successful i.e. status=200 or 201
            // Preparing data for sending parsed QAs to server
            setQuestions(result.message.Question);
            setAnswers(result.message.Answer);
            setDataFiles({ message: [...dataFiles.message, result.message] });
            let body = {
              DataId: result.message.DataId,
              SourceId: result.message.SourceId,
              Question: result.message.Question,
              Answer: result.message.Answer,
            };
            // Sending parsed QAs to server
            const response = await fetch(
              `${process.env.REACT_APP_API_ROOT}/api/data/addQA/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "*/*",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(body),
              }
            );
            const resultSecondEndpoint = await response.json();
            console.log(resultSecondEndpoint);
            navigate("/classroom/QuestionsAndAnswers", {
              state: {
                name: result.message.SourceName,
                description: "description",
                currentDataId: result.message.DataId,
                currentSourceId: result.message.SourceId,
                questions: result.message.Question,
                answers: result.message.Answer,
              },
            });
            swal({
              title: "Successful",
              text: "File has been uploaded!",
              icon: "success",
              button: "Dismiss!",
            });
          } else {
            swal({
              title: "We are sorry",
              text: result.message,
              icon: "error",
              button: "Dismiss!",
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (createFolder == "Scores And Ratings") {
      uploadFile(
        fileUploaded,
        fileUploaded.name,
        `${process.env.REACT_APP_API_ROOT}/api/bot/importchat/`
      );
    } else {
      uploadFile(
        fileUploaded,
        fileUploaded.name,
        `${process.env.REACT_APP_API_ROOT}/api/data/upload/`
      );
    }
  
  };

  const handleClick = () => {
    console.log(hiddenFileInput.current);
    hiddenFileInput.current.click();
  };

 

  const handleEditClick = (data) => {
    console.log(data);
    setEditData(data);
    setbuttonText("Update Folder");
    setFolderName(data.Name);
    setPlaceholder("Edit folder name");
    setHeading("Edit Your Folder Name");
    setShowModal(true);
    setEditTrue(true);
  };
  const handleDeleteClick = async (data) => {
    console.log(data)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/createDataset/?DataId=` +
          data.DataId,
        {
          method: "DELETE",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      const result = await response.json();
      if (response.status != 200 && response.status != 201) {
        message.error("Folder could not be deleted!");
      } else {
        setRerender(!rerender);
        message.success("Folder has been deleted!");
      }
    } catch (e) {
      console.log(e);
    }
  };





  return (
    <div className="w-full">
      <TopBar backArrow={true} text={name} />
      <div className="mx-12 my-5 px-6 py-5 h-auto bg-white rounded-sm">
        <FolderComponent
          data={fileFolders}
          newIcon={"/new-file-icon.svg"}
          fileIcon={"/file-icon.svg"}
          cardInnertext={"Upload New File"}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          text="Uploaded Files"
          subText="Here is the list of all uploaded files."
          buttonText="Upload New File"
          showButton={true}
          showLine={false}
          handleNewClick={handleNewClick}
        />
      
      
       
      </div>
    </div>
    // <React.Fragment>
    //   <CssBaseline />
    //   <Container
    //     // fixed
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       textAlign:"center"
    //       // margin: "3vw",
    //     }}
    //   >
    //     <BreadcrumbsComponent Left="Library" Right="Data Files" botId={botId} setBotId={setBotId} setUserId={setUserId} UserId={UserId} />

    //     {showFolders ? <>{DataFolderScreen()}</> : <>{DataFilesScreen()}</>}
    //   </Container>
    //   <CreateFolderModal
    //     botId={botId}
    //     open={showModal}
    //     setOpen={setShowModal}
    //     files={dataFolders}
    //     setFiles={setDataFolders}
    //     createFolderPrompt={createFolder}
    //     setCurrentDataId={setCurrentDataId}
    //   />
    //   <EditFolderModal
    //     botId={botId}
    //     dataId={dataId}
    //     open={showEditModal}
    //     setOpen={setShowEditModal}
    //     files={dataFolders}
    //     setFiles={setDataFolders}
    //     createFolderPrompt={"Edit Folder Name"}
    //     setCurrentDataId={setCurrentDataId}
    //   />
    //   <CreateDefaultModal
    //     botId={botId}
    //     dataId={dataId}
    //     open={openDefaultCreationModal}
    //     setOpen={setOpenDefaultCreationModal}
    //     files={dataFolders}
    //     setFiles={setDataFolders}
    //     createFolderPrompt={"Add Default Questions"}
    //     setCurrentDataId={setCurrentDataId}
    //     />
    // </React.Fragment>
  );

  // function DataFolderScreen() {
  //   return (
  //     <div>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //           flexWrap: "wrap",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           My uploaded content
  //         </Typography>
  //         <Button
  //           variant="contained"
  //           size="large"
  //           startIcon={<CreateNewFolderIcon />}
  //           onClick={handleFolderCreation}
  //           sx={{
  //             backgroundColor: "#295598",
  //             fontWeight: "bold",
  //             color: "white",
  //             "&.MuiButtonBase-root:hover": {
  //               bgcolor: "#295598",
  //             },
  //           }}
  //         >
  //           Create new folder
  //         </Button>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "100%",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {fileFolders &&
  //           fileFolders.map((file) => {

  //              return <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               setDataId={setDataId}
  //               setFiles={setDataFiles}
  //               showFolders={showFolders}
  //               setShowFolders={setShowFolders}
  //               setShowEditModal={setShowEditModal}
  //               setCurrentDataId={setCurrentDataId}
  //               setCurrentFolderName={setCurrentFolderName}
  //               questions={questions}
  //               answers={answers}
  //               implementChecks= {false}
  //               manualResponses={false}
  //               rerender={rerender}
  //               setRerender={setRerender}
  //               isScore={false}
  //               file={file}
  //             />
  // })}
  //         <CreateNew handleFolderCreation={handleFolderCreation} />
  //       </Box>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           Manually Created Responses
  //         </Typography>
  //         <Button
  //           variant="contained"
  //           size="large"
  //           startIcon={<CreateNewFolderIcon />}
  //           onClick={handleManualCreation}
  //           sx={{
  //             backgroundColor: "#295598",
  //             fontWeight: "bold",
  //             color: "white",
  //             "&.MuiButtonBase-root:hover": {
  //               bgcolor: "#295598",
  //             },
  //           }}
  //         >
  //           Create Manual Responses
  //         </Button>
  //         <Button
  //           variant="contained"
  //           size="large"
  //           startIcon={<CreateNewFolderIcon />}
  //           onClick={handleDefaultCreation}
  //           sx={{
  //             backgroundColor: "white",
  //             fontWeight: "bold",
  //             color: "#295598",
  //             "&.MuiButtonBase-root:hover": {
  //               bgcolor: "white",
  //             },
  //           }}
  //         >
  //           Create Default Responses
  //         </Button>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "100%",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {manualFolders &&
  //           manualFolders.map((file) => (
  //             <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               setFiles={setDataFiles}
  //               showFolders={showFolders}
  //               setShowFolders={setShowFolders}
  //               setCurrentDataId={setCurrentDataId}
  //               setCurrentFolderName={setCurrentFolderName}
  //               manualResponses={true}
  //               rerender={rerender}
  //               setRerender={setRerender}
  //               isScore={false}
  //             />
  //           ))}
  //         <CreateNew handleFolderCreation={handleManualCreation} />
  //       </Box>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           alignItems:"center",
  //           my: 2,
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           Scores And Ratings
  //         </Typography>
  //         <Button
  //         variant="contained"
  //         size="large"
  //         endIcon={<GetAppIcon />}
  //         sx={{
  //           mt: 0,
  //           width:"fit-content",
  //           padding:2,
  //           backgroundColor: "white",
  //           fontSize:14,
  //           fontWeight: "bold",
  //           color: "#295598",
  //           position:"relative",
  //           float: "left",
  //           "&.MuiButtonBase-root:hover": {
  //             bgcolor: "white",
  //           },
  //         }}
  //         onClick={createNewScoresFolder}>
  //           Create New Folder
  //         </Button>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "100%",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {scoreFolders &&
  //           scoreFolders.map((file) => (
  //             <FileCard
  //               name={file.Name}
  //               DataId={file.DataId}
  //               setFiles={setDataFiles}
  //               showFolders={showFolders}
  //               setShowFolders={setShowFolders}
  //               setCurrentDataId={setCurrentDataId}
  //               setCurrentFolderName={setCurrentFolderName}
  //               manualResponses={false}
  //               setIsScore={setIsScore}
  //               isScore={false}
  //               rerender={rerender}
  //               setRerender={setRerender}
  //             />
  //           ))}
  //         <CreateNew handleFolderCreation={createNewScoresFolder} variant={"Scores"}/>
  //       </Box>
  //     </div>
  //   );
  // }
  // function DataFilesScreen() {
  //   return (
  //     <div>
  //       <Container
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           my: 2,
  //           flexWrap: "wrap",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontWeight: "bold",
  //             fontSize: "24px",
  //           }}
  //         >
  //           {currentFolderName}
  //         </Typography>
  //         <Button
  //           variant="contained"
  //           size="large"
  //           startIcon={<CreateNewFolderIcon />}
  //           onClick={(e) => handleClick(e)}
  //           sx={{
  //             backgroundColor: "#295598",
  //             fontWeight: "bold",
  //             color: "white",
  //             "&.MuiButtonBase-root:hover": {
  //               bgcolor: "#295598",
  //             },
  //           }}
  //         >
  //           <input
  //             type="file"
  //             ref={hiddenFileInput}
  //             style={{ display: "none" }}
  //             onChange={handleChange}
  //             accept=".pdf,.csv,.docx,.txt,.xlsx,.xlsm"
  //           />
  //           Upload New file
  //         </Button>
  //       </Container>
  //       <Box
  //         sx={{
  //           bgcolor: "#E1F4FF",
  //           height: "40vh",
  //           width: "100%",
  //           my: 5,
  //           display: "flex",
  //           alignItems: "left",
  //           flexDirection: "row",
  //           flexWrap: "wrap",
  //           overflow: "auto",
  //         }}
  //       >
  //         {dataFiles.message &&
  //           dataFiles.message.map((file) => (
  //             <FileCard
  //               name={file.SourceName}
  //               showFolders={showFolders}
  //               DataId={file.SourceId}
  //               currentDataId={file.DataId}
  //               rerender={rerender}
  //               setRerender={setRerender}
  //               isScore={true}
  //             />
  //           ))}
  //       </Box>
  //       <Box
  //         align={"left"}
  //         sx={{
  //           position: "absolute",
  //           bottom: "0px",
  //           display:"flex",
  //           alignItems:"center",
  //           gap:"10px",
  //           cursor:"pointer"
  //         }}
  //         onClick={() => setShowFolders(true)}
  //       >
  //         <KeyboardBackspaceIcon sx={{ fontSize: 50, color: "#295598",px:"8px" }} />
  //         <Typography
  //           fontSize={"24px"}
  //           fontWeight={500}
  //           fontFamily={"Gordita"}
  //           color={"#295598"}
  //         >
  //           Back
  //         </Typography>
  //       </Box>
  //     </div>
  //   );
  // }
};

export default DataFiles;
