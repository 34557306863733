import React, { useEffect, useState } from "react";
import { TopBar } from "../../../components/CommonComponent/Topbar";
import { MyAvatarCard } from "../../../components/MyAvatarNew/MyAvatarCard";
import { message, Pagination } from "antd";

const TemplateHistory = () => {
  const [history, setHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [render,setRender]=useState(false);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_ROOT + `/api/ppt/delete-history/${id}`, {
        method: 'DELETE',
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("token")
        },
      });      
      if (!response.ok) {
        message.error("Failed to delete the record")
        throw new Error('Failed to delete the record');
      
      }
      message.success("Successfully deleted")
      setRender(!render);

    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getData().then((data) => {
      console.log(data);
      setCount(data?.total);
      setHistory(data?.history);
    });
  }, [page,render]);

  const onPageChange = (page) => {
    setPage(page);
  };

  const getData = () => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/ppt/get-history/${page}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    ).then((data) => data.json());
  };
  return (
    <div>
      <TopBar text="Templates History" />
      <div className="py-5 px-8">
        <div>
          <h1 className="text-xl font-medium mt-2">Template based Avatare</h1>
          <div className="mt-3.5">
            <div className="flex gap-5 m-w-[50%] flex-wrap">
              {history &&
                history.map((historyItem, index) => (
                  <MyAvatarCard
                    key={index}
                    title={historyItem.section_name}
                    number={"0"+historyItem.section_no.split(".")[1]}
                    backGround="linear-gradient(180deg, #7FB04F 0%, #497964 100%)"
                    heading={"Nail the Pain: Spark & Explore"}
                    chatData={historyItem}
                    date={historyItem.created_at}
                    handleDelete={handleDelete}

                  />
                ))}
            </div>
          </div>
         
            <div className="flex items-center justify-center py-4 mb-4">
            {!!history && (<Pagination
                current={page}
                pageSize={10}
                total={count}
                onChange={onPageChange}
              />)}
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default TemplateHistory;
