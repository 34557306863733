import React from 'react';
import { Widget, addResponseMessage, } from 'react-chat-widget';
import "./ChatWidget.css";

const ChatWidget = ({BotId}) => {


  const handleNewUserMessage = (newMessage) => {
    let body = {
        BotId: BotId,
        Question: newMessage
    }
  fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/query/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    body: JSON.stringify(body),
    cache: "default",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
        console.log(data.message);
        addResponseMessage(data.message);
  })
}


  return (
    <div className='flex items-center justify-center w-24'  >
      <Widget
        className="bg-green"
        handleNewUserMessage={handleNewUserMessage}
        title="Conversational AI"
        color="red"
        subtitle="Lets talk!"
      />
    </div>
  );
}

export default ChatWidget;