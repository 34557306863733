import React, { useState } from "react";
import styles from "./VideoCardForPlaylist.module.css";

const VideoCardForPlaylist = ({
  index,
  videoName,
  setVideoName,
  url,
  setUrl,
  transcript,
  setTranscript,
  activeCard, setActiveCard
}) => {
  const [cardStyle, setCardStyle] = useState(styles.cardOutermost);

 
  const handleClick = (index) => {
    setUrl(url);
    setVideoName(videoName);
    setTranscript(transcript);
    setActiveCard(index)
    console.log(index)
  };
  const handleFocus = () => {
    setCardStyle(styles.cardOutermostClick);
  };
  const handleFocusOut = () => {
    setCardStyle(styles.cardOutermost);
  };
  return (
    <div
      tabIndex={"0"}
      onClick={(e)=>handleClick(index)}
      onFocus={handleFocus}
      key={index}
      onBlurCapture={handleFocusOut}
      className="w-full  rounded-md mt-5 flex gap-2 cursor-pointer"
    >
        {activeCard === index ? <img src="/video-playlist-card-arrow.svg" className="w-6"/>:<h1 className="flex self-center mr-2">{index+1}</h1>}
        <div className="bg-white w-full  rounded-md flex justify-start items-center gap-4">
      <div
        style={{
          background: "linear-gradient(180deg, #9747FF 0%, #4903A3 100%)",
        }}
        className="relative  text-white p-6 rounded-lg w-1/3 min-w-[250px] h-48 flex flex-col justify-between"
      >
        <div>
          <h2 className="text-xl">How to Guide : {videoName}</h2>
        </div>
        <div className="self-end text-lg">4:06</div>
        <div className="absolute inset-0">
          <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-30 h-30 bg-no-repeat ">
            <img src="/right-card-logo.svg" className="w-20" />
          </div>
          <div className="absolute left-16 bottom-0 transform -translate-x-1/2 w-30 h-10 bg-no-repeat ">
            <img src="/avatare-card-logo.svg" className="w-20" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between mt-2 gap-4">
      <h1 className="text-xl font-semibold text-left">How to Guide : {videoName}</h1>
      <p className="mt-12">Avatare</p>
      <div>
    </div>
   

  </div>
  </div>
  </div>
  );
};

export default VideoCardForPlaylist;
