import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Container from "@mui/material/Container";
import styles from "./AddEmojiCard.module.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddEmojiCard = ({ botId, name, desc, emojiS3Link, page, BotEmoji, BotName, BotId }) => {
    /* eslint eqeqeq: 0 */
  const [file, setFile] = React.useState("");
  const [uploadedFile, setUploadedFile] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const avatareName = name;
  const description = desc;
  // const [isSuccess, setIsSuccess] = React.useState(false);

  const navigate = useNavigate();

  const uploadFile = async (newbotId) => {
    // Size validation
    const MIN_FILE_SIZE = 1; // 1KB
    const MAX_FILE_SIZE = 300; // 300KB
    console.log("new botId ", newbotId);

    let success = false;

    if (!uploadedFile) {
      setErrorMsg("Please choose a file");
      return;
    }

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg("File size is less than minimum limit");
      return;
    } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      return;
    } else {
      // setIsSuccess(true);
      success = true;
      // console.log("success")
      setErrorMsg("");
    }
    if (success == true) {
      const formData = new FormData();
      {
        page == "addEmoji" ?
          formData.append("BotId", newbotId)
          :
          formData.append("BotId", botId)
      }
      formData.append("BotEmoji", uploadedFile);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/`, {
          method: "PUT",
          body: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        });
        // console.log(response);
        // console.log(response.status);
        // const result = await response.json();
        // console.log(result);
        if (response.status != 200 && response.status != 201) {
          swal({
            title: "Oh Snap!",
            text: "Emoji could not be uploaded!",
            icon: "error",
            button: "Dismiss!",
          });
        } else {
          swal({
            title: "Success",
            text: "Emoji has been uploaded!",
            icon: "success",
            button: "Dismiss!",
          });
          if(page=== "addEmoji"){
            navigate("/admin/addMyEmoji", {
              state: {
                botId: botId == undefined ? BotId : botId,
                variant: "DataFiles",
                avatareName: name,
                description: desc,
                emoji: file
              }
            })
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const cancelUpload = () => {
    setUploadedFile("")
    setFile("")
    // console.log(description);
    // navigate("/admin/addMyEmoji", {
    //   state: { botId: botId, avatareName: avatareName, description: description },
    // });
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    // console.log("Click");
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // let success = false;

  const handleChange = async (event) => {
    const MIN_FILE_SIZE = 1; // 1KB
    const MAX_FILE_SIZE = 300; // 300KB

    if (!event.target.files[0]) {
      setErrorMsg("Please choose a file");
      // setIsSuccess(false);
      return;
    }

    const fileSizeKiloBytes = event.target.files[0].size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg("File size is less than minimum limit");
      // setIsSuccess(false);
      return;
    } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than 300KB");
      // setIsSuccess(false);
      return;
    } else {
      // setIsSuccess(true);
      // success = true;
      const fileUploaded = event.target.files[0];
      setUploadedFile(fileUploaded);
      setFile(URL.createObjectURL(fileUploaded));
      setErrorMsg("");
    }

    // props.handleFile(fileUploaded);
  };

  // console.log("avatares avatares :", avatares);

  return (
    <>
      {page == "addEmoji" ? (
        <>
          <Card
            // className={styles.outermostCard}
            sx={{ border: "dashed", borderWidth: 1, position: "relative", width: "23%", height: "220px", my: "30px",mx:"10px",textAlign:"center"}}
          >
            <CardMedia className={styles.cardMedia}>
              {file && uploadedFile ?
                <img
                  src={file}
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  alt="Emoji"
                />
                : emojiS3Link ? (
                  <>
                    <img src={emojiS3Link} style={{ width: "55px", height: "55px", objectFit: "cover", borderRadius: "50%" }} />
                  </>
                ) : BotEmoji ? (
                  <>
                    <img src={BotEmoji} style={{ width: "55px", height: "55px", objectFit: "cover", borderRadius: "50%" }} />
                  </>
                ) : (
                  <>
                    <AccountCircleIcon className={styles.AccountCircleIcon} />
                  </>
                )}
            </CardMedia>
            <CardContent sx={{ padding: 0 }}>
              <Typography className={styles.AvatareName}>{BotName}</Typography>
              <Typography fontSize={12}>{description}</Typography>
              <p className="error-message">{errorMsg}</p>
              <Box sx={{ position: "absolute", bottom: "10px",width:"100%" }}>
                {file ? (
                  <>
                    <Container className={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={() => uploadFile(BotId)}
                        sx={{
                          backgroundColor: "#295598",
                          color: "#ffffff",
                          mx:"4px",
                          py:"6px",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "#295588",
                          },
                        }}
                      >
                        Upload File
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={cancelUpload}
                        sx={{
                          backgroundColor: "white",
                          border: "solid",
                          borderColor: "#295598",
                          color: "#295598",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Container>
                  </>
                ) : (
                  <>
                    <Container className={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={handleClick}
                        sx={{
                          backgroundColor: "white",
                          border: "solid",
                          borderColor: "#295598",
                          color: "#295598",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                      >
                        <input
                          type="file"
                          accept=".svg,.png,.jpg"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                        Upload Emoji
                      </Button>
                    </Container>
                  </>
                )}
                {!uploadedFile ? <Typography sx={{ fontSize: "10px", float: "right", mr: 4, mt: 0.5 }}>
                  Max Upload Size: 300 KB
                </Typography> : <></>}
              </Box>

            </CardContent>
          </Card>
        </>
      )
        : (
          <Card
            className={styles.outermostCard}
            sx={{ border: "dashed", borderWidth: 1, position: "relative",textAlign:"center" }}
          >
            <CardMedia className={styles.cardMedia}>
              {file ? (
                <>
                  {/* <embed src={file}  height="54px" width="52px" /> */}
                  <img src={file} style={{ width: "80px", height: "80px", objectFit: "cover", borderRadius: "50%" }} />
                </>
              ) : (
                emojiS3Link ? <><img src={emojiS3Link} style={{ width: "80px", height: "80px", objectFit: "cover", borderRadius: "50%" }} /></> :
                  <>
                    <AccountCircleIcon className={styles.AccountCircleIcon} />
                  </>
              )}
            </CardMedia>
            <CardContent sx={{ padding: 0, }}>
              <Typography className={styles.AvatareName}>{name}</Typography>
              <Typography fontSize={12}>{description}</Typography>
              <p className="error-message">{errorMsg}</p>
              <Box sx={{ position: "absolute", bottom: "10px",width:"100%" }}>
                {file ? (
                  <>
                    <Container className={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={uploadFile}
                        sx={{
                          backgroundColor: "#295598",
                          color: "#ffffff",
                          mx:"4px",
                          py:"6px",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "#295588",
                          },
                        }}
                      >
                        Upload File
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={cancelUpload}
                        sx={{
                          backgroundColor: "white",
                          border: "solid",
                          borderColor: "#295598",
                          color: "#295598",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Container>
                  </>
                ) : (
                  <>
                    <Container className={styles.buttonContainer}>
                      {/* <Button
                  variant="contained"
                  size="small"
                  className={styles.button}
                  sx={{
                    backgroundColor: "#295598",
                    color: "#ffffff",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#295588",
                    },
                  }}
                >
                  Create Emoji
                </Button> */}
                      <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={handleClick}
                        sx={{
                          backgroundColor: "white",
                          border: "solid",
                          borderColor: "#295598",
                          color: "#295598",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                      >
                        <input
                          type="file"
                          accept=".svg,.png,.jpg"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                        Upload Emoji
                      </Button>
                    </Container>
                  </>
                )}
                {!uploadedFile ? <Typography sx={{ fontSize: "10px", float: "right", mr: 4, mt: 0.5 }}>
                  Max Upload Size: 300 KB
                </Typography> : <></>}
              </Box>
            </CardContent>
          </Card>
        )
      }
    </>
  );
};

export default AddEmojiCard;
