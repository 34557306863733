import React from "react";
import contentData from "../../template-detail";

const TemplateDetail = ({ ...rest}) => {
  const content = contentData[rest.number] || {};

  console.log(content)

  return (
    <div className="w-[50%] border-r-[2px]">
      <div className="p-4 pt-8">
        <div className="flex flex-col justify-center">
          <div className="flex items-center  gap-2">
            <div
              style={{ background: rest.backGround, boxShadow: rest.boxShadow }}
              className="text-lg flex items-center justify-center font-bold w-[34px] h-[34px] text-white rounded-full"
            >
              {rest.number}
            </div>
            <div>
              <h2 className="text-lg font-semibold">{rest.title}</h2>
            </div>
          </div>
          <div className="pl-10">
            <div className="bg-[#20556E] w-fit px-3  py-1.5 rounded-[20px] text-center text-white">
              {rest.heading}
            </div>
            <p className="mt-1 font-normal text-wrap">
             {content.description}
            </p>
            {content.goals &&<h2 className="text-lg font-semibold mt-4">Goals</h2>}
            <ul className="font-normal gap-2 flex flex-col pl-4 text-wrap text-left list-disc">
            {content.goals?.map((goal, idx) => (
          <li key={idx}>{goal}</li>
        ))}
      </ul>
             
            {content.keyFeatures && <h2 className="text-lg font-semibold mt-4">Key Features</h2>}
            <ul className="font-normal gap-2 flex flex-col pl-4 text-wrap text-left list-disc">
        {content.keyFeatures?.map((feature, idx) => (
          <li key={idx}>{feature}</li>
        ))}
      </ul>

            {/* <div className="mt-4">
        <h3 className="font-bold mb-4">12 Slides Included:</h3>
        <div className="flex gap-4 px-2 flex-wrap">
          {slides.map((slide, index) => (
            <img key={index} src={`/${slide}`} className='w-[150px]' alt={`Slide ${index + 1}`}  />
          ))}
        </div>
      </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateDetail;
