import React, { useState } from 'react'
import BarChart from './BarChart'
import LineChartComp from './LineChart'
import { Button } from '@mui/material'

const Usage = () => {
  const [charShow,setChartShow] = useState(true)
  const [chartData , setChartData] = useState({})
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');

  const fetchData = async () => {
    // console.log("ahsdjasgfjhsagfhasgfj")
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/payment/creditsused/?UserId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const dataa = await response.json();
        setChartData(dataa);
        // console.log(dataa ,"dtaaaaaaaaaaaaaaaaaa");
      } else {
        console.log('Error:', response.status);
        setChartData({ message: [] })
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  
  React.useEffect(() => {
    fetchData();
  }, []);
  // console.log( chartData.message," : chartData");
// console.log(chartData,"chart Data");
  return (
    <div>
      <Button onClick={()=>setChartShow(!charShow)} sx={{display:"flex",justifyContent:"start",my:"30px"}}>Show {charShow ?"Line":"Bar"} Chart</Button>
      {chartData?.message?.length >= 0 && (
      <>
      {
      charShow ? 
      <BarChart chartData={chartData?.message} />
      :
      <LineChartComp chartData={chartData?.message} />
      }
      </>
      ) }

    </div>
  )
}

export default Usage