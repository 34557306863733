import './App.css';
import { Routes, Route,BrowserRouter } from "react-router-dom";
// import Avatare from "./landing/pages/Avatare"
// import Ecommerce from "./landing/pages/Ecommerce"
// import Playbook from "./landing/pages/PlayBookAI"
import Signup from './screens/Auth/OrgSignup';

import Main from './template/Main';
import AddEmoji from "./screens/Administration/AddEmoji";
import CloneAvatare from "./screens/Administration/CloneAvatare";
import Quickstart from "./screens/Library/Quickstart";
import Folders from "./screens/Library/DataFolders";
import Translation from "./screens/Library/Translation";
import ErrorPage from "./screens/ErrorPage";
import Home from "./screens/Home";
import TrainMyModel from "./screens/Classroom/TrainMyModel";
import TrainBySubject from "./screens/Classroom/TrainBySubject"
import TestMyAvatare from "./screens/Classroom/TestMyAvatare";
import Analytics from "./screens/Operations/Analytics";
import Integrations from "./screens/Operations/Integrations";
import WhisperAI from "./screens/Research/WhisperAI";
import DallE from "./screens/Research/DallE";
import ChatGPT from "./screens/Research/ChatGPT";
import Videos from "./screens/Library/Videos";
import AddMyEmoji from "./screens/Administration/AddMyEmoji";
import ScoreAndRating from "./screens/Classroom/ScoreAndRating";
import QuestionsAndAnswersScreen from "./screens/Library/QuestionsAndAnswers";
import AddNewQuestion from "./screens/Library/AddNewQuestion";
import SelectModel from './screens/Classroom/SelectModel';
import SelectSubjectsToTrain from './screens/Classroom/SelectSubjectsToTrain';
import SelectIntegration from './screens/Operations/SelectIntegration';
import EditAvatare from './screens/Administration/EditAvatare';
import Login from './screens/Auth/OrgLogin';
import Organization from './template/Organization';
import Profile from './screens/Organization/Profile';
import Teams from './screens/Organization/Teams';
import MemberLogin from './screens/Auth/MemberLogin';
import MemberSignup from './screens/Auth/MemberSignup';
import Landing from './screens/Organization/Landing';
// import PublicRoute from './PublicRoute';
// import PrivateRoute from './PrivateRoute';
import LandingTwo from './screens/Organization/LandingTwo';
import Payment from './screens/Organization/Billing/Payment';
import Billing from './screens/Organization/Billing/Billing';
import React, {useState} from "react"
import SuperAdmin from './screens/Organization/SuperAdmin';
import Usage from './screens/Organization/Usage';
import AvatarMonitoringAndControl from './components/Operations/AnalyticsComponents/AvatarMonitoringAndControl';
import TrainigData from './components/Operations/AnalyticsComponents/TrainingData';
import ActivityOverview from './components/Operations/AnalyticsComponents/ActivityOverview';
import PolicyQuestions from './components/Operations/AnalyticsComponents/PolicyQuestions';
import HistoryTable from './screens/Organization/HistoryTable';
import Documentation from './screens/Operations/Documentation';
import Help from './components/common/Help';
import WhisperPlayground from './components/Research/WhisperPlayground';
import Template from './screens/Classroom/Template';
import TestMyAvatareNew from './screens/Classroom/TestMyAvatareNew';
import { BotCustomization } from './screens/BotCustomization/BotCustomization';
import { MyAvatar } from './screens/Administration/MyAvatar';
import { CreateAvatareFromScratch } from './screens/Administration/CreateAvatareFromScratch';
import TemplatesLibrary from './screens/SequentialStartup/TemplateLibrary/TemplatesLibrary';
import TemplateHistory from './screens/SequentialStartup/TemplatesHistory/TemplateHistory';
import DataFiles from './screens/Library/DataFiles';
import NewFileUpload from './components/Classroom/NewFileUpload/NewFileUpload';
import TrainingData from './components/Operations/AnalyticsComponents/TrainingData';



function App() {
  const [credits, setCredits] = useState(20)
  const [totalCredits, setTotalCredits] = useState()
  const [value, setValue] = useState(20)
  const [isOrganizer, setIsOrgnizer] = useState()
  const superAdminEmail = localStorage.getItem("email")
  const [changeCredits,setChangeCredits] = useState(false)

  /* eslint eqeqeq: 0 */
  /*jshint eqeqeq: false */


  return (
    <BrowserRouter>
    <div className="App">
    <Routes>
      {/* Login and signup routes */}
      <Route exact path="/signup" element={<Signup/>} />
      {/* <Route exact path="/Login/:id" element={<MemberLogin/>}/> */}
      <Route exact path="/Signup/:id" element={<MemberSignup/>}/>
      <Route exact path="/login" element={<Login setIsOrgnizer={setIsOrgnizer}/>} />
      {/* <Route exact path="/avatare" element={<Landing />} /> */}
      {/* <Route exact path="/" element={<LandingTwo/>}/> */}
      {/* <Route exact path="/" element={<Avatare/>}/> */}
      {/* <Route exact path='/ecommerce' element={<Ecommerce/>}/> */}
      {/* <Route exact path='/playbook' element={<Playbook/>}/> */}
      <Route exact path="/" element={<Main setTotalCredits={setTotalCredits} totalCredits={totalCredits} changeCredits={changeCredits} />}>
        {/* Default route and sequential startup*/}
        <Route path="/" element={ <Home /> } />
        <Route path="/sequential-startup/templates-libray" element={<TemplatesLibrary />}/>
        <Route path="/sequential-startup/templates-history" element={<TemplateHistory/>}/>
        <Route path="/sequential-startup/test-template-avatare" element={ <TestMyAvatareNew/>}/>
        <Route path="/sequential-startup/customize-bot" element={ <BotCustomization/>}/>
        <Route path="/sequential-startup/template" element={ <Template/>}/>


        
        {/* <Route path="/" element={<Main/>}/> */}
        <Route path="/admin/createAvatar" element={<CreateAvatareFromScratch/>} />
        <Route path="/admin/editAvatar" element={<EditAvatare/>}/>
        {/* <Route path="/admin/createNewAvatar" element={ <CreateNewAvatare/> } /> */}

        <Route path="/admin/addEmoji" element={ <AddEmoji/> } />
        <Route path="/admin/MyAvatare" element={ <MyAvatar/> } />

        <Route path="/admin/addMyEmoji" element={ <AddMyEmoji/> } />
        <Route path="/admin/cloneAvatare" element={ <CloneAvatare/> } />
        {/* Library routes */}
        <Route path="/library/quickstart" element={ <Quickstart/> } />
        <Route path="/library/quickstart/videos" element={<Videos/>}/>
        <Route path="/library/dataFiles" element={ <Folders/> } />
        <Route path="/library/dataFiles/files/:id/:name" element={ <DataFiles/> } />
        <Route path="/library/dataFiles/file-upload/:id" element={ <NewFileUpload/> } />
        <Route path="/library/translation" element={ <Translation/>}/>
        {/* Classroom routes */}

        <Route path="/classroom/selectModel/:Id" element={<SelectModel/>}/>
        <Route path="/classroom/trainMyModel" element={ <TrainMyModel setChangeCredits={setChangeCredits} />}/>
        <Route path="/classroom/trainBySubject" element={ <TrainBySubject/>}/>
       

        
        <Route path="/classroom/testMyAvatare" element={ <TestMyAvatare/>}/>
        <Route path="/classroom/template" element={ <Template/>}/>
        <Route path="/classroom/scoreAndRating/:Id" element={<ScoreAndRating/>}/>
        <Route path="/classroom/QuestionsAndAnswers" element={<QuestionsAndAnswersScreen/>}/>
        <Route path="/classroom/addNewQuestion" element={<AddNewQuestion/>}/>
        <Route path="/classroom/SelectSubjectsToTrain/:Id" element={<SelectSubjectsToTrain/>}/>
        {/* Operations routes */}
        <Route path="/operations/analytics" element={<Analytics/>}/>
        <Route path="/operations/monitoringandcontrol/" element={<AvatarMonitoringAndControl/>}/>
        <Route path="/operations/trainigdata" element={<TrainingData/>}/>
        <Route path="/operations/policyquestion" element={<PolicyQuestions/>}/>
        <Route path="/operations/activityoverview" element={<ActivityOverview superAdminEmail={superAdminEmail}/>}/>
        <Route path="/operations/integrations" element={<Integrations/>}/>
        <Route path="/operations/selectIntegration/:id" element={<SelectIntegration/>}/>
        <Route path="/operations/documentation" element={<Documentation/>}/>
        

        {/* Research and development routes */}
        <Route path="/research/whisperAI" element={<WhisperAI />}/>
        <Route path="/research/dallE" element={<DallE />} />
        <Route path="/research/chatGPT" element={<ChatGPT setChangeCredits={setChangeCredits} />}/>
        <Route path="/research/whisperplayground" element={<WhisperPlayground />}/>

        {/* Error routes */}
        <Route path="error" element={ <ErrorPage/> } />
        <Route path="/help" element={ <Help /> } />

      </Route>
      <Route exact path="/organization" element={<Organization/>}>
            <Route path="workspace" element={<h1 >Workspace</h1>}/>
            <Route path="profile" element={<Profile/>}/>
            <Route path="team" element={<Teams/>}/>      
            <Route path="credithistory" element={<HistoryTable />}/>      
            <Route path="superadmin" element={superAdminEmail=="daren.jackson@avatare.com"?<SuperAdmin />:<ErrorPage/>}/>      
            <Route path="usage" element={<Usage />}/>      
        <Route path="payment" element={ <Payment value={value} setValue={setValue} setCredits={setCredits} credits={credits}/> } />
        <Route path="paymentintegration" element={ <Payment value={value} setValue={setValue} setCredits={setCredits} credits={credits}/> } />
        <Route path="billing" element={ <Billing value={value} setValue={setValue} setCredits={setCredits} credits={credits}/> } />
      </Route>
      
    </Routes>
    
    </div>
    </BrowserRouter>
  );
}

export default App;
