import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BreadcrumbsComponent from "../../../components/common/Breadcrumbs";
import { useLocation } from "react-router-dom";
import PolicyQuestionTable from "./PolicyQuestionTable";

const PolicyQuestions = () => {
  const location = useLocation();
  const data = location.state;
  const DataId = data?.DataId;
  console.log("policy Question data ", DataId);

  const [chartData, setChartData] = React.useState();
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/?DataId=${DataId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const dataa = await response.json();
        setChartData(dataa);
        console.log(dataa, "this is policy data");
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const random = chartData?.message?.map((i) => i);
  console.log("this is policy question data", random);
  // const queData = chartData?.message?.map((i)=>i)
  // console.log("tqueData ",queData);

  return (
    <>
      <div>
        <div className="flex w-full">
          <PolicyQuestionTable rows={chartData?.message} />
        </div>
      </div>
    </>
  );
};

export default PolicyQuestions;
