import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import swal from "sweetalert";
import Typography from '@mui/material/Typography';
import Modal from "./Modal";
import { useState } from "react";

export default function MembersTable() {
  /* eslint eqeqeq: 0 */
  const [data, setData] = React.useState([]);
  // const [rerender, setRerender] = React.useState(false)
  const rerender= false
  const [memberId , setMemberId] = React.useState()
  const [orgnisationName, setOrgnisationName] = useState()

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const getData = () => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/super-admin/members/`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    ).then((data) => data.json());
  };

  React.useEffect(() => {
    getData().then((items) => {
      if (items.message != "No members yet") {
        console.log(items.message);
        setData(items.message);
      }
      else{
        setData([])
      }
    });
  }, [rerender]);
  // const deleteMember = (Id) => {
  //   console.log(Id)
  //   fetch(
  //     `${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?Id=${Id}`,
  //     {
  //       method:"DELETE",
  //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  //     }
  //   ).then((response) => {
  //       if(response.status==200 || response.status==201){
  //           swal({
  //             title: "Successful",
  //             text: "Question has been deleted!",
  //             icon: "success",
  //             button: "Dismiss!",
  //           });
  //         }
  //       response.json()}).then((data) => setRerender(!rerender))
  //   ;
  // };
  // console.log(data,"dtaaaaaaaaaaaa");

  return (
  <>
  <Modal handleClose={handleClose} open={open} MemberId={memberId} orgnisationName={orgnisationName} setOpen={setOpen}/>
          <TableContainer component={Paper} sx={{bgcolor:"transparent",boxShadow:"none"}}>
    <Table sx={{ minWidth: 650}} aria-label="simple table">
      <TableHead> 
        <TableRow>
          <TableCell>Members</TableCell>
          <TableCell>Credits Bought</TableCell>
          <TableCell align="left">Crides Used</TableCell>
          <TableCell align="left">Cost</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row)=>{
          return(
            <>
          <TableRow>
            <TableCell component="th" scope="row">
              {row.Organization}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.credits_bought}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.credits_used}
            </TableCell>
            <TableCell align="left" scope="row">
              {row.cost}
            </TableCell>
            <TableCell align="left">
              <Typography sx={{color:"black",textDecoration:"none",cursor:"pointer",}} 
              onClick={()=>{
                setOrgnisationName(row.Organization)
                setMemberId(row.UserId)
                setOpen(true)
                }}  >
                Assign Credits
              </Typography>
            </TableCell>
            <TableCell align="left" sx={{display:"flex",justifyContent:"end",py:"22px"}}>
              <EditIcon sx={{ color: "#295598", }}/>
              <DeleteIcon sx={{ color: "#295598" }} 
              // onClick={() => {deleteMember(row.Id)}} 
              />
            </TableCell>
          </TableRow>
            </>
          )
        })}
      </TableBody>
    </Table>
    </TableContainer>
                    </>
  );
}
