import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Typography,
  Checkbox,
} from "@mui/material";
// import { styled, alpha } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import BetterAnswerModal from "../BetterAnswerModal/BetterAnswerModal";
import QuestionsAndAnswers from "../QuestionsAndAnswers/QuestionsAndAnswers";
import styles from "./SessionQuestions.module.css";
import { useState } from "react";

const SessionQuestions = ({
  open,
  setOpen,
  dataObjects,
  Id,
  SessionId,
  IdName,
  rerender,
  setRerender,
  handleCheckboxChange,
  isChecked,
}) => {
  /* eslint eqeqeq: 0 */
  const question="";
  // const [questionsToBeShown, setQuestionsToBeShown] = React.useState([]);
  const [questionId, setQuestionId] = useState(1);
  // const [numberOfItems, setNumberOfItems] = React.useState(10);
  // const [searchQuery, setSearchQuery] = React.useState("");
  // const [pageNumber, setPageNumber] = React.useState(0);
  const [showQuestion, setShowQuestions] = React.useState(false);

  // React.useEffect(() => {
  //   setQuestionsToBeShown(dataObjects);
  //   console.log("setRerender: ", setRerender);
  // }, [dataObjects]);

  // const goToPage = (e, page) => {
  //   setPageNumber(page - 1);
  //   console.log(page);
  // };

  const getResults = async (question) => {
    return fetch(
      `${process.env.REACT_APP_API_ROOT}/api/bot/getchats/?BotId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
    ).then((data) => data.json());
  };

  const getQAs = async (question) => {
    if(IdName=="DataId"){
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/searchQAs/?DataId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
      ).then((data) => data.json());

    }
    else{
      return fetch(
        `${process.env.REACT_APP_API_ROOT}/api/data/searchQAs/?SourceId=${Id}&Question=${question}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
      ).then((data) => data.json());
    }
  };

  // const searchQuestions = async (e) => {
  //   var question = document.getElementById("search").value;
  //   var currentScreen = window.location.href.split("/")[4];
  //   // setSearchQuery(question);
  //   if (currentScreen == "SessionQuestions") {
  //     getQAs(question).then((result) => {
  //       console.log(result);
  //       if (typeof result.message == "string") {
  //         // setQuestionsToBeShown([]);
  //       } else {
  //         // setQuestionsToBeShown(result.message);
  //       }
  //     });
  //   } else {
  //     getResults(question).then((result) => {
  //       console.log(result);
  //       // setQuestionsToBeShown(result.message);
  //     });
  //   }
  // };
  

  return (
    <>
        <Box sx={{ display: "flex" }}>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        <Container
          className={styles.outermostCard}
          onClick={() => setShowQuestions(!showQuestion)}
        >
          <Typography className={styles.SessionId}>
            Session ID : {SessionId}
          </Typography>
        </Container>
      </Box>
    {showQuestion ? <Container>
      {/* <Search
        sx={{
          border: "1px solid #ABABAB",
          color: "#295598",
          height: "49px",
          width: "45vw!important",
          mt: "29px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          type="button"
          onClick={searchQuestions}
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <StyledInputBase
          id="search"
          placeholder="Search questions here."
          inputProps={{ "aria-label": "search" }}
        />
      </Search> */}
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "100%",
          ml: "0px!important",
          padding: "0px!important",
        }}
      >
        <QuestionsAndAnswers questionId={questionId} setQuestionId={setQuestionId} open={open} setOpen={setOpen} dataObjects={dataObjects} Id={Id} rerender={rerender} setRerender={setRerender}/>
        {/* {(() => {
          const rows = [];
          for (let i = 0; i <= 6; i++) {
            rows.push(<QuestionBoxInScore index={i+1} open={open} setOpen={setOpen} question={questions[i]} answer={answers[i]} setQuestion={setQuestion} dataObjects={dataObjects}/>);
          }
          return rows;
        })()} */}
      </Container>
      <Container
        sx={{
          m: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
      </Container>
      <BetterAnswerModal
        Id={questionId}
        BotId={Id}
        open={open}
        setOpen={setOpen}
        question={question}
      />
    </Container> : 
    <>

    </>}
    
    </>
  );
};

export default SessionQuestions;
