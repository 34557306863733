import {
  Typography,
  Breadcrumbs,
  FormControl,
  Select,
  MenuItem,
  Container,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./SideNav.module.css";
import React, { useState } from "react";
// import { Container } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BreadcrumbsComponent = ({ Left, Right, botId, setBotId, setUserId,UserId ,allMemberId,allMember,allAvatarsId ,allAvatars,setSelectActivityAvatare,setSelectActivityMember,selectActivityAvatare,selectActivityMember,setAllIds}) => {
  /* eslint eqeqeq: 0 */
  const [selectValue, setSelectValue] = React.useState(botId ? botId : 1);
  const [dropdownBots, setDropdownBots] = React.useState([]);
  const [members, setMember] = useState()
  const isOrganizer = localStorage.getItem("isOrganizer")
  const token = localStorage.getItem("token")
  const userId = localStorage.getItem("UserId")

// console.log("UserId :", UserId);

  const handleMemberChange = (e) => {
    setUserId(e.target.value)
  }
  
  const handleChange = (event) => {
    // console.log(event.target.value)
    setSelectValue(event.target.value);
    setBotId(event.target.value);
  };

  const handleActivityMemberChange = (e) => {
    setSelectActivityMember(e.target.value)
  }
  const handleActivityAvatarChange = (event) => {
    setSelectActivityAvatare(event.target.value);
    // setBotId(event.target.value);
  };



  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/memberslist/?OrganizationId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        // console.log(dataa, "");
        setMember(dataa)
      } else {
        const dataa = await response.json();
        console.log('Error:', response.status, dataa);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);


  const getData = async () => {
      // let userId = localStorage.getItem('UserId')
      return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=${UserId}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then(
          data => data.json())
  }

  React.useEffect(() => {
    getData()
      .then(items => {
        // console.log("Items: ", items);
        var a = []
        if(items?.message =="No Avatars Yet"){
          a.push({"BotId" : 1, "BotName": "No avatar"})
          setDropdownBots(a)
          setSelectValue(1)
          setBotId(a[0].BotId)
        }else{
        items?.message?.map((item) => {
            a.push({"BotId" : item.BotId, "BotName": item.BotName})
        })
        setDropdownBots(a)
        if(botId==1||botId==null){
          setSelectValue(a[0].BotId)
          setBotId(a[0].BotId)
        }
      }
      })
    }, [UserId])
  return (
    <>
      <Container sx={{ display: "flex", alignItems: "end", justifyContent:"space-between",position:"relative" }}>
        <Breadcrumbs
          maxItems={5}
          separator=">"
          aria-label="breadcrumb"
          // width
          height="10px"
          className={styles.BreadcrumbsOutermost}
          color="#295598"
        >
          <Link className={styles.breadcrumbHeading} to="/">
            {Left}
          </Link>
          <Link className={styles.breadcrumbHeading} to="/">
            {Right}
          </Link>
        </Breadcrumbs>
        <Box sx={{display:"flex"}}>

        {Right=="Create Avatare" && isOrganizer == "true" || Right=="Add My Emoji" && isOrganizer == "true"|| Right=="Data Files" && isOrganizer == "true" || Right=="Clone Avatare" && isOrganizer == "true" || Right=="Analytics" && isOrganizer == "true" ?  <>
        <Box sx={{mx:"5px"}}>
        <FormControl size="small" sx={{mb:"-30px"}} >
          <Typography size={16} align="left" className={styles.dropdownHeading}>
            Current Member
          </Typography>
          <Select
            className={styles.dropdown}
            value={UserId}
            onChange={handleMemberChange}
            displayEmpty
            IconComponent={() => (
              <KeyboardArrowDownIcon />
            )}
            sx={{
              color: "black",
              width: "200px",
              float: "right",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
            
          >
            {members?.message?.map((members) => {
            return(
              <MenuItem value={members?.Id}><Typography>{`${members?.first_name == null?"Orgnizer":members?.first_name} ${members?.last_name==null?"":members?.last_name}`}</Typography></MenuItem>
            )
})}
          </Select>
        </FormControl>
        </Box>
        </> : <></>}
        {Right=="Data Files"?  <>
        <Box sx={{mx:"5px"}}>
        <FormControl size="small" sx={{mb:"-30px"}} >
          <Typography size={16} align="left" className={styles.dropdownHeading}>
            Current Avatare
          </Typography>
          <Select
            className={styles.dropdown}
            value={selectValue}
            onChange={handleChange}
            displayEmpty
            IconComponent={() => (
              <KeyboardArrowDownIcon />
            )}
            sx={{
              color: "black",
              width: "200px",
              float: "right",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
          >
            {dropdownBots.map((bot) => {
            return(
              <MenuItem value={bot.BotId}><Typography>{bot.BotName}</Typography></MenuItem>
            )
})}
          </Select>
        </FormControl>
        </Box>
        </> : <></>}
        </Box>

        {Right == "Analytics Overview" && (
        <Box sx={{display:"flex"}}>
        {Right=="Analytics Overview" && isOrganizer == "true" ?  <>
        <Box sx={{mx:"5px"}}>
        <FormControl size="small" sx={{mb:"-30px"}} >
          <Typography size={16} align="left" className={styles.dropdownHeading}>
            Current Member
          </Typography>
          <Select
            className={styles.dropdown}
            value={selectActivityMember}
            onChange={handleActivityMemberChange}
            displayEmpty
            IconComponent={() => (
              <KeyboardArrowDownIcon />
            )}
            sx={{
              color: "black",
              width: "200px",
              float: "right",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
            
          >
            <MenuItem value={allMemberId} defaultValue={allMemberId} onClick={()=>setAllIds(true)}><Typography>All Members</Typography></MenuItem>
            {allMember?.message?.map((members) => {
            return(
              <MenuItem value={members?.Id}><Typography>{`${members?.first_name == null?"Orgnizer":members?.first_name} ${members?.last_name==null?"":members?.last_name}`}</Typography></MenuItem>
            )
})}
          </Select>
        </FormControl>
        </Box>
        </> : <></>}
        {Right=="Analytics Overview"?  <>
        <Box sx={{mx:"5px"}}>
        <FormControl size="small" sx={{mb:"-30px"}} >
          <Typography size={16} align="left" className={styles.dropdownHeading}>
            Current Avatare
          </Typography>
          <Select
            className={styles.dropdown}
            value={selectActivityAvatare}
            onChange={handleActivityAvatarChange}
            displayEmpty
            IconComponent={() => (
              <KeyboardArrowDownIcon />
            )}
            sx={{
              color: "black",
              width: "200px",
              float: "right",
              "& .MuiSvgIcon-root": {
                color: "black",
              },
            }}
          >
            <MenuItem value={allAvatarsId} defaultValue={allAvatarsId}><Typography>All Bots</Typography></MenuItem>
            {allAvatars?.message?.map((bot) => {
            return(
              <MenuItem value={bot.BotId}><Typography>{bot.BotName}</Typography></MenuItem>
            )
})}
          </Select>
        </FormControl>
        </Box>
        </> : <></>}
        </Box>
        )}
        
      </Container>
      <hr  
      className={styles.divider}/>
    </>
  );
};

export default BreadcrumbsComponent;
