// import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import { Typography } from "@mui/material";
// import PlaylistCard from "../../components/Library/PlaylistCard/PlaylistCard";
// import BreadcrumbsComponent from "../../components/common/Breadcrumbs";

// const Quickstart = () => {
//   const handleClick = () => {
//     window.location = "/library/quickstart/videos"
//     // console.log("We here")
//   }
//   return (
//     <React.Fragment>
//       <CssBaseline />
//       <Container
//         fixed
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           // margin:"3vw"
//         }}
//       >
//         <BreadcrumbsComponent Left="Library" Right="Quickstart Tutorial"/>
//         <Typography
//         sx = {{
//             position: "relative",
//             right: "43%",
//             fontWeight: 'bold',
//             fontSize: '24px'
//         }}>
//             All Playlists
//         </Typography>
//         <Box
//         onClick={handleClick}
//           sx={{
//             bgcolor: "#E1F4FF",
//             height: "50vh",
//             width: "65vw",
//             mt: 5,
//             display: "flex",
//             alignItems: "left",
//             flexDirection: "row",
//             flexWrap: "wrap"
//           }}
//         >
//         <PlaylistCard playlistName={"Tutorials"}/>
//         </Box>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default Quickstart;

import * as React from "react";
import { TopBar } from "../../components/CommonComponent/Topbar";
import Guide from "../../components/Library/Guide";
import { useNavigate } from "react-router-dom";

const Quickstart = () => {
  const navigate= useNavigate();

  const handleClick = () => {
    navigate("/library/quickstart/videos");
    
  };
  return (
    <>
      <TopBar text={"QuickStart Tutorial"} />
      <div className="px-8 py-4">
        <h1 className="text-lg leading-7 font-semibold">
          Lets get you started
        </h1>
        <div className="flex mt-2 items-center gap-6">
         <Guide handleClick={handleClick} icon="/guide-arrow.svg" text="How to Guide" subText="This is simple step-by-step guide to initialize your first flow in Avatare"/>
         <Guide icon="/template-guide-icon.svg" text="Template based Avatare" subText="This is simple step-by-step guide to create Avatare based on customizable templates."/>
        </div>
      </div>

    </>
  );
};

export default Quickstart;
