// src/App.js
import React, { useState } from 'react';
import PopUpData from "./PopUpData"

function Popup({isModalOpen,closeModal,text,desc,buttonTextOne,buttonTextTwo,avatareName,setAvatareName,handleClone ,fromClone}) {


 

  return (
    <div  className="flex items-center justify-center">
      <PopUpData isOpen={isModalOpen} onClose={closeModal} text={text} desc={desc} buttonTextOne={buttonTextOne} buttonTextTwo={buttonTextTwo}  avatareName={avatareName} setAvatareName={setAvatareName} handleClone={handleClone} fromClone={fromClone}/>
    </div>
  );
}

export default Popup;
