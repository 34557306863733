import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography, Button } from "@mui/material";
import styles from "./QuestionBoxInScore.module.css";
import Dropdown from "../../../assets/img/classroom/dropdown.svg";
import { useStars } from "stars-rating-react-hooks";
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import { TopBar } from "../../CommonComponent/Topbar";
import { message } from "antd";

const QuestionBoxInScore = ({
  index,
  open,
  setOpen,
  question,
  setQuestion,
  answer,
  botId,
  rank,
  setQuestionId,
  rerender,
  setRerender
}) => {
  /* eslint eqeqeq: 0 */
  // console.log(index);
  const [showData, setShowData] = React.useState(false);
  const [activeStyle, setActiveStyle] = React.useState(styles.outermostCard);
  const [selecting, setSelecting] = React.useState(null);
  const [rating, setRating] = React.useState(null);
  const [currentScreen, setCurrentScreen] = React.useState(
    window.location.href.split("/")[4]
  );
  const [questionNumber,setQuestionNumber] = React.useState()

  // React.useEffect(() => {
  //   // console.log(window.location.href.split("/")[4]);
  //   setQuestionId(index);
  // }, []);
  React.useEffect(() => {
    console.log(window.location.href.split("/")[4]);
    if (typeof setQuestionId === 'function') {
      setQuestionId(index);
    }
  }, []);

  const config = {
    totalStars: 5,
    initialSelectedValue: rank,
    renderFull: "★",
    renderEmpty: "☆",
  };

  const {
    stars,
    getStarProps,
    getStarWrapperProps,
    // isSelecting,
    // selectingValue and selectedValue are same after user rates
    // selectingValue,
    // selectedValue,
  } = useStars(config);

  const addBetterAnswer = (i) => {
    setQuestionId(index);
    setOpen(true);
    setQuestionNumber(i)
  };

  const showMore = () => {
    setQuestionId(index);
    setShowData(!showData);
    if (activeStyle === styles.outermostCard) {
      setActiveStyle(styles.activeStyle);
    } else {
      setActiveStyle(styles.outermostCard);
    }
  };

  const SubmitRanks = async (e) => {
    e.stopPropagation();
    {
      let body = {
        UserId: localStorage.getItem("UserId"),
        BotId: botId,
        ChatId: index,
        Rating: rating,
      };
      console.log(body);
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/getchats/?UserId=` , {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(body),
      });
      // console.log(response);
      // console.log(response.status);
      const result = await response.json();
      console.log(result);
      if (response.status == 200 || response.status == 201) {
        // console.log("Stop propagation");
        setSelecting(true)
        message.success("Rank has been deleted!")
        
      }
    }
  };
  const deleteQuestion = async (e) => { 
    if(currentScreen == "scoreAndRating"){
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}/api/bot/getchats/?ChatId=` + index,
          {
            method: "DELETE",
            headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
          }
        );
        console.log(response);
        if (response.status != 200 && response.status != 201 && response.status != 204) {
          
            message.success("Question has been deleted!")
            setRerender(!rerender)
      
        } else {
          message.success("Question has been deleted!")
              setRerender(!rerender)
      
        }
        const result = await response.json();
        console.log(result)
      } catch (e) {
        console.log(e);
      }
    }
    else{
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/?Id=` + index,
          {
            method: "DELETE",
            headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
          }
        );
        console.log(response.status);
        if (response.status != 200 && response.status != 201 && response.status != 204) {
          message.error("Question could not be deleted!")
          
        } else {
          message.success("Question has been deleted!")
         
          setRerender(!rerender)
      
        }
        const result = await response.json();
        console.log(result)
      } catch (e) {
        console.log(e);
      }
    }
  }


  function DataBox() {
    return (
      
        


      <div className="flex flex-col mt-2 w-full">
        <div className="flex justify-between gap-2 items-start border-black border-opacity-40 border rounded-md p-3 w-full">
          <div className="flex flex-col gap-2 items-start">
          <p className="text-base font-medium">
            {index}- {question}
          </p>
          <div className="flex gap-2">
          <div style={{ display: "flex", gap: "2vw" }}>
              <p id="answer" className="text-base font-medium">
                Answer:{" "}
              </p>
              {currentScreen == "scoreAndRating" ? (
                <>
                  <div>
                    <span
                      {...getStarWrapperProps({
                        style: {
                          cursor: "pointer",
                        },
                      })}
                    >
                      {stars?.map((star, i) => (
                        <span
                          key={i}
                          {...getStarProps(i, {
                            style: {
                              fontSize: "20px",
                              color: "gold",
                            },
                            onClick: (event, ratedValue) => {
                                setRating(ratedValue)
                                event.stopPropagation();
                              },
                          })}
                        >
                          {star}
                        </span>
                      ))}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              
            </div>
            
            <p className="text-base font-medium"> {answer}</p>
            </div>
            </div>
          
            <>
            <div style={{display:"flex", flexDirection:"row", margin:"1vw"}}>
            <div  onClick={(index) => deleteQuestion(index)}>
              <img src="/delete-white-icon.svg" />
            </div>
            </div>
            </>
          
        </div>
     
          <>
           
            <div className={styles.buttonContainer}>
              {currentScreen != "scoreAndRating" || selecting==true ? (
                <></>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  className={styles.submitRanks}
                  onClick={SubmitRanks}
                >
                  Submit ranks
                </Button>
              )}
              {/* <Button
                variant="contained"
                size="small"
                className={styles.shareBetter}
                on
                onClick={()=>addBetterAnswer(index)}
              >
                Add better answer
              </Button> */}
            </div>
          </>
       
      </div>
      
    );
  }
  return (
    <>
   
    <div className="w-ful" >{DataBox()}</div>
    </>
  );
};

export default QuestionBoxInScore;
