import React from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import ProfileDetailForm from "../../components/Organization/Profile/ProfileDetailForm/ProfileDetailForm";
import EmailAndPassword from "../../components/Organization/Profile/EmailAndPassword.js/EmailAndPassword";

const Profile = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "4vh",
      }}
    >
      <Typography
        align={"left"}
        fontSize={"38px"}
        fontWeight={500}
        fontFamily={"Gordita"}
      >
        My Profile
      </Typography>
      <ProfileDetailForm/>
      <Divider></Divider>
      <EmailAndPassword/>
      <Divider></Divider>
      <Typography align="left" fontWeight={"bold"} fontSize={"30px"} sx={{color:"#295598", textDecoration:"underline"}}>
        Delete My Account
      </Typography>
      <Box display={"flex"}>
        <Typography align="left" fontSize="24px" mr={1}>
          You will receive an email to confirm account deletion
        </Typography>
      </Box>
    </Container>
  );
};

export default Profile;
