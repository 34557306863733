import React from "react";
import { Button, Typography, Checkbox, Menu, MenuItem, Divider, MenuList} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./FileCard.module.css";
import { useNavigate } from "react-router-dom";
import OperationsMenu from "../../common/OperationsMenu";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";

const FileCard = ({
  file,
  name,
  DataId,
  setDataId,
  SetCheckedIDs,
  checkedIDs,
  setFiles,
  showFolders,
  setShowFolders,
  setShowEditModal,
  setCurrentDataId,
  currentDataId,
  setCurrentFolderName,
  questions,
  answers,
  implementChecks,
  manualResponses,
  rerender,
  setRerender,
  setIsScore,
  isScore
}) => {
  const [implementCheck, setImplementCheck] = React.useState(implementChecks);
  const [check, setCheck] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log("file",file);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const navigate = useNavigate();

  // console.log("Data Id",DataId);
  const handleCheckbox = () => {
    let ch = !check;
    console.log(ch);
    console.log(DataId);
    setCheck(!check);
    if (check == false) {
      SetCheckedIDs([...checkedIDs, DataId]);
    } else {
      SetCheckedIDs(checkedIDs.filter((IDs) => IDs != DataId));
    }
    // console.log(checkedIDs);
  };
  
  const getFiles = async () => {
    if (showFolders == true) {
      if(isScore==true){
        setIsScore(false)
      }
      if (manualResponses == false) {
        // Uploading the file to server
        return fetch(`${process.env.REACT_APP_API_ROOT}/api/data/upload/?DataId=${DataId}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
          .then((data) => data.json())
          .then((items) => {
            console.log("Items :: ", Array.isArray(items.message));
            setCurrentDataId(DataId);
            setCurrentFolderName(name);
            console.log("Current data id: ", DataId);
            setShowFolders(false);
            if (Array.isArray(items.message)) {
              setFiles(items);
            }
          });
      } else {
        navigate("/classroom/QuestionsAndAnswers", {
          state: {
            name: name,
            description: "description",
            currentDataId: DataId,
            currentSourceId: false,
            questions: questions,
            answers: answers,
          },
        });
      }
    }
    else {
      console.log("You are clicking on a file.");
      navigate("/classroom/QuestionsAndAnswers", {
        state: {
          name: name,
          description: "description",
          currentDataId: currentDataId,
          currentSourceId: DataId,
          questions: questions,
          answers: answers,
        },
      });
    }
  };

  const options = (e) => {
    // console.log(e);
    if (e) {
      e.stopPropagation();
    }
    setOpenDropdown(true);
    setAnchorEl(e.currentTarget)
    // console.log("Open options");
  };
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setOpenDropdown(false);
  };
  const editById = (e) => {
    if (e) {
      e.stopPropagation();
    }
    console.log("Edit By Id")
    setDataId(DataId)
    setShowEditModal(true)
  }
  const deleteById = async(e) => {
    if (e) {
      e.stopPropagation();
    }
    // console.log("Delete By Id")
    try {
      if(isScore==true || showFolders == false){
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/data/upload/?SourceId=` + DataId, {
        method: "DELETE",
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
      });
      // console.log(response)
       const result = await response.json()
       console.log(result)
      if (response.status != 200 && response.status != 201) {
        swal({
          title: "Oh Snap!",
          text: "Folder could not be deleted!",
          icon: "error",
          button: "Dismiss!",
        });
      } else {
        setRerender(!rerender)
        swal({
          title: "Success",
          text: "Folder has been deleted!",
          icon: "success",
          button: "Dismiss!",
        });
        
      }

      }
      else{
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/data/createDataset/?DataId=` + DataId, {
        method: "DELETE",
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
      });
      console.log(response)
       const result = await response.json()
       console.log(result)
      if (response.status != 200 && response.status != 201) {
        swal({
          title: "Oh Snap!",
          text: "Folder could not be deleted!",
          icon: "error",
          button: "Dismiss!",
        });
      } else {
        setRerender(!rerender)
        swal({
          title: "Success",
          text: "Folder has been deleted!",
          icon: "success",
          button: "Dismiss!",
        });
        
      }

      }
      
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <>
      {implementCheck ? (
        <Card className={styles.cardOutermost}>
          <CardActionArea>
            <Checkbox
              sx={{
                fontSize: 90,
                display: "flex",
                justifyContent: "flex-start",
              }}
              checked={check}
              onChange={() => {
                handleCheckbox();
              }}
            ></Checkbox>
            <CardMedia>
              <FolderOpenIcon sx={{ fontSize: 40, color: "#295598" }} />
            </CardMedia>

            <CardContent>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                {name}
              </Typography>
              {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "grey" }}
              >
                Last Updated : 08-04-2022
              </Typography> */}
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <>
          <Card
            className={styles.cardOutermost}
            onClick={() => getFiles(DataId)}
          >
            <CardActionArea>
              <MoreVertIcon
                className={styles.vertIcon}
                onClick={(e) => options(e)}
              />
              <Menu
                id="basic-menu"
                open={openDropdown}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorEl={anchorEl}
                keepMounted={false}
              >
                <MenuList dense>
                  <MenuItem onClick={editById}>
                    <EditIcon/>
                    <Typography ml={1}>Edit</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={deleteById}>
                    <DeleteIcon/>
                    <Typography ml={1}>Delete</Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
              <CardMedia sx={{ fontSize: 90 }}>
                <FolderOpenIcon sx={{ fontSize: 40, color: "#295598" }} />
              </CardMedia>

              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  {name}
                </Typography>
                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: "grey" }}
                >
                  Last Updated : 08-04-2022
                </Typography> */}
              </CardContent>
            </CardActionArea>
          </Card>
        </>
      )}
    </>
  );
};

export default FileCard;
