import React, { useRef } from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import CloudUpload from '../../assets/img/common/CloudUpload.svg';
import { useState } from 'react';
import swal from "sweetalert";


const UploadBox = ({ setAudioFile }) => {
  const fileInputRef = useRef(null);
  const [key, setKey] = useState(0);

  const handleContainerClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    let selectedFile = event.target.files[0];
    console.log("selected file",selectedFile);
    const MIN_FILE_SIZE = 1; 
    const MAX_FILE_SIZE = 25000;
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      swal({
        title: "Oh Snap!",
        text: "File size is less than minimum limit",
        icon: "error",
        button: "Dismiss!",
      })
    } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      swal({
        title: "Oh Snap!",
        text: "File size is greater than maximum limit",
        icon: "error",
        button: "Dismiss!",
      })
    }
    else if (selectedFile.type == "audio/mpeg" || selectedFile.type == "audio/mp3" || selectedFile.type == "audio/wav" || selectedFile.type == "audio/x-m4a") {
      try {
        // Create an audio element to calculate the duration
        const audio = new Audio();
        audio.addEventListener("loadedmetadata", () => {
          const durationInSeconds = audio.duration;
          // Pass the selected file and duration to the parent component
          setAudioFile({ file: selectedFile, duration: durationInSeconds });
          swal({
            title: "Success",
            text: "File uploaded successfully",
            icon: "success",
            button: "Dismiss!",
          });
        });
  
        // Set the audio source after adding the event listener
        audio.src = URL.createObjectURL(selectedFile);
        audio.load();
      } catch (error) {
        console.error("Error calculating audio duration: ", error);
        swal({
          title: "Error",
          text: "Failed to calculate audio duration",
          icon: "error",
          button: "Dismiss!",
        });
      }
    }else {
      swal({
        title: "Oh Snap!",
        text: "This File is not Supported",
        icon: "error",
        button: "Dismiss!",
      });
      //     const selectedFile = event.target.files[0];
      //     setAudioFile(URL.createObjectURL(selectedFile));
      setKey((prevKey) => prevKey + 1);
    }
  };


  return (
    <div>
      <Box
        sx={{
          border: "dashed",
          minHeight: "35vh",
          height: "100%",
          width: "100%",
          mt: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor:"pointer"
        }}
        onClick={handleContainerClick}
      >
        <Container
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}
        >
          <img src={CloudUpload} sx={{ fontSize: 20 }} alt="Cloud Upload" />
          <Typography sx={{ mt: 2, color: "#5C5C5C" }}>Upload audio or video from computer</Typography>
          <Typography sx={{ color: "#5C5C5C" }}>Or</Typography>
          <Typography sx={{ color: "#5C5C5C" }}>Drag and drop here</Typography>
          <input
            type="file"
            accept="audio/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Container>
      </Box>
    </div>
  )
}

export default UploadBox;
