import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import Select from "@mui/material/Select";
import { Typography,  InputLabel, FormControl,  } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AvatareModelTraining from '../../components/Administration/AvatareModelTraining/AvatareModelTraining';
import { useParams, useLocation } from "react-router-dom";


const SelectModel = () => {
    const [model, setModel] = React.useState("")
    const [listModels, setListModels] = React.useState([])
    const [botName, setBotName] = React.useState("")
    const [botDesc, setBotDesc] = React.useState("")
    // const [buttonMessage, setButtonMessage] = React.useState("")
    const [emoji, setEmoji] = React.useState("")
    // const [userId, setUserId] = React.useState("")
    // const [botData, setBotData] = React.useState({})

    let { Id } = useParams()

    const state =  useLocation()
    const { DataIds } = state

    React.useEffect(() => {
        let mounted = true;
        console.log("Emoji link is: ", emoji)
        getData().then((items) => {
          console.log("Items: ", items);
          setListModels(items.message.map((item) => (Object.keys(item)[0])));
        });


        let mounted2 = true;
        getBotData().then((items) => {
          console.log("Items: ", items);
          // setBotData(items.message)
          setBotName(items.message[0].BotName)
          setBotDesc(items.message[0].BotDescription)
          setEmoji(items.message[0].emojiS3Link)
          // setUserId(items.message[0].UserId)
        });
        return () => (mounted2 = false);
      }, []);

      const getData = async () => {
        // let UserId = "7ed282afb12842529c03dd069f0c83da ";
        return fetch(
          `${process.env.REACT_APP_API_ROOT}/api/bot/modelslist/`,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
        ).then((data) => data.json());
      }

      const getBotData = async () => {
        return fetch(
          `${process.env.REACT_APP_API_ROOT}/api/bot/getbotdetails/?BotId=` + Id,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
        ).then((data) => data.json());
      };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          // margin:"3vw",
          // width:"2000px"
        }}
      >
        <BreadcrumbsComponent Left="Classroom" Right="Train My Avatare By AI model"/>
        <Box
          sx={{
            bgcolor: "#E1F4FF",
            // height: "50vh",
            width: "100%",
            mt: 10,
            py:"30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "dashed",
            borderWidth: "1px"
          }}
        >
          <Typography fontSize={38} sx={{ mt: 2 }} fontWeight="bold">
            Please select AI model to start training!
          </Typography>
          <FormControl  sx={{ m: 1, minWidth: "45vw" }}>
                <InputLabel id="demo-simple-select-label" sx={{fontFamily:"Gordita", fontSize:25}}>Specify your Model here</InputLabel>
                <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Specify your avatar here"
                        width="45vw"
                        placeholder="Specify your avatar here"
                        value={model}
                        onChange={e => setModel(e.target.value)}
                        sx={{backgroundColor:"white",
                        fontFamily:"Gordita", fontSize:25}}
                    >
                        {listModels.map((model) => 
                            <MenuItem value={model}>{model}</MenuItem>
                        )}
                </Select>
            </FormControl>
            {model? <div><AvatareModelTraining BotId={Id} name={botName} description={botDesc} DataIds={DataIds} button_message={"Train"} emoji={emoji}/></div> : <></>}
        </Box>
        </Container>
    </React.Fragment>
  )
}

export default SelectModel