import React from 'react';
import { Progress } from 'antd';

const FileProgress = ({ fileNames, currentIndex,active,pl,chat}) => {

   
  const progressPercent = (100 / fileNames.length) * (currentIndex ? (currentIndex + 1) : 1);
  const progressPercentchat = ((100 / fileNames.length) * (active.length > 0 ? active.length  : 0));
  

  const renderFileList = () => {
    return fileNames.map((fileName, fileIndex) => {
      const bgColor = active.includes(fileIndex) ? "bg-black" : "bg-gray-400";
      const textColor = active.includes(fileIndex)
        ? "text-black"
        : "text-gray-400";
      const icon = active.includes(fileIndex) ? "/radio.svg" : "/dot.svg";

      return (
        <div
          key={fileName}
          className={`flex items-center ${
            fileIndex < fileNames?.length - 1 ? "w-full" : "w-fit"
          } gap-2`}
        >
          <img src={icon} alt="icon" />
          
          <h1 className={`text-xs text-nowrap ${textColor} font-semibold`}>
            {fileName}
          </h1>
          {fileIndex < fileNames?.length - 1 && (
            <div
              // style={{ width: "200px"}}
              className={`h-[1px] max-h-[2%] w-full  mt-1 ${bgColor}`}
            ></div>
          )}
        </div>
      );
    });
  };

  const renderSectionList = () => {
    return !!fileNames && fileNames.map((fileName, fileIndex) => {
      const bgColor = active.includes(fileName) ? "bg-black" : "bg-gray-400";
      const textColor = active.includes(fileName)
        ? "text-black"
        : "text-gray-400";
      const icon = active.includes(fileName) ? "/radio.svg" : "/dot.svg";

      return (
        <div
          key={fileName}
          className={`flex items-center ${
            fileIndex < fileNames?.length - 1 ? "w-full" : "w-fit"
          } gap-2`}
        >
          <img src={icon} alt="icon" />
          
          <h1 className={`text-xs   text-wrap min-w-[100px] max-w-[100px] ${textColor} font-semibold`}>
            {fileName}
          </h1>
          {fileIndex < fileNames?.length - 1 && (
            <div
              // style={{ width: "200px"}}
              className={`h-[1px] max-h-[2%] w-full  mt-1 ${bgColor}`}
            ></div>
          )}
        </div>
      );
    });
  };


  return (
    <div>
      <div className="px-[29px] w-full flex gap-2 py-[21px]">
        {chat ? renderSectionList():renderFileList()}
      </div>
      <Progress
        style={{paddingLeft:pl}}
        className="flex items-baseline"
        percent={chat ? progressPercentchat: progressPercent}
        showInfo={false}
        strokeColor="#497964"
        strokeWidth={5}
      />
    </div>
  );
};

export default FileProgress;
