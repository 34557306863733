import { Typography, TextField, Button, Box } from "@mui/material";
import React from "react";
import styles from "./TeamInvite.module.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const TeamInvite = () => {
  const [copied, setCopied] = React.useState(false);
  const [copyButtonMessage, setCopyButtonMessage] = React.useState("Copy Link")

  const ORGANIZATION_ID = localStorage.getItem("UserId");
  var inviteLink = `${process.env.REACT_APP_API_ROOT}/Signup/` + ORGANIZATION_ID;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
        if (copied) {
            setCopied(false);
        setCopyButtonMessage("Copy Link")}
      }, 1000);
  
      return () => clearTimeout(timeout);
  }, [copied])

  const handleCopyClick = (e) => {
    setCopied(true);
    setCopyButtonMessage("Copied");
    navigator.clipboard.writeText(inviteLink);

  };
  return (
    <>
      <Typography variant="h1" className={styles.textLabels} mt={10}>
        Team Members
      </Typography>
      <Typography className={styles.subText}>
        Collaborate with others in your workspace
      </Typography>
      <Typography className={styles.textInviteHeading} mt={5}>
        Team Invite Link
      </Typography>
      <TextField className={styles.textField} value={inviteLink}></TextField>
      <Box className={styles.buttonContainer}>
        <Button
          className={styles.copyButton}
          startIcon={
            copied ? (
                <div className={styles.copiedIcon}>
                    <Check css={{
                        strokeDasharray: 50,
                        strokeDashoffset: copied ? 0 : -50,
                    }}/>
                </div>
            ) : (
                <ContentCopyIcon />
            )
          }
          onClick={handleCopyClick}
        >
          {copyButtonMessage}
        </Button>
        {/* <Button className={styles.resetButton} startIcon={<RestartAltIcon/>}>Reset Link</Button> */}
      </Box>
    </>
  );
};
function Check(props) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path d="M13.25 4.75L6 12L2.75 8.75" />
      </svg>
    );
  }

export default TeamInvite;
