import React, { useState } from 'react'
import { TopBar } from '../../CommonComponent/Topbar'
import { FileUploader } from '../../common/FileUploader'
import { Progress, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const NewFileUpload = () => {
    const navigate=useNavigate();
    const {id}=useParams();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [file,setFile]=useState(null);
 

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file.type === "text/csv") {
        const fileUploaded = event.target.files[0];
        setFile(fileUploaded)
        const formData = new FormData();
        formData.append("DataId", id);
        formData.append("SourceFile", fileUploaded);
        formData.append("SourceName", fileUploaded.name);

        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_API_ROOT}/api/data/upload/`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("token"));

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round((event.loaded / event.total) * 100);
                    setUploadProgress(percentComplete);
                }
            };

            xhr.onload = async () => {
                if (xhr.status == 400 || xhr.status == 401) {
                    message.error("File has not been uploaded due to format issues or lack of relevant data!");
                    setUploadProgress(0);
                    return;
                } else {
                    const result = JSON.parse(xhr.responseText);
                    if (result.message.constructor == Object) {
                        let body = {
                            DataId: result.message.DataId,
                            SourceId: result.message.SourceId,
                            Question: result.message.Question,
                            Answer: result.message.Answer,
                        };
                        const secondResponse = await fetch(
                            `${process.env.REACT_APP_API_ROOT}/api/data/addQA/`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "*/*",
                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                },
                                body: JSON.stringify(body),
                            }
                        );
                        const resultSecondEndpoint = await secondResponse.json();
                        console.log(resultSecondEndpoint);
                        navigate("/classroom/QuestionsAndAnswers", {
                            state: {
                                name: result.message.SourceName,
                                description: "description",
                                currentDataId: result.message.DataId,
                                currentSourceId: result.message.SourceId,
                                questions: result.message.Question,
                                answers: result.message.Answer,
                            },
                        });
                        message.success("File has been uploaded!");
                    } else {
                        message.error("Some error occurred!");
                    }
                }
            };

            xhr.onerror = () => {
                message.error("An error occurred while uploading the file.");
            };

            xhr.send(formData);
        } catch (e) {
            console.log(e);
        }
    } else {
        message.error("Please upload supported file.");
    }
};

  return (
    <div className="w-full">
    <TopBar backArrow={true} text="Upload New File" />
    <div className='mx-8 mt-8 py-6 px-16 bg-white  min-h-[500px]'>
    <FileUploader
              accept=".csv , .xlsx"
              supportedFiles={"csv,xlsx"}
              text="File Upload" 
              icon={"/file-upload-icon.svg"}
              desc="files enhances overall look and give it more meaning."
              handleFileUpload={handleFileUpload}
            />
          {file && uploadProgress > 0 && 
          <div className='flex flex-col p-6 gap-2 border-2 mt-4 border-gray-200 rounded-md'>
            <div className='flex gap-2 items-start'>
                <img src="/file-icon.svg" />
                <p className='text-medium text-lg'>{file.name}</p>
                </div>
            <div>

          <Progress 
        className="flex items-baseline mt-8"
        percent={uploadProgress}
        showInfo={false}
        strokeColor="#497964"
        strokeWidth={5} />
        </div>
        </div>}
        

    </div>
    </div>
  )
}

export default NewFileUpload