import React from 'react';
import AvatarTemplate from './AvatareTemplate';


const Section = ({ heading, items,backGround,boxShadow,CardBg,border }) => {
  return (
    <div className="mt-[4%]">
      <h2 className="text-xl text-black font-medium mb-4">{heading}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  gap-4">
        {items.map((item, index) => (
          <AvatarTemplate key={index} index={index} number={item.number} title={item.title} backGround={backGround} boxShadow={boxShadow} CardBg={CardBg} border={border} heading={heading} />
        ))}
      </div>
    </div>
  );
};

export default Section;
