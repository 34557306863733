import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useState } from "react"
import swal from "sweetalert";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center"
};

export default function Modale({ open, handleClose, propData, setOpen, getData, selectedUserData }) {
  const [credits, setCredits] = useState(null)
  const [userData, setUserData] = useState({})
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');
  const intCredits = parseInt(credits);


  const handleChange = (e) => {
    setCredits(e.target.value);
  };

  // const mappedData = propData.map(item => ({
  //   email: item.email,
  //   organizationId: item.OrganizationId
  // }));

  // const emails = mappedData.map(item => item.email);
  // const organizationIds = mappedData.map(item => item.organizationId);
  // console.log(organizationIds[0],"orgnisational id ===== ");
  // console.log(mappedData,"maped data");
  // console.log(emails,"emails");

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/getorganizationcredits/?OrganizationId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setUserData(dataa);
        getData()
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
    getData()
  }, []);
  // console.log(userData,"userDatauserData");

  const postCredits = async () => {
    // console.log(intCredits, "intCredits intCredits");
    const data = {
      OrganizationId: selectedUserData?.OrganizationId,
      MemberId: selectedUserData?.Id,
      credits: intCredits,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/assigncredits/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data), // Convert data to a JSON string
      });

      if (response.ok) {
        if (intCredits <= 0) {
          swal({
            title: "Oh Snap!",
            text: "Credits must me greater then 0.",
            icon: "error",
            button: "Dismiss!",
          });
        } else {
          const result = await response.json();
          console.log(result, "result of post");
          setOpen(false)
          getData()
          fetchData()
        }
        // fetchData(); // Update userData after making a payment
      } else {
        if (response.status == 400) {
          swal({
            title: "Oh Snap!",
            text: "you Don't have enough credits.",
            icon: "error",
            button: "Dismiss!",
          });
        }
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };
  const creditInNumber = () => {
    swal({
      title: "Oh Snap!",
      text: "Credits should be in Numbers.",
      icon: "error",
      button: "Dismiss!",
    });
  }
  // console.log("selectedUserData :",selectedUserData);


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" sx={{ fontSize: "24px", fontWeight: "700", color: "black", fontFamily: "Gordita" }}>
            Assign Credits
          </Typography>
          <Typography id="modal-modal-description" sx={{ fontSize: "18px", fontWeight: "400", fontFamily: "Gordita", mt: 2 }}>
            Your remaining credits in the organization are {userData?.message?.toLocaleString()}
          </Typography>
          <Box sx={{ width: "100%", }}>
            <Box sx={{ mx: "auto", width: "50%", display: "flex", justifyContent: "center", flexDirection: "column" }} >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "30px" }}>
                <InputLabel sx={{ fontSize: "22px", fontWeight: "600", color: "black", fontFamily: "Gordita" }}>Enter Credits</InputLabel>
                <TextField id="outlined-basic" size='small' variant="outlined" onChange={handleChange} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "20px" }}>
                <InputLabel sx={{ fontSize: "22px", fontWeight: "600", color: "black", fontFamily: "Gordita" }}>Email</InputLabel>
                <TextField id="outlined-basic" size='small' variant="outlined" value={selectedUserData?.email} disabled />
              </Box>
              <Box my={"40px"}>
                <Button variant="contained" onClick={isNaN(parseInt(credits)) ? creditInNumber : postCredits}>Assign</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}