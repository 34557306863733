import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import BreadcrumbsComponent from "../../components/common/Breadcrumbs";
import AvataresContainer from "../../components/Classroom/AvataresContainer/AvataresContainer";

const TrainBySubject = () => {
  const [avatares, setAvatares] = React.useState([])
  const getData = async () => {
    console.log("Right before hitting")
      let userId = localStorage.getItem('UserId')
      return fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?UserId=` + userId,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then(
          data => data.json())
  }
  React.useEffect(() => {
    let mounted = true;
    getData()
      .then(items => {
        console.log("Items: ", items);
        if(typeof items.message != Object) {
          setAvatares(items.message);
        }
      })
    return () => mounted = false;
  }, [])
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        sx={{
          display: "flex",
          flexDirection: "column",
          // margin:"3vw"
        }}
      >
        <BreadcrumbsComponent Left="Classroom" Right="Train My Avatare By AI Model"/>
        <Box
          sx={{
            bgcolor: "#E1F4FF",
            height: "100%",
            width: "100%",
            display:"flex",
            justifyContent:"space-around",
            flexWrap: "wrap",
            alignItems:"center",
            my: "20px",
            py:"20px",
            borderStyle: "dashed",
            borderWidth: "1px",
            overflowY:"auto"
          }}
        >
          <Typography fontSize={24} sx={{ m: 2 }}>
            Please select your avatare to start training!
          </Typography>
          <AvataresContainer avatares={avatares} pageName={"trainBySubject"}/>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default TrainBySubject;