import { Box, Container, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Search } from "@mui/icons-material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import whiteLogo from "../../assets/img/logo/White Logo.svg";

const Help = () => {
    return (
        <>
            <Box sx={{ px: "0px", mx: "0px", width: "100%" }}>
                <Box sx={{ width: "100%", display: "flex",background: "radial-gradient(circle, rgba(70,104,255,0.9445028011204482) 0%, rgba(41,85,152,1) 100%)",  }}>
                    <Box sx={{ width: "60%", textAlign: "start", color: "white",}}>
                        <Box sx={{ width: "80%", mx: "auto", my: "30px",}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                                welcome to avatare help center
                            </Typography>
                            <Typography sx={{ fontSize: "30px", fontWeight: "700" }}>
                                what can we help you ?
                            </Typography>
                            <TextField
                                id="search"
                                type="search"
                                label="Search"
                                // value={searchTerm}
                                // onChange={handleChange}
                                sx={{ width: "100%", bgcolor: "white", mt: "50px", }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <Box width="40%" position={"relative"}>
                        <Box width={"80%"} mx="auto">
                        <img src={whiteLogo} alt="White Logo" style={{  marginTop: "50px",width:"100%",mx:"auto"}} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex" }}>
                    <Container maxWidth="md" sx={{ mt: "20px", display: "flex", justifyContent: "space-around" }}>
                        <Box width={"32%"}>
                            <Box width={"90%"} mx="auto">
                                    <Typography sx={{fontSize:"22px",fontWeight:"600",textAlign:"start",p:"10px 5px",boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"}}>Managing Your Accout</Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Accordion 1</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Accordion 2</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum dolor sit amet,..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>
                        <Box width={"32%"}>
                            <Box width={"90%"} mx="auto">
                            <Typography sx={{fontSize:"22px",fontWeight:"600",textAlign:"start",p:"10px 5px",boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"}}>Using Avatare</Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Accordion 1</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Accordion 2</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum dolor sit amet,..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>
                        <Box width={"32%"}>
                            <Box width={"90%"} mx="auto">
                            <Typography sx={{fontSize:"22px",fontWeight:"600",textAlign:"start",p:"10px 5px",boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"}}>About Payment</Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Accordion 1</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Accordion 2</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Lorem ipsum dolor sit amet,..
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>

                    </Container>
                </Box>

            </Box>
        </>
    )
}

export default Help