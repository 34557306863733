import { Grid, Grow } from "@mui/material";
import React from "react";
import { Routes, Route, Outlet } from "react-router-dom"
import Appbar from "../components/common/AppBar";
import OrgSideNav from "../components/common/OrgSideNav";

const Organization = () => {
  return (
    <Grid container >
      <Grid item sx={{width:"22%"}}>
        <OrgSideNav />
      </Grid>
      <Grid item xs={9} sx={{ backgroundColor: "" }}>
        <Appbar />
        <Outlet/>
      </Grid>
    </Grid>
  )
}

export default Organization