import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CustomizeBot = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState(true);
  const [activePrompt, setActivePrompt] = useState(false);
  const [activeQuestions, setActiveQuestions] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [singleBlockData, setSingleBlockData] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [activeBlock, setActiveBlock] = useState("1");
  const [newQuestion, setNewQuestion] = useState("");
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState(false);
  const [newPrompt, setNewPrompt] = useState("");
  const [reset, setReset] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_API_ROOT + "/api/ppt/get_questions",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("token")}`,
          },
        }
      );

      const result = await response.json();
      setBlocks(result);
      setSingleBlockData(result[0]);
    };

    fetchData();
  }, [reset]);

  const handleQuestionView = () => {
    setQuestions(true);
    setActiveQuestions(true);
    setActivePrompt(false);
  };

  const handlePromptView = () => {
    setQuestions(false);
    setActiveQuestions(false);
    setActivePrompt(true);
  };

  const handleInputClick = (index) => {
    setEditingIndex(index);
    setNewQuestion(singleBlockData.question[index]);
  };

  const handleUpdateClick = () => {
    if (newQuestion.trim() !== "") {
      const updatedQuestions = [...singleBlockData.question];
      updatedQuestions[editingIndex] = newQuestion;
      const updatedBlock = { ...singleBlockData, question: updatedQuestions };
      setSingleBlockData(updatedBlock);
      updateBlockData(updatedBlock);
      setEditingIndex(null);
      setNewQuestion("");
    }
  };

  const handleCancelClick = () => {
    setEditingIndex(null);
    setNewQuestion("");
    setAddingQuestion(false);
    setEditingPrompt(false);
    setNewPrompt("");
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = singleBlockData.question.filter(
      (_, i) => i !== index
    );
    const updatedBlock = { ...singleBlockData, question: updatedQuestions };
    setSingleBlockData(updatedBlock);
    updateBlockData(updatedBlock);
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim() !== "") {
      const updatedQuestions = [...singleBlockData.question, newQuestion];
      const updatedBlock = { ...singleBlockData, question: updatedQuestions };
      setSingleBlockData(updatedBlock);
      updateBlockData(updatedBlock);
      setNewQuestion("");
      setAddingQuestion(false);
    }
  };

  const handleDeleteBlock = () => {
    const updatedBlocks = blocks.filter(
      (block) => block.block_id !== singleBlockData.block_id
    );
    setBlocks(updatedBlocks);
    setSingleBlockData(null);
    setActiveBlock(null);
  };

  const handleClick = (block) => {
    if (blocks.length == block.block_id) {
      setHide(true);
    } else {
      setHide(false);
    }

    setSingleBlockData(block);
    setQuestions(true);
    setActiveBlock(block.block_id);
  };

  const updateBlockData = (updatedBlock) => {
    const updatedBlocks = blocks.map((block) =>
      block.block_id === updatedBlock.block_id ? updatedBlock : block
    );
    setBlocks(updatedBlocks);
  };

  const handleNewQuestionClick = () => {
    setAddingQuestion(true);
    setNewQuestion("");
  };

  const handlePromptClick = () => {
    setEditingPrompt(true);
    setNewPrompt(singleBlockData.prompt);
  };

  const handlePromptUpdateClick = () => {
    if (newPrompt.trim() !== "") {
      const updatedBlock = { ...singleBlockData, prompt: newPrompt };
      setSingleBlockData(updatedBlock);
      updateBlockData(updatedBlock);
      setEditingPrompt(false);
      setNewPrompt("");
    }
  };

  const handleUpdateSave = async () => {
    const payload = {
      block_list: blocks,
    };

    const formData = new FormData();
    formData.append("block_list", JSON.stringify(payload.block_list));

    const response = await fetch(
      process.env.REACT_APP_API_ROOT + "/api/ppt/customized_prompt",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      }
    );

    const result = await response.json();
    if (result) navigate("/classroom/template");
  };

  const handleReset = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_ROOT + "/api/ppt/reset_all",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("token")}`,
        },
      }
    );

    const result = await response.json();
    if (result) {
      setReset(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full">
        {/* <div className="flex justify-between ">
         
         
          </div>
        </div> */}

        <div className="flex">
          <div className="w-1/5 bg-white p-8">
            <h1 className="font-semibold text-center mt-1 mb-8">
              Total Blocks : {blocks.length}
            </h1>

            <div>
              {blocks.map((b, i) => (
                <div
                  key={b.block_id}
                  className={`cursor-pointer mb-4 p-2 text-center rounded text-sm font-semibold ${
                    activeBlock === b.block_id
                      ? "border-2 border-[#497964]"
                      : "border-2 border-dashed border-[#747474]"
                  }`}
                  onClick={() => handleClick(b)}
                >
                  Block {b.block_id}
                </div>
              ))}
            </div>
          </div>
          <div className="py-8 px-7  w-[80%]">
            <div className="flex items-end justify-end gap-4">
              <button
                className="px-4  py-2 text-white  primary cursor-pointer "
                onClick={handleUpdateSave}
              >
                Save Template
              </button>
              <button
                className="px-4 py-2  text-black secondary cursor-pointer"
                onClick={handleReset}
              >
                Reset Template
              </button>
            </div>

            <div className="mt-5">
              {singleBlockData && (
                <div
                  style={{ borderColor: "rgba(0, 0, 0, 0.50)" }}
                  className="bg-white p-8 rounded-md border"
                >
                  {questions ? (
                    <div>
                      <div className="w-full flex justify-end items-center">
                        <h2 className="text-lg text-[#1A1A1A] font-semibold  2xl:mr-[28%] xl:mr-[22%] lg:mr-[18%]">
                          Block {activeBlock}
                        </h2>
                        <div className="flex justify-end">
                          <button
                           
                            className="px-5 py-2 text-sm primary rounded-tl-[23px] rounded-bl-[23px]  cursor-pointer"
                            onClick={handleQuestionView}
                          >
                            Question View
                          </button>
                          <button
                           
                            className={`px-5 py-2  rounded-tr-[23px] text-sm rounded-br-[23px]  secondary cursor-pointer`}
                            disabled={!singleBlockData.prompt}
                            onClick={handlePromptView}
                          >
                            Prompt View
                          </button>
                        </div>
                      </div>

                      {singleBlockData?.question?.map((q, index) => (
                        <div key={index} className="mb-4 mt-4">
                          <div className="flex flex-col">
                            <label className="block  font-semibold mb-4">
                              Question {index + 1}:
                            </label>
                            <div onClick={() => handleInputClick(index)}>
                            <input
                              type="text"
                              placeholder={q}
                              style={{border:"1px solid rgba(116, 116, 116, 0.50)"}}
                              className="w-full p-2 text-base rounded bg-[#FAFAFA] cursor-pointer custom-outline"
                              value={editingIndex === index ? newQuestion : q}
                              onChange={(e) => setNewQuestion(e.target.value)}
                            />
                          </div>
                            <button
                              className="text-[#E94444] self-end mt-1 underline underline-offset-4"
                              onClick={() => handleDeleteQuestion(index)}
                            >
                              Delete this Question
                            </button>
                          </div>
                        
                          {editingIndex === index && (
                            <div >
                              <button
                                className="px-4 py-2 text-white primary cursor-pointer mr-2"
                                onClick={handleUpdateClick}
                              >
                                Update
                              </button>
                              <button
                                className="px-4 py-2  text-black secondary cursor-pointer"
                                onClick={handleCancelClick}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      {addingQuestion && (
                        <div className="mb-4 mt-4">
                          <div className="flex justify-between">
                            <label className="block font-semibold mb-4">
                              New Question:
                            </label>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter new question"
                              style={{border:"1px solid rgba(116, 116, 116, 0.50)"}}
                              className="w-full p-2 text-base rounded bg-[#FAFAFA] cursor-pointer custom-outline"
                              value={newQuestion}
                              onChange={(e) => setNewQuestion(e.target.value)}
                            />
                          </div>
                          <div className="mt-5">
                            <button
                              className="px-4 py-2 text-white primary cursor-pointer mr-2"
                              onClick={handleAddQuestion}
                            >
                              Add Question
                            </button>
                            <button
                              className="px-4 py-2  text-black secondary cursor-pointer"
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}

                      <button
                        className="mb-5 py-2 px-3  secondary  text-black"
                        onClick={handleNewQuestionClick}
                      >
                        Add More Questions
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="w-full flex justify-end items-center">
                      <h2 className="text-lg text-[#1A1A1A] font-semibold  2xl:mr-[28%] xl:mr-[22%] lg:mr-[18%]">
                          Block {activeBlock}
                        </h2>
                        <div className="flex justify-end">
                          <button
                           className={`px-5 py-2  rounded-tl-[23px] text-sm rounded-bl-[23px]  secondary cursor-pointer`}
                            onClick={handleQuestionView}
                          >
                            Question View
                          </button>
                          <button
                           
                            className="px-5 py-2 text-sm rounded-tr-[23px] rounded-br-[23px] primary cursor-pointer"
                            onClick={handlePromptView}
                          >
                            Prompt View
                          </button>
                        </div>
                      </div>
                      <div className="mb-4"></div>
                      <div className="mb-4">
                        <textarea
                          type="text"
                          rows={10}
                          value={
                            editingPrompt ? newPrompt : singleBlockData?.prompt
                          }
                          placeholder={singleBlockData?.prompt}
                          className="w-full p-8 border rounded bg-gray-50"
                          onChange={(e) => setNewPrompt(e.target.value)}
                          readOnly={!editingPrompt}
                        />
                        {editingPrompt && (
                          <div className="mt-3">
                            <button
                              className="px-4 py-2 text-white primary cursor-pointer mr-2"
                              onClick={handlePromptUpdateClick}
                            >
                              Update
                            </button>
                            <button
                              className="px-4 py-2 text-black secondary bg-white cursor-pointer"
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                      {!editingPrompt && (
                        <button
                          className="px-4 py-2 link-color underline underline-offset-4"
                          onClick={handlePromptClick}
                        >
                          Edit Prompt
                        </button>
                      )}
                    </div>
                  )}
                  {!hide && (
                    <button
                      className="link-color underline underline-offset-4"
                      onClick={handleDeleteBlock}
                    >
                      Delete this Block
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeBot;
