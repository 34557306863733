import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const FolderComponent = (props) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const popupRef = useRef();

  const handleFolderClick = (data) => {
    navigate(`/library/dataFiles/files/${data.DataId}/${data.Name}`);
  };

  const togglePopup = (index) => {
    setClickedIndex(index);
    setIsOpen(!isOpen);
  };

  const toggleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        toggleClose();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="mt-3">
      <div className="flex justify-between w-full items-center">
        <div>
          <h1 className="font-semibold">{props.text}</h1>
          <p className="text-black text-opacity-70">{props.subText}</p>
        </div>
        {props.showButton && (
          <button
            onClick={() =>
              props.handleNewClick(
                props.text,
                "Add folder name here",
                "Please create a folder first",
                "Create New Folder"
              )
            }
            className="primary py-2 px-4 text-sm"
          >
            {props.buttonText}
          </button>
        )}
      </div>
      <div className="mt-9">
        <div className="flex gap-6 flex-wrap lg:w-full">
          {props.data &&
            props.data.map((data, index) => (
              <div
                className="flex flex-col gap-0.5 justify-center items-center lg:w-[15%] min-w-[169px] h--auto py-4 border border-black border-opacity-[0.17] bg-[#F7F8FC] shadow-md rounded-[4px] hover:border-[#497964]"
                onClick={(e) => {
            e.stopPropagation(); // Prevent the event from bubbling
            handleFolderClick(data);
          }}
              >
                <div className="relative self-end mr-4">
                  <img
                    src="/threeIcon.svg"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePopup(index);
                    }}
                    className="w-[6px]  cursor-pointer"
                  />
                  {isOpen && clickedIndex === index && (
                    <div
                      ref={popupRef}
                      className="absolute -left-14  w-36 bg-white rounded-sm shadow-lg"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          props.handleDeleteClick(data);
                        }}
                        className="flex gap-3 border-b-[1.5px] p-3 border-b-black border-opacity-30 cursor-pointer"
                      >
                        <img src="/delete.svg" />
                        <p className="font-semibold text-sm text-black text-opacity-75">
                          Delete Folder
                        </p>
                      </div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the event from bubbling
                          props.handleEditClick(data);
                        }}
                        className="flex gap-3 border-b-[1px] p-3 cursor-pointer"
                      >
                        <img src="/edit.svg" />
                        <p className="font-semibold text-sm text-black text-opacity-75">
                          Edit Folder
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <img
                  src={props.fileIcon}
                  className="w-[35px] h-auto cursor-pointer"
                />
                <h1 className="text-[14px]   mt-3 font-semibold">
                  {data?.Name ? data.Name : data?.SourceName}
                </h1>
              </div>
            ))}
          {props.showButton && (
            <div
              onClick={() =>
                props.handleNewClick(
                  props.text,
                  "Add folder name here",
                  "Please Create folder first",
                  "Create New Folder"
                )
              }
              className="flex flex-col gap-0.5 justify-center items-center lg:w-[15%] min-w-[169px]  h--auto py-4 border border-black border-opacity-[0.17] bg-[#F7F8FC] shadow-md rounded-[4px] hover:border-[#497964]"
            >
              <img
                src={props.newIcon}
                className="w-[35px] h-auto mt-4 cursor-pointer"
              />
              <h1 className="text-[14px]   mt-4 font-semibold">
                {props.cardInnertext}
              </h1>
            </div>
          )}
        </div>
      </div>
      {props.showLine && (
        <div className="mt-12">
          <div className="w-full h-[0.5px] bg-black bg-opacity-60"></div>
        </div>
      )}
    </div>
  );
};

export default FolderComponent;
