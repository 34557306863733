import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import swal from "sweetalert";

function EnhancedTable({ setOpen ,Id}) {
  const token = localStorage.getItem("token")
  const UserId = localStorage.getItem("UserId")
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])


  const handleSearch = async () => {
          try {
              const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/searchdialogues/?UserId=${UserId}&Question=${search}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              },
          });
          if (response.ok) {
              const data = await response.json();
              setSearchData(data);
          } else {
              const error = await response.json();
              swal({
                  title: "We are sorry",
                  text: error?.message,
                  icon: "error",
                  button: "Dismiss!",
              });
          }
      } catch (error) {
          console.log(error);
      }
  }
  const handleAddAnswer = async (answer) => {
      const data = {
          Id:Id,
          Answer:answer,
      }
          try {
              const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/data/addQAmanually/`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              },
              body : JSON.stringify(data)
          });
          if (response.ok) {
              const data = await response.json();
              swal({
                  title: "Updated",
                  text: "data successfully added",
                  icon: "success",
                  button: "Dismiss!",
              });

          } else {
              swal({
                  title: "We are sorry",
                  text: "something went wrong",
                  icon: "error",
                  button: "Dismiss!",
              });
          }
      } catch (error) {
          console.log(error);
      }
  }
  // console.log("searchData.message :" , searchData.message);

  return (
    <Box>
    <Box>
        <Paper
            // component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: "#E1F4FF" }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search ..."
                inputProps={{ 'aria-label': 'search ...' }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => setSearch("")}>
                <Close />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleSearch}>
                <SearchIcon />
            </IconButton>
        </Paper>
    </Box>
    <Box sx={{ my: "10px", overflow: "auto", position: "relative", maxHeight: "50vh" }}>
        {Array.isArray(searchData?.message) && searchData?.message?.length > 0 ? (
            searchData.message.map((data, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "100%",
                        bgcolor: "#E1F4FF",
                        border: "1px solid #737272",
                        my: "10px",
                    }}
                >
                    <Typography sx={{ fontSize: "20px", fontWeight: "400", m: "10px" }}>
                      <b> Question : </b>{data?.Question}
                    </Typography>
                    <Typography sx={{ fontSize: "20px", fontWeight: "400", m: "10px" }}>
                      <b> Answer : </b>{data?.Answer}
                    </Typography>
                    <Box sx={{display:"flex",justifyContent:"end",alignItems:"end"}}>
                        <Button onClick={()=>handleAddAnswer(data?.Answer)} sx={{ bgcolor: "#295598", color: "white", m: "10px", fontSize: "20px", fontWeight: "500", textTransform: "capitalize", ':hover': { bgcolor: '#295580', color: 'white', }, }}>
                            Add Answer
                        </Button>
                        {/* <Typography sx={{mx:"10px"}}>
                            View full document <b>{data?.SourceId !== null ? data?.SourceId?.SourceName : data?.DataId?.Name}</b>
                        </Typography> */}
                    </Box>
                </Box>
            )
            ))
            : (
                <Box sx={{ width: "100%", mx: "auto" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "400", m: "10px" }}>
                        No Answers found
                    </Typography>
                </Box>
            )
        }
    </Box>
</Box>
  );
}

export default EnhancedTable;
