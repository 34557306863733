import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AvatarTemplate = ({ number, title, backGround, heading,boxShadow,CardBg,border,index }) => {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const hoverStyles = {
    background: isHovered ? CardBg : "white", // Change background on hover
    border: isHovered
      ? border
      : "1px solid rgba(0, 0, 0, 0.15)", // Change border on hover
  };
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/sequential-startup/template`, { state: { index: index ,heading :heading,title:title ,backGround:backGround,boxShadow:boxShadow ,number:number,newChat:true } })}
      style={{ ...hoverStyles }}Keep Separate Spending Records
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="bg-white shadow-md rounded-md  border p-4 flex items-center gap-4  hover:bg-gray-100 cursor-pointer"
    >
      <div
        style={{ background: backGround, boxShadow: boxShadow }}
        className="text-lg flex items-center justify-center font-bold w-[34px] h-[34px] text-white rounded-full"
      >
        {number}
      </div>
      <div className="flex flex-col  text-start justify-start w-[300px]">
        <div className="text-black text-lg font-medium">{title}</div>
        <div className="text-sm text-gray-500">
          Avatare will be based on templates
        </div>
      </div>
    </div>
  );
};

export default AvatarTemplate;
