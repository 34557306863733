import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const style = {
  width: "100%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display:"flex",
  justifyContent:"center",
  flexDirection:"column",
  textAlign:"center",
};

const HistoryModel = () => {
  const navigate = useNavigate()
    const location = useLocation()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); 
    const historyData=location?.state


      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
const handleBack = () =>{
  navigate("/organization/team")
}
  return (
    <div>
      <Box sx={style}>
        <Box sx={{display:"flex",alignItems:"center"}}>
        <Button id="modal-modal-title" sx={{fontSize:"18px"}} onClick={handleBack}>
          <ArrowBackIcon /> Back
        </Button>
        <Typography id="modal-modal-title" sx={{fontSize:"24px",fontWeight:"700",color:"black",fontFamily:"Gordita",my:"20px",textAlign:"center",mx:"auto"}}>
          History
        </Typography>
        </Box>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650,overflow:"auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >ID</TableCell>
            <TableCell align="center">Assigned By</TableCell>
            <TableCell align="center">Assigned To</TableCell>
            <TableCell align="center">Assigned Credits</TableCell>
            <TableCell align="right">Assigned Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {historyData?.message?.length > 0 ? (
    historyData?.message?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => (
        <TableRow
          key={row?.Id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell>{row?.Id}</TableCell>
          <TableCell align="center">{row?.AssignedBy}</TableCell>
          <TableCell align="center">{row?.AssignedTo}</TableCell>
          <TableCell align="center">{row?.AssignedCredits}</TableCell>
          <TableCell align="right">{row?.AssignedDate}</TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow>
      <TableCell colSpan={5} align="center">
        No data available.
      </TableCell>
    </TableRow>
  )}
</TableBody>
      </Table>
    </TableContainer>
    <TablePagination
            rowsPerPageOptions={[5, 10, 25]} 
            component="div"
            count={historyData?.message?.length || 0} 
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Box>
  </div>
  )
}

export default HistoryModel