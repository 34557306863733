import React from "react";

export const FileUploader = (props) => {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-1.5">
      <h1 className="font-medium text-lg">{props.text}
       </h1>
      <p>{props.desc}</p>
      <div
        style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}
        className="mt-[3%] w-full flex flex-col justify-center items-center py-8 gap-2 border border-dashed cursor-pointer border-spacing-60"
        aria-label="attach"
      >
        {!props.uploadedFile ? 
        <>
        <input
          accept={props.accept}
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={props.handleFileUpload}
        />
        <label htmlFor="file-input">
            <img src={props.icon} />
        </label>
        <h1 style={{ color: "rgba(0, 0, 0, 0.70)" }} className="text-sm">
          Drag & Drop files here or
          <span className="text-black mx-1 font-medium  text-sm underline underline-offset-2">  
            Browse
          </span>
          here
        </h1>
        <h1 style={{ color: "rgba(0, 0, 0, 0.70)" }} className="text-xs">
        Supported files :{props.supportedFiles}   </h1>
        </>
        : <h1 style={{ color: "rgba(0, 0, 0, 0.70)" }} className="text-lg font-medium">
        {props.uploadedFile.name}
       
      </h1>}
      </div>
    </div>
  );
};
