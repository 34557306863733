import React from 'react'

export const Description = (props) => {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-1.5">
      <h1 className="font-medium text-lg">Add Description to your Avatare (optional)</h1>
      <p className='text-sm'>Description can be added to make it more brief</p>
      <div
        style={{ borderColor: "rgba(0, 0, 0, 0.20)" }}
        className="mt-[3%] w-full p-2 px-4 gap-2 border  cursor-pointer rounded border-spacing-60"
      >
        <textarea
          placeholder="Add description here"
          type="text"
          className='w-full text-sm outline-none'
          rows={6}
          onChange={(e)=>props.setDescription(e.target.value)}
          value={props.description}
        />
       
      </div>
    </div>
  )
}
