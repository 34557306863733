const TrackorComponent = (props) => {
    console.log(props)
  return (

    <div>
      <div className=" relative">
        

        <div className="flex flex-row items-center">
          <div
            className={`${
              props.showFirst ? "" : " opacity-0"
            } z-10 rounded-full border-[2px] border-white w-[120px] h-[120px] grid grid-cols-1`}
          >
            <h1 className=" col-span-1 flex justify-center items-center bg-[#6CA6C4] rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]">
              Start with Problem
            </h1>
            <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 text-[#20556E] font-semibold text-2xl">
              A
            </h2>
          </div>

          <div
            className={`${
              props.showFirst ? "" : ""
            } relative z-0 ml-[-10px] w-24 h-12 bg-tracker-line-1`}
           
          >
            <div
              className=" bg-tracker-circle-1 text-white font-semibold text-[21px] h-[54px] w-[54px] absolute left-5 -top-[3px] border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              01
              <div className=" absolute  left-0 -top-[60px]">
                <h1 style={{color:props?.data?.block1?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10 -top-1 underline underline-offset-8`}>
                  {props?.data?.block1?.text}
                </h1>
                <div style={{background:props?.data?.block1?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>

          <div
            className="bg-tracker-line-2 h-12 w-32 flex flex-row items-center"
           
          >
            <div
              className="ml-[0.5px] z-20 h-12 w-12 custom-arrow rotate-90 bg-tracker-arrow-1"
              
            ></div>

            <div
              className="bg-tracker-circle-2 relative ml-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              02
              <div className="absolute left-0 top-[55px]">
              <h1 style={{color:props?.data?.block2?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10  underline underline-offset-8`}>
                  {props?.data?.block2?.text}
                </h1>
                <div style={{background:props?.data?.block2?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>

          <div
            className="bg-tracker-line-1 h-12 w-32 flex flex-row items-center"
           
          >
            <div
              className=" ml-[0.5px] z-20 h-12 w-12 custom-arrow rotate-90 bg-tracker-arrow-2"
             
            ></div>

            <div
              className="bg-tracker-circle-3 relative ml-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              03
              <div className=" absolute left-0 -top-[60px]">
              <h1 style={{color:props?.data?.block3?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10 -top-1 underline underline-offset-8`}>
                  {props?.data?.block3?.text}
                </h1>
                <div style={{background:props?.data?.block3?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>

          <div
            className="bg-tracker-line-2 h-12 w-32 flex flex-row items-center"
          
          >
            <div
              className=" ml-[0.5px] z-20 h-12 w-12 custom-arrow rotate-90 bg-tracker-arrow-1"
             
            ></div>

            <div
              className="bg-tracker-circle-1 relative ml-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              04
              <div className="absolute left-0 top-[55px]">
              <h1 style={{color:props?.data?.block4?.textColor,fontSize:"16px"}}className={`absolute w-[170px] left-10  underline underline-offset-8`}>
                  {props?.data?.block4?.text}
                </h1>
                <div style={{background:props?.data?.block4?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>

          <div
            className="bg-tracker-line-1 h-12 w-32 flex flex-row items-center"
           
          >
            <div
              className="bg-tracker-arrow-2 ml-[1px] z-20 h-12 w-12 custom-arrow rotate-90"
             
            ></div>

            <div
              className="bg-tracker-circle-2 relative ml-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              05
              <div className=" absolute left-0 -top-[60px]">
              <h1 style={{color:props?.data?.block5?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10 -top-1 underline underline-offset-8`}>
                  {props?.data?.block5?.text}
                </h1>
                <div style={{background:props?.data?.block5?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>
        </div>
        <div className=" absolute right-0 flex flex-row items-center justify-end">
          <div className=" absolute -right-[88px] -top-[168px]">
            <img className="h-[400px]" src="/1ST.svg" alt="curve" />
            <div
              className="bg-tracker-arrow-1 absolute right-[43px] top-[86px] ml-[1px] z-20 h-11 w-11 custom-arrow rotate-90"
             
            ></div>

            <div className=" absolute -right-[40px] top-[156px] z-10 rounded-full border-[2px] border-white w-[120px] h-[120px] grid grid-cols-1">
              <h1 className=" col-span-1 flex justify-center items-center bg-[#497964] rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]">
                Exploring the Problem
              </h1>
              <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 text-[#497964] font-semibold text-2xl">
                B
              </h2>
            </div>
          </div>
        </div>

        <div className="mt-[100px] flex justify-end items-center">
          <div
            className="bg-tracker-line-1 h-12 w-[115px] flex flex-row items-center justify-end"
          >
            <div
              className="bg-tracker-circle-1 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              10
              <div className="absolute left-0 top-[55px]">
              <h1 style={{color:props?.data?.block10?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10  underline underline-offset-8`}>
                  {props?.data?.block10?.text}
                </h1>
                <div style={{background:props?.data?.block10?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
              
            </div>
            <div
              className="bg-tracker-arrow-2 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"
              
            ></div>
          </div>
          <div
            className="bg-tracker-line-2 h-12 w-32 flex flex-row items-center justify-end"
           
          >
            <div
              className="bg-tracker-circle-2 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              09
              <div className=" absolute left-0 -top-[60px]">
               <h1 style={{color:props?.data?.block9?.textColor,fontSize:"16px"}}className={`absolute w-[270px] right-0 -top-2 underline underline-offset-8`}>
                  {props?.data?.block9?.text}
                </h1>
                <div style={{background:props?.data?.block9?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
            <div
              className=" bg-tracker-arrow-1 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"
              
            ></div>
          </div>
          <div
            className="bg-tracker-line-1 h-12 w-32 flex flex-row items-center justify-end"
            
          >
            <div
              className="bg-tracker-circle-3 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              08
              <div className="absolute left-0 top-[55px]">
              <h1 style={{color:props?.data?.block8?.textColor,fontSize:"16px"}}className={`absolute w-[230px] left-10  underline underline-offset-8`}>
                  {props?.data?.block8?.text}
                </h1>
                <div style={{background:props?.data?.block8?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
            <div
              className="bg-tracker-arrow-2 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"
             
            ></div>
          </div>

          <div
            className="bg-tracker-line-2 h-12 w-32 flex flex-row items-center justify-end"
           
          >
            <div
              className="bg-tracker-circle-2 relative mr-2 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              07
              <div className=" absolute left-0 -top-[60px]">
              <h1 style={{color:props?.data?.block7?.textColor,fontSize:"16px"}}className={`absolute w-[230px] right-2 -top-2 underline underline-offset-8`}>
                  {props?.data?.block7?.text}
                </h1>
                <div style={{background:props?.data?.block7?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
            <div
              className="bg-tracker-arrow-1 mr-[1px] z-20 h-12 w-12 custom-arrow -rotate-90"
             
            ></div>
          </div>
          <div
            className="bg-tracker-line-1 h-12 w-28 flex flex-row items-center justify-end"
           
          >
            {/* <div className=" ml-[1px] z-20 h-12 w-12 custom-arrow rotate-90"
                        style={{ background: "#EB803C" }}
                    >

                    </div> */}

            <div
              className="bg-tracker-circle-1 relative mr-5 text-white font-semibold text-[21px] h-[54px] w-[54px]  border-white border-[2px] rounded-full flex justify-center items-center p-2"
             
            >
              06
              <div className="absolute left-0 top-[55px]">
              <h1 style={{color:props?.data?.block6?.textColor, fontSize:"16px"}}className={`absolute  w-[230px] left-10  underline underline-offset-8 `}>
                  {props?.data?.block6?.text}
                </h1>
                <div style={{background:props?.data?.block6?.lineColor}}className={`w-[3px]  h-[52px] ml-5`}></div>
              </div>
            </div>
          </div>
        </div>

        <div className=" absolute left-[108px] top-[370px] flex flex-row items-center justify-end rotate-180">
          <div className=" absolute -right-[88px] -top-[168px]">
            <img className="h-[400px]" src="/1ST.svg" alt="curve" />
            <div
              className="bg-tracker-arrow-1 absolute right-[41px] top-[271px] ml-[0.5px] z-20 h-11 w-12 custom-arrow rotate-90"
             
            ></div>

            {props.last &&<div className=" rotate-180 absolute -right-[40px] top-[156px] z-10 rounded-full border-[2px] border-white w-[120px] h-[120px] grid grid-cols-1">
              <h1 className=" col-span-1 flex justify-center items-center bg-[#7FB04F] rounded-tr-full rounded-tl-full text-center text-xs p-2 text-white border-b-white border-b-[2px]">
                Plan the mission
              </h1>
              
              <h2 className=" bg-[#D9D9D9] rounded-br-full rounded-bl-full col-span-1 flex justify-center items-center p-2 text-[#386FA0] font-semibold text-2xl">
                C
              </h2>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackorComponent;
