import React, { useEffect, useState } from "react";
import { Typography, Menu, MenuItem, MenuList, Divider, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./AvatareModelTraining.module.css";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import AvatarCreditModel from "./AvatarCreditModel";
import MembersModel from "./MembersModel";
import ShareIcon from '@mui/icons-material/Share';

const AvatareModelTraining = ({
  BotId,
  name,
  description,
  DataIds,
  button_message,
  emoji,
  pageName,
  SetRerender,
  trainmymodel,
  analytics,
  totalAvatars,
  AllCredits,
  SelectModel,
  setChangeCredits,
  getData
}) => {
  /* eslint eqeqeq: 0 */
  // const [title, setTitle] = React.useState(name);
  const [buttonMess, setButtonMess] = React.useState(button_message);
  // const [responseMessage, setResponseMessage] = React.useState("");
  const botEmoji = "Whatever";
  const [rerender, setRerender] = React.useState(true);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentScreen = window.location.href.split("/")[4];
  const botData = {
    BotId: BotId,
    BotName: name,
    BotDescription: description,
    emojiS3Link: emoji,
  };
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('UserId');
  const [open, setOpen] = React.useState(false);
  const [openmember, setOpenmember] = React.useState(false);
  const [shareBotId, setShareBotId] = React.useState(null);
  const [modelStaus, setModelStatus] = useState()
  const [modelData, setModelData] = useState({})
  const [totalCredits, setTotalCredits] = useState()
  const handleCloseModel = () => setOpen(false);
  const handleClosememberModel = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    setOpenmember(false)
  };

  const navigate = useNavigate();
  // console.log("shareBotId :",shareBotId );

  const fetchTotalCredits = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/auth/getcredits/?Id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const dataa = await response.json();
        setTotalCredits(dataa);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  React.useEffect(() => {
    fetchTotalCredits();
  }, []);

  // console.log("total credits",totalCredits);

  useEffect(() => {
    let currentWindow = window.location.href.split("/")[4];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    DataIds = DataIds ? DataIds : [1];
    if (currentWindow == "trainBySubject") {
      // console.log("Bot id is : ", BotId);
      fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/?BotId=${BotId}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then((data) => data.json())
        .then(function (data) {
          console.log(data);
          setButtonMess(data.message);
          if (data.message == "Training" || data.message == "In Queue") {
            setTimeout(() => {
              // console.log("5 seconds have passed!");
              setRerender(!rerender);
            }, 5000);
          } else if (data.message == "Completed") {
            setButtonMess("Re-train");
          } else {
            setButtonMess("Select Training Subject");
          }
          return data;
        });
    } else if (currentWindow == "integrations") {
      setButtonMess("Integrate");
    }
    else if (currentWindow == "analytics") {
      setButtonMess("Analytics");
    }
    else {
      console.log("Bot id is : ", BotId);
      console.log("Windows href is: ", window.location.href.split("/")[4]);

      fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/?BotId=${BotId}`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then((data) => data.json())
        .then(function (data) {
          console.log(data);
          setButtonMess(data?.message);
          if (data?.message == "Training" || data.message == "In Queue") {
            setTimeout(() => {
              console.log("5 seconds have passed!");
              setRerender(!rerender);
            }, 5000);
          } else if (data.message == "Completed") {
            setButtonMess("Re-train");
          }
          return data;
        });
    }
  }, [rerender]);
  
  const handleClick = async (e) => {
    let currentWindow = window.location.href.split("/")[4];
    // console.log("fdvdscadce asld;jhfklasdjfkljasd", buttonMess, currentWindow, e.target.textContent, botData);
    if (buttonMess === "Name your avatare") {
      window.location = "/admin/createNewAvatar";
    }
    else if (buttonMess == "Analytics") {
      const Botdata = {
        BotId: BotId,
        ModelType: "es",
        name: name,
        BotEmoji: emoji,
        description: description,
        totalAvatars: totalAvatars,
      };
      navigate("/operations/monitoringandcontrol", { state: Botdata });
    }
    else if (buttonMess === "Start Training" || buttonMess === "Re-train") {
      if (currentWindow == "createAvatar" || currentWindow == "trainMyModel") {
        let body = {
          BotId: BotId,
          ModelType: "es",
          UserId: localStorage.getItem('UserId'),
        };
        fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          body: JSON.stringify(body),
          cache: "default",
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.message == "Task started") {
              console.log("here1111",data , typeof DataIds)
                if(typeof DataIds === "undefined" && currentWindow == "trainMyModel"){
                  navigate("/admin/createAvatar")
                }else{
                  typeof DataIds === "undefined"
                    ? navigate("/classroom/trainMyModel", {
                      state: { botData: botData, buttonMessage: "Training" },
                    })
                    : navigate("/classroom/trainBySubject");
                }
            } else if (
              data.message == "No dataset found to start training!"
            ) {
              swal({
                title: "We are sorry",
                text: data.message,
                icon: "error",
                button: "Dismiss!",
              });
            }
            return data;
          });

      }
      if (currentWindow == "selectModel") {
        // console.log("here12121" , DataIds)
        if (DataIds) {
          let body = {
            BotId: BotId,
            ModelType: "es",
            DataIds: DataIds,
            UserId: localStorage.getItem('UserId'),
          };
          console.log(body);
          fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body),
            cache: "default",
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.message == "Task started") {
                typeof DataIds === "undefined"
                  ? navigate("/classroom/trainMyModel", {
                    state: { botData: botData, buttonMessage: "Training" },
                  })
                  : navigate("/classroom/trainBySubject");
              } else if (
                data.message == "No dataset found to start training!"
              ) {
                swal({
                  title: "We are sorry",
                  text: data.message,
                  icon: "error",
                  button: "Dismiss!",
                });
              }
              return data;
            });
        } else {
          console.log("trainmodal")
          let body = {
            BotId: BotId,
            ModelType: "es",
            UserId: localStorage.getItem('UserId'),
          };
          fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/trainbot/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body),
            cache: "default",
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.message == "Task started") {
                typeof DataIds === "undefined"
                  ? navigate("/classroom/trainMyModel", {
                    state: { botData: botData, buttonMessage: "Training" },
                  })
                  : navigate("/classroom/trainBySubject");
              } else if (
                data.message == "No dataset found to start training!"
              ) {
                swal({
                  title: "We are sorry",
                  text: data.message,
                  icon: "error",
                  button: "Dismiss!",
                });
              }
              return data;
            });
        }
      }
    } else if (e.target.textContent == "Completed") {
      e.stopPropagation();
    } else if (e.target.textContent == "Integrate") {
      window.location.href = "/operations/selectIntegration/" + BotId;
    }
    else if (window.location.href == "createAvatar") {
      console.log("retrain")
    }
  };
  const options = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdown(true);
    setAnchorEl(e.currentTarget);
  };

  const shareById = (e) => {
    e.stopPropagation();
    setShareBotId(botData)
    setOpenmember(true)
    // console.log("share bot :",BotId);
  }

  const editById = (e) => {
    if (e) {
      e.stopPropagation();
    }
    console.log(botData)
    navigate("/admin/editAvatar", {
      state: { botData: botData },
    })
  };
  const deleteById = async (e) => {
    if (e) {
      e.stopPropagation();
    }
    console.log("Bot ID is: ", botData.BotId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/api/bot/createbot/?BotId=` + botData.BotId,
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          method: "DELETE",
        }
      );
      console.log(response);
      // const result = await response.json();
      if (response.status != 200 && response.status != 201) {
        swal({
          title: "Oh Snap!",
          text: "Bot could not be deleted!",
          icon: "error",
          button: "Dismiss!",
        });
      } else {
        SetRerender(true);
        swal({
          title: "Success",
          text: "Bot has been deleted!",
          icon: "success",
          button: "Dismiss!",
        });
        getData()
        handleClose()
      }
    } catch (e) {
      console.log(e);
    }
    getData()
  };
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    setOpenDropdown(false);
  };

  const postCredits = async () => {
    // setChangeCredits(false)
    const data = {
      BotId: BotId,
      ModelType: "es",
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/bot/checkcreditsfortraining/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        console.log(result, "result of post on train model");
        setModelData(result)
        setModelStatus(response.status)
        setOpen(true)
        // AllCredits()
        // setChangeCredits(true)
      } else {
        const result = await response.json();
        // totalCredits.message == 0 ? 
        swal({
          title: "Ohh Snap!",
          text: result?.message,
          // text: "make sure you have data model",
          icon: "error",
          button: "Dismiss!",
        })
      }
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  return (
    <>
      <AvatarCreditModel open={open} handleCloseModel={handleCloseModel} modelData={modelData} modelStaus={modelStaus} setOpen={setOpen} handleClick={handleClick} />
      <MembersModel openmember={openmember} handleClosememberModel={handleClosememberModel} setOpenmember={setOpenmember} shareBotId={shareBotId} />
      <Box width="25%" p={"0px 15px"} sx={{textAlign:"center"}}>
        <Card className={styles.outermostCard}>
          {buttonMess == "Re-train" || buttonMess == "Completed" ? (
            <div className={styles.bannerCompleted}>Completed!</div>
          ) : buttonMess == "Training" || buttonMess == "In Queue" ? (
            <>
              <div className={styles.bannerTraining}>Training!</div>
            </>
          ) : buttonMess == "Integrate" ? (
            <>
              <div className={styles.bannerCompleted}>Completed!</div>
            </>
          )
            : buttonMess == "Analytics" ? (
              <></>
            )
              :
              <><div className={styles.bannerStart}>Untrained!</div></>
          }

          {/* <CardActionArea sx={{ align: "right",position:"relative" }}> */}
          {currentScreen != "selectModel" && buttonMess !== "Analytics" && trainmymodel !== true ? (
            <>
              <MoreVertIcon
                className={styles.vertIcon}
                sx={{ cursor: "pointer" }}
                onClick={(e) => options(e)}
              />
              <Menu
                id="basic-menu"
                open={openDropdown}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorEl={anchorEl}
                keepMounted={true}
              >
                <MenuList dense>
                  <MenuItem onClick={editById}>
                    <EditIcon />
                    <Typography ml={1}>Edit</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={deleteById}>
                    <DeleteIcon />
                    <Typography ml={1}>Delete</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={(e) => shareById(e)}>
                    <ShareIcon />
                    <Typography ml={1}>Share</Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <></>
          )}
          <CardMedia
            onClick={(e) => { SelectModel(BotId) }}
            className={analytics == "analytics" ? styles.cardMediaanayltics : pageName == "TrainMyModel" ? styles.cardMediaTraimModel : styles.cardMedia}
            sx={buttonMess == "Training" && { display: "flex" }}
            style={{ cursor: "pointer" }}
          >
            {buttonMess == "Training" ? (
              <>
                <div className="spinner-container" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div className="loading-spinner" style={{ margin: "auto", display: "flex", justifyContent: "center", width: "100%" }}>
                    {emoji == null ? (
                      <>
                        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", mx: "auto" }}>
                          <AccountCircleIcon className={styles.AccountCircleIcon} sx={{ mx: "auto" }} />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", }}>
                          <img
                            alt=" "
                            className={styles.loaderImage}
                            src={emoji}

                            style={{ borderRadius: "50%", objectFit: "cover", height: "55px", width: "55px" }}
                          />
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {emoji ? (
                  <>
                    <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", }}>
                      <img alt=" " src={emoji} style={{ borderRadius: "50%", objectFit: "cover", height: "55px", width: "55px" }} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", mx: "auto" }}>
                      <AccountCircleIcon className={styles.AccountCircleIcon} />
                    </Box>
                  </>
                )}
              </>
            )}
          </CardMedia>
          <CardContent className={styles.cardContent}>
            <Typography fontSize={16} fontWeight="bold">
              {name}
            </Typography>
            {/* <Typography fontSize={10}>{description}</Typography> */}
          </CardContent>
          <Button
            className={styles.button}
            variant="contained"
            size="large"
            onClick={buttonMess === "Start Training" || buttonMess === "Re-train" ? postCredits : handleClick}
            // onClick={()=>setOpen(true)}
            // onClick={postCredits}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            {buttonMess}
          </Button>
          {/* </CardActionArea> */}
        </Card>
      </Box>
    </>
  );
};

export default AvatareModelTraining;
